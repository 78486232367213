export const config = [
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'address',
        name: 'full_address',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'date',
        unit: '',
        location: 'valuation',
        name: 'date',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'valuation',
        name: 'type',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['avm', 'dvm', 'ovm'],
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'request',
        name: 'references',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'request',
        name: 'valuation_request_ref',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'request',
        name: 'customer_ref',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'reconstruction_value',
        search_dict_name: '',
        icon: 'ic-reconstruction',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'address_location',
        search_dict_name: '',
        icon: 'ic-location',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'secondary_buildings',
        search_dict_name: '',
        icon: 'ic-cube',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'economic_activity',
        search_dict_name: '',
        icon: 'ic-economic',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'roof',
        search_dict_name: '',
        icon: 'ic-roof',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'transaction',
        search_dict_name: '',
        icon: 'ic-economic',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'general',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'report',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'valuation-app',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'dashboard',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'faq-search-results',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'warnings',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'request',
        name: 'bank',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'valuation',
        name: 'valuer',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'valuation',
        name: 'monitor',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'request',
        name: 'customer',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'request',
        name: 'purpose',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['new_loan', 'revaluation'],
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'request',
        name: 'package',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['classic', 'renovation_light', 'renovation_full', 'new_construction'],
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'renovation',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'euro',
        location: 'features',
        name: 'renovation_cost',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['general_info'],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'general_info',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'f_building_type',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['house', 'apartment'],
        sections: ['general_info', 'building', 'c_building'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'f_number_of_facades',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['attached', 'semi', 'detached'],
        sections: ['general_info', 'c_building'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'features',
        name: 'f_parcel_area',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels_and_buildings', 'parcels', 'c_parcels'],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'parcels_and_buildings',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'c_parcels',
        search_dict_name: '',
        icon: 'ic-environment',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'c_building',
        search_dict_name: '',
        icon: 'ic-building',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'features',
        name: 'f_building_area',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels_and_buildings', 'c_building'],
    },
    {
        report_scope: [],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'features',
        name: 'f_others',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_x_annexes',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels_and_buildings', 'parcels', 'secondary_buildings'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'features',
        name: 'f_annexes_area',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels_and_buildings', 'parcels', 'secondary_buildings'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'features',
        name: 'f_area_largest_annex',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['secondary_buildings'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'custom_parcel',
        unit: '',
        location: 'features',
        name: 'parcel_ids',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels_and_buildings'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'degree',
        location: 'features',
        name: 'f_lng',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['address_location'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'degree',
        location: 'features',
        name: 'f_lat',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['address_location'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_number_of_addresses',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['address_location'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'building',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'dvm', 'ovm', 'ers'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'features',
        name: 'f_living_area',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building', 'c_building', 'house_info'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'features',
        name: 'f_approx_living_area',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['c_building'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'n_rooms',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['c_building'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'approx_n_rooms',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['c_building'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'float',
        unit: 'euro',
        location: 'features',
        name: 'transaction_value',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['transaction'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'date',
        unit: '',
        location: 'features',
        name: 'transaction_date',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['transaction'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'euro/m2',
        location: 'features',
        name: 'approx_price_sqm',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['transaction'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'float',
        unit: 'kgco2/m2year',
        location: 'features',
        name: 'co2_sqm',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['energy'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'features',
        name: 'co2_label',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['energy'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'kwh/m2year',
        location: 'features',
        name: 'f_approx_epc',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['energy'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'style_of_house',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['traditional', 'modern', 'contemporary', 'mediterranean', 'other'],
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm', 'ers'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_construction_year',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building', 'c_building', 'house_info'],
    },
    {
        report_scope: ['dvm'],
        building_type_scope: ['new_construction'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_expected_construction_year',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'numeric',
        unit: 'kwh/m2year',
        location: 'features',
        name: 'f_epc',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['energy'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'features',
        name: 'f_epc_label',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['A++', 'A+', 'A', 'B', 'C', 'D', 'E', 'F', 'G'],
        sections: ['energy', 'house_info'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'numeric',
        unit: 'kwh/m2year',
        location: 'features',
        name: 'f_epc_numeric',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm'],
        building_type_scope: ['new_construction'],
        functionality: 'feature',
        type: 'numeric',
        unit: 'kwh/m2year',
        location: 'features',
        name: 'f_expected_epc',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_bedrooms',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'f_floor',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['ground_floor', '1', '2', '3', '4', '>4'],
        sections: ['building'],
    },
    {
        report_scope: [],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_bottom_floor',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: [],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_top_floor',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'array_of_int',
        unit: '',
        location: 'features',
        name: 'f_floors',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'f_balcony_present',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'f_garage_present',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_n_closed_garage',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_n_parking_spot',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'f_cellar_attic',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'f_garden_access',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['none', '\nprivate', 'common', 'private+common'],
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'f_garden_common',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'f_garden_private',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'building_structure',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm3',
        location: 'features',
        name: 'f_volume',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'c_building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'exterior_state',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['maintenance_required', 'good', 'very_good'],
        sections: ['building_structure'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'multi_enum_open',
        unit: '',
        location: 'dvm_features',
        name: 'facade_material',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: [
            'cinder_blocks',
            'wood',
            'plaster',
            'natural_stone',
            'metal',
            'concrete',
            'brick',
        ],
        sections: ['building_structure'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'features',
        name: 'f_roof_area',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'roof'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'degree',
        location: 'features',
        name: 'f_mean_tilt',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'roof'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'percent',
        location: 'features',
        name: 'f_percent_of_roof_flat',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'roof'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm',
        location: 'features',
        name: 'f_min_height',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'roof'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm',
        location: 'features',
        name: 'f_max_height',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'roof'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_n_parts',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'c_building'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_n_roof_panes',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'roof'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'multi_enum_open',
        unit: '',
        location: 'dvm_features',
        name: 'roof_material',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: [
            'tiles',
            'bitumen_roofing',
            'metal',
            'slate_shingles',
            'black_concrete_tiles',
            'thatched',
            'asbestos',
            'green_roof',
        ],
        sections: ['building_structure'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'dvm_features',
        name: 'n_roof_windows',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'dvm_features',
        name: 'n_roof_dormers',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'dvm_features',
        name: 'solar_panel_area',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'building_purpose_classification',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'dvm_features',
        name: 'f_building_listed',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_purpose_classification'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: '',
        name: 'clarify here',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_purpose_classification'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_x_matched_entities',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['economic_activity'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'c_economic_activity_class',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: [
            'home_liberal_profession_or_office',
            'home_other_professional_activity',
            'home',
        ],
        sections: ['economic_activity'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'c_economic_activity_level',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: [
            'no_economic_activity',
            'activity_unlikely',
            'activity_possible',
            'activity_likely',
            'activity_very_likely',
        ],
        sections: ['economic_activity'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'parcels',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'features',
        name: 'f_garden_area',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels', 'c_parcels'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'garden_orientation',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['n', 'ne', 'e', 'se', 's', 'sw', 'w', 'nw'],
        sections: ['parcels'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'garden_arrangement',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['limited', 'good', 'very_good'],
        sections: ['parcels'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'dvm_features',
        name: 'f_swimming_pool',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels', 'c_parcels'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'dvm_features',
        name: 'f_swimming_pool_area',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels', 'c_parcels'],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'fixed',
        unit: '',
        location: 'fixed',
        name: 'parcels_and_building_shapes',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['details_previous_page'],
        sections: ['parcels'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'surroundings',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm',
        location: 'features',
        name: 'f_distance_to_street',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['surroundings'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'direct_traffic_level',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['low', 'medium', 'high'],
        sections: ['surroundings'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'indirect_traffic_level',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['low', 'medium', 'high'],
        sections: ['surroundings'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm',
        location: 'features',
        name: 'f_dist_building_flood',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['surroundings', 'flood', 'flood_simple'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm',
        location: 'features',
        name: 'f_dist_building_flood_insurance_exclusion',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['surroundings', 'flood'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'f_flood_risk',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['high', 'medium', 'low', 'unknown', 'none'],
        sections: ['surroundings', 'flood', 'flood_simple'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'f_flood_risk_insurance_exclusion',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['high', 'none'],
        sections: ['surroundings', 'flood'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'f_flood_risk_composite',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['high', 'medium', 'low', 'none'],
        sections: ['surroundings', 'flood'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'surroundings',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['urban', 'rural', 'isolated'],
        sections: ['surroundings'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'multi_enum_open',
        unit: '',
        location: 'dvm_features',
        name: 'other_positive',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: [
            'classified_facade',
            'green_neighbourhood',
            'sea_view',
            'no_direct_traffic',
            'exceptional_view',
        ],
        sections: ['surroundings'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'multi_enum_open',
        unit: '',
        location: 'dvm_features',
        name: 'other_negative',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['tram', 'train', 'metro', 'traffic', 'busy_area'],
        sections: ['surroundings'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'multiline-text',
        unit: '',
        location: 'dvm_features',
        name: 'remarks',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'multiline-text',
        unit: '',
        location: 'dvm_features',
        name: 'internal_remarks',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'valuations',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'euro',
        location: 'valuation',
        name: 'market_value',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuations'],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'euro',
        location: 'valuation',
        name: 'market_value_post_renovation',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'euro',
        location: 'valuation',
        name: 'forced_sale_value',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuations'],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'euro/month',
        location: 'valuation',
        name: 'rental_value',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuations'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'euro',
        location: 'valuation',
        name: 'reconstruction_value',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuations', 'reconstruction_value'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: '',
        location: 'valuation',
        name: 'index_value',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuations', 'reconstruction_value'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'valuation',
        name: 'index_type',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['abex'],
        sections: ['valuations', 'reconstruction_value'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'features',
        name: 'level',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['reconstruction_value'],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'reference_properties_location',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'section',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'reference_properties_characteristics',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'section',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'flood',
        search_dict_name: '',
        icon: 'ic-flood',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'section',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'energy',
        search_dict_name: '',
        icon: 'ic-environment',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'section',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'flood_simple',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm',
        location: 'features',
        name: 'f_ground_height_above_sea',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['flood', 'flood_simple'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'section',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'faq',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'section',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'questions_feedback',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'section',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'contact_info',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'section',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'disclaimer',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'what-is-avm',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['general'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'what-is-dvm',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['general'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'what-is-ovm',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['general'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'what-is-dashboard',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['general'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'what-is-draft',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['general'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'market-price-incorrect',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['report'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'issue-parcels',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['report'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'old-picture-or-3d',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['report'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'report-language',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['report'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'wrong-pictures',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['report'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'sharing',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['report'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'remarks-language',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['report'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'where-customer-ref',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuation-app'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'building-type-apartment-building',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuation-app'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'address-not-found',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuation-app'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'find-epc',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuation-app'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'epc-label-vs-number',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuation-app'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'find-living-area',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuation-app'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'find-box-number',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuation-app'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'find-construction-year',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuation-app'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'construction-vs-renovation',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuation-app'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'over-two-days',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuation-app'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'carport',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuation-app'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'personal-use',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuation-app'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'is-request-submitted',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['dashboard'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'review',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['dashboard'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'find-previous-request',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['dashboard'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'download-report',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['dashboard'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'language-impact',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['dashboard'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'unsubmission-window',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['dashboard'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'after-sixty-minutes',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['dashboard'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'review-billing',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['dashboard'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'faq',
        type: '',
        unit: '',
        location: 'faq',
        name: 'on-site-next-steps',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['dashboard'],
    },
    {
        report_scope: ['ers'],
        building_type_scope: [],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'property_type',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['house', 'appartment'],
        sections: ['property_type'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features_question',
        name: 'insulated_floors',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['insulation'],
    },
    {
        report_scope: ['ers'],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'insulated_floors',
        search_dict_name: 'floor_attributes.0.has_insulation',
        icon: '',
        fallback: '',
        values: null,
        sections: ['insulation'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features_question',
        name: 'insulated_roof',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['insulation'],
    },
    {
        report_scope: ['ers'],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'insulated_roof',
        search_dict_name: 'roof_attributes.0.has_insulation',
        icon: '',
        fallback: '',
        values: null,
        sections: ['insulation'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features_question',
        name: 'insulated_walls',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['insulation'],
    },
    {
        report_scope: ['ers'],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'insulated_walls',
        search_dict_name: 'wall_attributes.0.has_insulation',
        icon: '',
        fallback: '',
        values: null,
        sections: ['insulation'],
    },
    {
        report_scope: ['ers'],
        building_type_scope: [],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'window_type',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: [
            'single',
            'ordinary_double',
            'hr_double_before_2000',
            'hr_double_after_2000',
            'triple',
        ],
        sections: ['insulation'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features_question',
        name: 'has_attic',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['ers'],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'has_attic',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['insulation'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'integer',
        unit: '',
        location: 'features_question',
        name: 'insulation_floors_when',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['ers'],
        building_type_scope: [],
        functionality: 'feature',
        type: 'integer',
        unit: '',
        location: 'features',
        name: 'floors_insulation_years',
        search_dict_name: 'floor_attributes.0.insulation_year',
        icon: '',
        fallback: '',
        values: null,
        sections: ['insulation'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'integer',
        unit: '',
        location: 'features_question',
        name: 'insulation_roof_when',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['ers'],
        building_type_scope: [],
        functionality: 'feature',
        type: 'integer',
        unit: '',
        location: 'features',
        name: 'roof_insulation_year',
        search_dict_name: 'roof_attributes.0.insulation_year',
        icon: '',
        fallback: '',
        values: null,
        sections: ['insulation'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'integer',
        unit: '',
        location: 'features_question',
        name: 'insulation_walls_when',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['ers'],
        building_type_scope: [],
        functionality: 'feature',
        type: 'integer',
        unit: '',
        location: 'features',
        name: 'walls_insulation_year',
        search_dict_name: 'wall_attributes.0.insulation_year',
        icon: '',
        fallback: '',
        values: null,
        sections: ['insulation'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features_question',
        name: 'has_basement',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['ers'],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'has_basement',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['insulation'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features_question',
        name: 'is_attic_heated',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['ers'],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'is_attic_heated',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['insulation'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'address',
        name: 'streetname',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['address'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'address',
        name: 'streetnumber',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['address'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'address',
        name: 'boxnumber',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['address'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'address',
        name: 'postalcode',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['address'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'address',
        name: 'municipality',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['address'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'integer',
        unit: '',
        location: 'features_question',
        name: 'epc_known',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['ers'],
        building_type_scope: [],
        functionality: 'feature',
        type: 'integer',
        unit: '',
        location: 'features',
        name: 'epc_score',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['house_info'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'heating',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['ers'],
        building_type_scope: [],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'heating_fuel',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: [
            'gas',
            'oil',
            'wood',
            'pellets',
            'coal',
            'district_heating',
            'electricity',
        ],
        sections: ['consumption'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features_question',
        name: 'select_heating_fuel',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: [
            'condensing_boiler',
            'standard_boiler_open',
            'standard_boiler_closed',
            'heat_pump',
            'standard_boiler',
            'chp',
            'energy_efficient',
            'non_energy_efficient',
            'electric_heeater',
        ],
        sections: [],
    },
    {
        report_scope: ['ers'],
        building_type_scope: [],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'heating_type',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: [
            'boiler:condensing',
            'boiler:standard',
            'heat_pump',
            'stove',
            'electric_heater',
            'district_heating',
        ],
        sections: ['consumption'],
    },
    {
        report_scope: ['ers'],
        building_type_scope: [],
        functionality: 'feature',
        type: 'integer',
        unit: '',
        location: 'features',
        name: 'heating_installation_year',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['consumption'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'ventilation',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['ers'],
        building_type_scope: [],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'ventilation_type',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['natural', 'mechanical'],
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features_question',
        name: 'ventilation_type',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['natural', 'mechanical'],
        sections: [],
    },
    {
        report_scope: ['ers'],
        building_type_scope: [],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features_question',
        name: 'ventilation_subtype',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['system_b', 'system_c', 'system_cplus', 'system_d'],
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'renewables',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: ['solar_boiler', 'solar_panels', 'battery', 'air_conditioner'],
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'solar_boiler',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['consumption'],
    },
    {
        report_scope: ['ers'],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'solar_panels',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['consumption'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'battery',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['consumption'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'air_conditioner',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['consumption'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'additional_consumption',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'spa_room',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'swimming_pool',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['ers'],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'electric_vehicle',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'consumers',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['ers'],
        building_type_scope: [],
        functionality: 'feature',
        type: 'integer',
        unit: '',
        location: 'features',
        name: 'n_residents',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['consumption'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features_question',
        name: 'protected_consumer',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'protected_consumer',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'integer',
        unit: '',
        location: 'features_question',
        name: 'installation_year',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'integer',
        unit: '',
        location: 'features_question',
        name: 'heater',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'house_info',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'consumption',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'property_type',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'address',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'insulation',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'house_information',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'integer',
        unit: '',
        location: 'features_question',
        name: 'consumption_known',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'integer',
        unit: '',
        location: 'features_question',
        name: 'yearly_electricity_consumption',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['ers'],
        building_type_scope: [],
        functionality: 'feature',
        type: 'integer',
        unit: '',
        location: 'features',
        name: 'yearly_electricity_consumption',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: ['consumption'],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'integer',
        unit: '',
        location: 'features_question',
        name: 'yearly_fuel_consumption',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'integer',
        unit: '',
        location: 'features_question',
        name: 'yearly_heating_consumption',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'integer',
        unit: '',
        location: 'features_question',
        name: 'f_construction_year',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'integer',
        unit: '',
        location: 'features_question',
        name: 'f_living_area',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features_question',
        name: 'solar_panels',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'integer',
        unit: '',
        location: 'features_question',
        name: 'insulated_windows',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features_question',
        name: 'insulated_single_renovated',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features_question',
        name: 'insulated_plural_renovated',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['ers'],
        building_type_scope: [],
        functionality: 'feature',
        type: 'integer',
        unit: '',
        location: 'features',
        name: 'gas_consumption_approx',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'question',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q1.q',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'wrong_answer',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q1.a1',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'correct_answer',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q1.a2',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'wrong_answer',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q1.a3',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'question',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q2.q',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'wrong_answer',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q2.a1',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'correct_answer',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q2.a2',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'wrong_answer',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q2.a3',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'wrong_answer',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q2.a4',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'question',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q3.q',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'wrong_answer',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q3.a1',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'wrong_answer',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q3.a2',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'correct_answer',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q3.a3',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'question',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q4.q',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'wrong_answer',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q4.a1',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'wrong_answer',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q4.a2',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'correct_answer',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q4.a3',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'question',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q5.q',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'wrong_answer',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q5.a1',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'correct_answer',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q5.a2',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'wrong_answer',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q5.a3',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'question',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q6.q',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'correct_answer',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q6.a1',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'wrong_answer',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q6.a2',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'wrong_answer',
        type: 'string',
        unit: '',
        location: 'energy_quizz',
        name: 'q6.a3',
        search_dict_name: '',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
]
export const translations = {
    'fr-BE': {
        unit: {
            m: 'm',
            m_tooltip: '',
            m2: 'm\u00b2',
            m2_tooltip: '',
            m3: 'm\u00b3',
            m3_tooltip: '',
            'kwh/m2year': 'kWh/m\u00b2an',
            'kwh/m2year_tooltip': '',
            euro: '\u20ac',
            euro_tooltip: '',
            'euro/month': '\u20ac/mois',
            'euro/month_tooltip': '',
            'euro/m2': '\u20ac/m\u00b2',
            'euro/m2_tooltip': '',
            'kgco2/m2year': 'kgCO\u2082/m\u00b2an',
            'kgco2/m2year_tooltip': '',
            kWh: 'kWh',
            kWh_tooltip: '',
            l: 'l',
            l_tooltip: '',
            kg: 'kg',
            kg_tooltip: '',
            'kWh/yr': 'kWh/an',
            'kWh/yr_tooltip': '',
            'l/yr': 'l/an',
            'l/yr_tooltip': '',
            'm3/yr': 'm\u00b3/an',
            'm3/yr_tooltip': '',
            'kg/yr': 'kg/an',
            'kg/yr_tooltip': '',
        },
        address: {
            full_address: 'Adresse',
            full_address_tooltip:
                "S\u00e9lectionnez l'adresse du bien financ\u00e9. Merci de ne pas confondre ceci avec l'adresse d'autres bien \u00e9ventuels qui peuvent servir comme s\u00fbret\u00e9.",
            streetname: 'Rue',
            streetname_tooltip: '',
            streetnumber: 'Num\u00e9ro',
            streetnumber_tooltip: '',
            boxnumber: 'Bo\u00eete',
            boxnumber_tooltip: '',
            postalcode: 'Code postal',
            postalcode_tooltip: '',
            municipality: 'Commune',
            municipality_tooltip: '',
        },
        valuation: {
            date: 'Date de la valorisation',
            date_tooltip: '',
            type: 'Type de valorisation',
            type_tooltip: '',
            valuer: 'R\u00e9alis\u00e9 par',
            valuer_tooltip: '',
            monitor: 'Contr\u00f4l\u00e9 par',
            monitor_tooltip: '',
            market_value: 'Valeur de march\u00e9',
            market_value_tooltip: '',
            market_value_post_renovation:
                'Valeur de march\u00e9 pr\u00e9vue apr\u00e8s r\u00e9novation',
            market_value_post_renovation_tooltip: '',
            forced_sale_value: 'Valeur de vente forc\u00e9e',
            forced_sale_value_tooltip: '',
            rental_value: 'Valeur locative mensuelle',
            rental_value_tooltip: '',
            reconstruction_value: 'Valeur de reconstruction',
            reconstruction_value_tooltip: '',
            index_value: 'Index',
            index_value_tooltip: '',
            index_type: "Type d'index",
            index_type_tooltip: '',
            explanation_1:
                "La valorisation de ce bien repose en partie sur un mod\u00e8le statistique fond\u00e9 sur des donn\u00e9es. Son r\u00e9sultat a \u00e9t\u00e9 revu et affin\u00e9 par un expert immobilier. Il s'agit d'un processus en deux \u00e9tapes :",
            explanation_1_tooltip: '',
            explanation_2: '\u00c9tape 1 : Estimation par le mod\u00e8le de valorisation',
            explanation_2_tooltip: '',
            explanation_3:
                'Une premi\u00e8re estimation de la valeur de march\u00e9 de la propri\u00e9t\u00e9 est calcul\u00e9e par le mod\u00e8le sur la base de toutes les informations fournies par le client et de certains param\u00e8tres automatiques (cf. page 2). Cette valorsiation repr\u00e9sente la valeurde march\u00e9 moyenne pour une propri\u00e9t\u00e9 ayant cette localisation et ces caract\u00e9ristiques.(*)',
            explanation_3_tooltip: '',
            explanation_4:
                '\u00c9tape 2 : Evaluation et mise au point par un expert immobilier',
            explanation_4_tooltip: '',
            explanation_5:
                "L'expert immobilier \u00e9value la valorisation du mod\u00e8le utilis\u00e9e en \u00c9tape 1 et prend ensuite en compte les autres param\u00e8tres entr\u00e9s automatiquement, ainsi que les param\u00e8tres entr\u00e9s par l'expert. Il en r\u00e9sulte une valuer de march\u00e9 du bien (ligne rouge dans le graphique). En fonction des corrections appliqu\u00e9es par l'expert immobilier, la valeur de march\u00e9 peut \u00eatre inf\u00e9rieure ou sup\u00e9rieure \u00e0 l'estimation initiale du mod\u00e8le de valorisation.\n\nLe graphique ci-dessous illustre comment la valeur de march\u00e9 de cette propri\u00e9t\u00e9 se compare \u00e0 d'autres propri\u00e9t\u00e9s dans le voisinage. Il permet toujours de contextualiser la valorisation r\u00e9alis\u00e9e par l'expert.",
            explanation_5_tooltip: '',
            explanation_6:
                "La valeur de march\u00e9 indiqu\u00e9e dans ce rapport est bas\u00e9e sur une valorisation \u00e0 distance r\u00e9alis\u00e9e par un expert. Elle vise \u00e0 donner la meilleure vue possible sur la valeur actuelle du bien. Cependant, elle reste une estimation o\u00f9 les \u00e9l\u00e9ments suivants doivent \u00eatre pris en compte :\n- Valorisation \u00e0 distance: l'expert a \u00e9valu\u00e9 le bien \u00e0 distance et n'a pas r\u00e9ellement visit\u00e9 le bien.\n- Pr\u00e9cision du mod\u00e8le: la valorisation repose, en partie, sur un mod\u00e8le statistique avec une certaine marge d'erreur.\n- Degr\u00e9 de subjectivit\u00e9: m\u00eame la meilleure valorisation par un expert est quelque peu biais\u00e9e par une forme de subjectivit\u00e9.",
            explanation_6_tooltip: '',
        },
        value: {
            avm: 'Valorisation automatique',
            avm_tooltip: '',
            dvm: 'Valorisation \u00e0 distance',
            dvm_tooltip: '',
            ovm: 'Valorisation sur place',
            ovm_tooltip: '',
            new_loan: 'Demande d\u2019un pr\u00eat immobilier',
            new_loan_tooltip: '',
            revaluation: 'Revalorisation',
            revaluation_tooltip: '',
            classic_tooltip: '',
            renovation_light_tooltip: '',
            renovation_full_tooltip: '',
            house: 'Maison',
            house_tooltip: 'B\u00e2timent comptant une seule entit\u00e9',
            apartment: 'Appartement',
            apartment_tooltip:
                "Une entit\u00e9 qui fait partie d'un b\u00e2timent plus large",
            annex: 'Annexe',
            annex_tooltip: '',
            new_construction: 'Nouvelle construction',
            new_construction_tooltip: '',
            construction_plot: 'Terrain \u00e0 b\u00e2tir',
            construction_plot_tooltip: '',
            attached: '2 fa\u00e7ades',
            attached_tooltip: '',
            semi: '3 fa\u00e7ades',
            semi_tooltip: '',
            detached: '4 fa\u00e7ades',
            detached_tooltip: '',
            yes: 'Oui',
            yes_tooltip: '',
            no: 'Non',
            no_tooltip: '',
            traditional: 'Traditionel',
            traditional_tooltip: '',
            modern: 'Moderne',
            modern_tooltip: '',
            contemporary: 'Contemporain',
            contemporary_tooltip: '',
            mediterranean: 'M\u00e9diterran\u00e9en',
            mediterranean_tooltip: '',
            other: 'Autres',
            other_tooltip: '',
            ground_floor: 'Rez-de-chauss\u00e9e',
            ground_floor_tooltip: '',
            1: '1',
            '1_tooltip': '',
            2: '2',
            '2_tooltip': '',
            3: '3',
            '3_tooltip': '',
            4: '4',
            '4_tooltip': '',
            '>4': '>4',
            '>4_tooltip': '',
            private: 'Jardin priv\u00e9',
            private_tooltip: '',
            common: 'Jardin commun',
            common_tooltip: '',
            'private+common': 'Jardin priv\u00e9 et commun',
            'private+common_tooltip': '',
            maintenance_required: "Besoin d'entretien",
            maintenance_required_tooltip: '',
            good: 'Bon',
            good_tooltip: '',
            very_good: 'Tr\u00e8s bon',
            very_good_tooltip: '',
            cinder_blocks: 'Parpaings',
            cinder_blocks_tooltip: '',
            wood: 'Bois',
            wood_tooltip: '',
            plaster: 'Cr\u00e9pi',
            plaster_tooltip: '',
            natural_stone: 'Pierres naturelles',
            natural_stone_tooltip: '',
            metal: 'M\u00e9tal',
            metal_tooltip: '',
            concrete: 'B\u00e9ton',
            concrete_tooltip: '',
            brick: 'Briques',
            brick_tooltip: '',
            tiles: 'Tuiles',
            tiles_tooltip: '',
            bitumen_roofing: 'Bit\u00fbme',
            bitumen_roofing_tooltip: '',
            slate_shingles: 'Bardeaux en ardoise',
            slate_shingles_tooltip: '',
            black_concrete_tiles: 'Tuiles noires en b\u00e9ton',
            black_concrete_tiles_tooltip: '',
            thatched: 'Chaume',
            thatched_tooltip: '',
            asbestos: 'Amiante',
            asbestos_tooltip: '',
            green_roof: 'Toit v\u00e9g\u00e9tal',
            green_roof_tooltip: '',
            home_liberal_profession_or_office:
                'Maison, profession lib\u00e9rale ou bureau',
            home_liberal_profession_or_office_tooltip: '',
            home_other_professional_activity: 'Autre activit\u00e9 professionelle',
            home_other_professional_activity_tooltip: '',
            home: 'Maison',
            home_tooltip: '',
            no_economic_activity: "Pas d'activit\u00e9 \u00e9conomique",
            no_economic_activity_tooltip: '',
            activity_unlikely: 'Activit\u00e9 improbable',
            activity_unlikely_tooltip: '',
            activity_possible: 'Activit\u00e9 possible',
            activity_possible_tooltip: '',
            activity_likely: 'Activit\u00e9 probable',
            activity_likely_tooltip: '',
            activity_very_likely: 'Activit\u00e9 tr\u00e8s probable',
            activity_very_likely_tooltip: '',
            n: 'N',
            n_tooltip: '',
            ne: 'NE',
            ne_tooltip: '',
            e: 'E',
            e_tooltip: '',
            se: 'SE',
            se_tooltip: '',
            s: 'S',
            s_tooltip: '',
            sw: 'SO',
            sw_tooltip: '',
            w: 'O',
            w_tooltip: '',
            nw: 'NO',
            nw_tooltip: '',
            limited: 'Limit\u00e9',
            limited_tooltip: '',
            details_previous_page: '(d\u00e9tails sur la page pr\u00e9c\u00e9dente)',
            details_previous_page_tooltip: '',
            low: 'Faible',
            low_tooltip: '',
            medium: 'Mod\u00e9r\u00e9',
            medium_tooltip: '',
            high: 'Elev\u00e9',
            high_tooltip: '',
            unknown: 'Inconnu',
            unknown_tooltip: '',
            urban: 'Urbain',
            urban_tooltip: '',
            rural: 'Rural',
            rural_tooltip: '',
            isolated: 'Isol\u00e9',
            isolated_tooltip: '',
            city: 'Ville',
            city_tooltip: '',
            suburbs: 'Banlieue',
            suburbs_tooltip: '',
            village: 'Village',
            village_tooltip: '',
            none: 'Aucun',
            none_tooltip: '',
            classified_facade: 'Fa\u00e7ade class\u00e9e',
            classified_facade_tooltip: '',
            green_neighbourhood: 'Environnement vert',
            green_neighbourhood_tooltip: '',
            sea_view: 'Vue sur mer',
            sea_view_tooltip: '',
            no_direct_traffic: 'Pas de trafic direct',
            no_direct_traffic_tooltip: '',
            exceptional_view: 'Vue exceptionelle',
            exceptional_view_tooltip: '',
            tram: 'Tram',
            tram_tooltip: '',
            train: 'Train',
            train_tooltip: '',
            metro: 'M\u00e9tro',
            metro_tooltip: '',
            traffic: 'Trafic',
            traffic_tooltip: '',
            busy_area: 'Zone fr\u00e9quent\u00e9e',
            busy_area_tooltip: '',
            abex: 'ABEX',
            abex_tooltip: '',
            single: 'Simple',
            single_tooltip: '',
            ordinary_double: 'Double',
            ordinary_double_tooltip: '',
            hr_double_after_2000: 'Double haut rendement (apr\u00e8s 2000)',
            hr_double_after_2000_tooltip: '',
            hr_double_before_2000: 'Double haut rendement (avant 2000)',
            hr_double_before_2000_tooltip: '',
            triple: 'Triple',
            triple_tooltip: '',
            gas: 'Gaz',
            gas_tooltip: '',
            oil: 'Mazout',
            oil_tooltip: '',
            pellets: 'Pellets',
            pellets_tooltip: '',
            coal: 'Charbon',
            coal_tooltip: '',
            district_heating: 'Chauffage urbain/r\u00e9seau de chaleur',
            district_heating_tooltip: '',
            'boiler:condensing': 'Chaudi\u00e8re \u00e0 condensation',
            'boiler:condensing_tooltip': '',
            heat_pump: 'Pompe \u00e0 chaleur',
            heat_pump_tooltip: '',
            'boiler:standard': 'Chaudi\u00e8re standard',
            'boiler:standard_tooltip': '',
            chp: 'Cog\u00e9n\u00e9ration',
            chp_tooltip: '',
            stove: 'Po\u00eale',
            stove_tooltip: '',
            electricity: 'Electricit\u00e9',
            electricity_tooltip: '',
            electric_heater: 'Chauffage \u00e9lectrique',
            electric_heater_tooltip: '',
            no_ventilation: 'Pas de ventilation',
            no_ventilation_tooltip: '',
            natural: 'Naturelle',
            natural_tooltip:
                "Ventilation naturelle par les fen\u00eatres ou des grilles d'a\u00e9ration.",
            mechanical: 'M\u00e9canique',
            mechanical_tooltip: '',
            system_b: 'Syst\u00e8me B',
            system_b_tooltip:
                "Syst\u00e8me d'apport m\u00e9canique de l'air frais, \u00e9vacuation naturelle de l'air vici\u00e9.",
            system_c: 'Syst\u00e8me C',
            system_c_tooltip:
                "Syst\u00e8me d'apport m\u00e9canique de l'air frais, \u00e9vacuation naturelle de l'air vici\u00e9.",
            system_cplus: 'Syst\u00e8me C+',
            system_cplus_tooltip:
                "Syst\u00e8me d'apport et d'\u00e9vacuation d'air m\u00e9canique \u00e0 la demande, en fonction de la qualit\u00e9 de l'air. ",
            system_d: 'Syst\u00e8me D',
            system_d_tooltip:
                "Syst\u00e8me d'apport et \u00e9vacuation d'air m\u00e9canique automatis\u00e9, appel\u00e9 aussi 'ventilation \u00e9quilibr\u00e9e'. ",
        },
        request: {
            references: 'R\u00e9f\u00e9rences',
            references_tooltip: '',
            valuation_request_ref: 'R\u00e9f\u00e9rence de la valorisation',
            valuation_request_ref_tooltip: '',
            customer_ref: 'R\u00e9f\u00e9rence interne',
            customer_ref_tooltip:
                "Ceci permet de relier la demande d'\u00e9valuation au pr\u00eat.",
            bank: 'Banque',
            bank_tooltip: '',
            customer: 'Client',
            customer_tooltip: '',
            purpose: 'Objectif de la valorisation',
            purpose_tooltip: '',
            package_tooltip: '',
        },
        footer: {
            text: 'Pour de plus amples informations, visitez {url.rockestate_main}',
            text_tooltip: '',
        },
        url: {
            rockestate_main: 'https://www.rock.estate',
            rockestate_main_tooltip: '',
            dashboard: '/valuation/default/dashboard',
            dashboard_tooltip: '',
            rockestate_info: 'https://www.rock.estate/',
            rockestate_info_tooltip: '',
            valuation_app: '/valuation/default/',
            valuation_app_tooltip: '',
        },
        export: {
            by: 'Export\u00e9 par',
            by_tooltip: '',
            date: 'Date',
            date_tooltip: '',
            reference: 'R\u00e9f\u00e9rence',
            reference_tooltip: '',
        },
        section: {
            reconstruction_value: 'Reconstruction',
            reconstruction_value_tooltip: '',
            address_location: 'Adresse et localisation',
            address_location_tooltip: '',
            secondary_buildings: 'B\u00e2timents secondaires',
            secondary_buildings_tooltip: '',
            economic_activity: 'Activit\u00e9 \u00e9conomique',
            economic_activity_tooltip: '',
            roof: 'Toit',
            roof_tooltip: '',
            transaction: 'Transactions',
            transaction_tooltip: '',
            general: 'G\u00e9n\u00e9ral',
            general_tooltip: '',
            report: 'Rapport',
            report_tooltip: '',
            'valuation-app': 'Application',
            'valuation-app_tooltip': '',
            dashboard: 'Tableau de bord',
            dashboard_tooltip: '',
            'faq-search-results': 'R\u00e9sultats de la recherche',
            'faq-search-results_tooltip': '',
            warnings: 'Avertissements',
            warnings_tooltip: '',
            renovation: 'R\u00e9novation pr\u00e9vue',
            renovation_tooltip: '',
            general_info: 'Information g\u00e9n\u00e9rales',
            general_info_tooltip: '',
            parcels_and_buildings: 'Parcelles et b\u00e2timents',
            parcels_and_buildings_tooltip: '',
            c_parcels: 'Terrain',
            c_parcels_tooltip: '',
            c_building: 'B\u00e2timent principal',
            c_building_tooltip: '',
            building: 'B\u00e2timent',
            building_tooltip: '',
            building_structure: 'Structure du b\u00e2timent',
            building_structure_tooltip: '',
            building_purpose_classification:
                'Utilisation & classification du b\u00e2timent',
            building_purpose_classification_tooltip: '',
            parcels: 'Parcelle(s)',
            parcels_tooltip: '',
            surroundings: 'Environnement proche',
            surroundings_tooltip: '',
            valuations: 'Valorisations',
            valuations_tooltip: '',
            reference_properties_location: 'Biens de r\u00e9f\u00e9rences : emplacement',
            reference_properties_location_tooltip: '',
            reference_properties_characteristics:
                'Biens de r\u00e9f\u00e9rences : caract\u00e9ristiques',
            reference_properties_characteristics_tooltip: '',
            flood: 'Zones inondables',
            flood_tooltip: '',
            energy: 'Energie',
            energy_tooltip: '',
            flood_simple: 'Zones inondables',
            flood_simple_tooltip: '',
            faq: 'Foire aux questions',
            faq_tooltip: '',
            questions_feedback: 'Questions et commentaires',
            questions_feedback_tooltip: '',
            contact_info: 'Coordonn\u00e9es de contact',
            contact_info_tooltip: '',
            disclaimer: 'Mentions l\u00e9gales',
            disclaimer_tooltip: '',
            heating: 'Chauffage',
            heating_tooltip: '',
            ventilation: 'Ventilation',
            ventilation_tooltip: '',
            renewables: 'Energies renouvelables',
            renewables_tooltip: '',
            additional_consumption: 'n/a',
            additional_consumption_tooltip: '',
            consumers: 'Consommateurs',
            consumers_tooltip: '',
            house_info: "Donn\u00e9es relatives \u00e0 l'habitation",
            house_info_tooltip: '',
            consumption: 'Consommation',
            consumption_tooltip: '',
            property_type: 'Type de b\u00e2timent',
            property_type_tooltip: '',
            address: "Confirmation de l'adresse",
            address_tooltip: '',
            insulation: 'Isolation',
            insulation_tooltip: '',
            house_information: 'Donn\u00e9es relatives au bien',
            house_information_tooltip: '',
        },
        front: {
            title: 'Rapport de valorisation de la propri\u00e9t\u00e9',
            title_tooltip: '',
            core_title: 'Rapport de propri\u00e9t\u00e9',
            core_title_tooltip: '',
        },
        features: {
            renovation_cost: 'Co\u00fbt de r\u00e9novation (HTVA)',
            renovation_cost_tooltip: '',
            f_building_type: 'Type de bien',
            f_building_type_tooltip:
                'Est-que le client finance une maison unifamiliale ou un appartement?',
            f_number_of_facades: 'Nombre de fa\u00e7ades',
            f_number_of_facades_tooltip: '',
            f_parcel_area: 'Superficie totale de la parcelle',
            f_parcel_area_tooltip: '',
            f_building_area: 'Surface au sol du b\u00e2timent',
            f_building_area_tooltip: '',
            f_others: 'Autre',
            f_others_tooltip: '',
            f_x_annexes: 'B\u00e2timents secondaires',
            f_x_annexes_tooltip: '',
            f_annexes_area: 'Surface totale des b\u00e2timents secondaires',
            f_annexes_area_tooltip: '',
            f_area_largest_annex: 'Plus grand b\u00e2timent secondaire',
            f_area_largest_annex_tooltip: '',
            parcel_ids: 'R\u00e9f\u00e9rences cadastrales',
            parcel_ids_tooltip: '',
            f_lng: 'Longitude',
            f_lng_tooltip: '',
            f_lat: 'Latitude',
            f_lat_tooltip: '',
            f_number_of_addresses: "Nombre d'adresses",
            f_number_of_addresses_tooltip: '',
            f_living_area: 'Superficie habitable',
            f_living_area_tooltip:
                "La surface habitable totale du bien. Cette information se retrouve normalement dans l'annonce immobili\u00e8re.",
            f_expected_living_area: 'Superficie habitable (attendue)',
            f_expected_living_area_tooltip: '',
            f_approx_living_area: 'Surface habitable approximative',
            f_approx_living_area_tooltip: '',
            n_rooms: 'Nombre de pi\u00e8ces principales',
            n_rooms_tooltip: '',
            approx_n_rooms: 'Nombre de pi\u00e8ces approximatif',
            approx_n_rooms_tooltip: '',
            transaction_value: 'Valeur de transaction',
            transaction_value_tooltip: '',
            transaction_date: 'Date de transaction',
            transaction_date_tooltip: '',
            approx_price_sqm: 'Prix approximatif',
            approx_price_sqm_tooltip: '',
            co2_sqm: 'Emissions de gaz \u00e0 effet de serre',
            co2_sqm_tooltip: '',
            co2_label: "Label d'\u00e9mission de gaz \u00e0 effet de serre",
            co2_label_tooltip: '',
            f_approx_epc: "Consommation d'\u00e9nergie approximative",
            f_approx_epc_tooltip: '',
            f_construction_year: 'Ann\u00e9e de construction',
            f_construction_year_tooltip:
                "L'ann\u00e9e dans laquelle le bien a \u00e9t\u00e9 construit officiellement. Ceci ne tient pas compte d'\u00e9ventuelles r\u00e9novations qui ont eu lieu \u00e0 un moment ult\u00e9rieur. Cette information se retrouve normalement dans l'annonce immobili\u00e8re.",
            f_expected_construction_year: 'Ann\u00e9e de construction (attendue)',
            f_expected_construction_year_tooltip: '',
            f_epc: 'PEB',
            f_epc_tooltip:
                "Le certificat PEB (Performance \u00c9nerg\u00e9tique du B\u00e2timent) est la carte d'identit\u00e9 \u00e9nerg\u00e9tique d'un b\u00e2timent. Cette information se retrouve normalement dans l'annonce immobili\u00e8re.",
            f_epc_label: 'PEB',
            f_epc_label_tooltip: '',
            f_epc_numeric: 'PEB',
            f_epc_numeric_tooltip: '',
            f_expected_epc: 'PEB (attendu)',
            f_expected_epc_tooltip: '',
            f_bedrooms: 'Nombre de chambres',
            f_bedrooms_tooltip:
                'Nombre de pi\u00e8ces pouvant servir comme chambre \u00e0 coucher. Cuisine, salles de bain et salon ne sont pas inclus.',
            f_floor: '\u00c9tage',
            f_floor_tooltip:
                "L'\u00e9tage auquel l'appartement se situe. S'il est sur plusieurs \u00e9tages, choisissez le plus bas.",
            f_bottom_floor: "\u00c9tage le plus bas de l'immeuble",
            f_bottom_floor_tooltip:
                "L'\u00e9tage inf\u00e9rieur d'un b\u00e2timent est soit 0 soit un nombre n\u00e9gatif en fonction du nombre d'\u00e9tages souterrains. \nAttribuez la valeur 0 si le b\u00e2timent n'a pas de niveau souterrain. \nAttribuez une valeur n\u00e9gative si le b\u00e2timent poss\u00e8de un sous-sol/un garage souterrain/...",
            f_top_floor: "Dernier \u00e9tage de l'immeuble",
            f_top_floor_tooltip:
                "Le dernier \u00e9tage d'un b\u00e2timent est l'\u00e9tage situ\u00e9 sous le toit de ce b\u00e2timent. Les combles ne sont pas pris en compte si ils ne sont pas am\u00e9nag\u00e9s.",
            f_floors: "\u00c9tage(s) de l'appartement",
            f_floors_tooltip:
                "S\u00e9lectionnez le ou les \u00e9tages o\u00f9 se trouve l'appartement dans la liste g\u00e9n\u00e9r\u00e9e.\nPar exemple : pour un duplex se trouvant aux 2\u00e8me et 3\u00e8me \u00e9tage, s\u00e9lectionnez les deux \u00e9tages.",
            f_balcony_present: 'Terrasse',
            f_balcony_present_tooltip:
                "Choisissez 'oui' si l'appartement a au moins une terrasse capable d'accueillir une table de 4 personnes.",
            f_garage_present: 'Garage',
            f_garage_present_tooltip:
                "L'appartement peut disposer d'un ou plusieurs emplacements de parking. Il peut s'agir aussi bien d'un emplacement simple ou un box, \u00e0 l'interieur ou \u00e0 l'ext\u00e9rieur.",
            f_n_closed_garage: 'Nombre de garages ferm\u00e9s',
            f_n_closed_garage_tooltip:
                "Un garage ferm\u00e9 peut \u00eatre d'int\u00e9rieur ou d'ext\u00e9rieur. Indiquez le nombre de garages ferm\u00e9s appartenant \u00e0 l'appartement \u00e9valu\u00e9.",
            f_n_parking_spot: "Nombre d'emplacement de parkings",
            f_n_parking_spot_tooltip:
                'Une place de stationnement est un emplacement ext\u00e9rieur ou int\u00e9rieur d\u00e9limit\u00e9 par des marques de signalisation et non par des murs (par opposition aux garages ferm\u00e9s).\nIndiquez le nombre de places de stationnement li\u00e9es \u00e0 la propri\u00e9t\u00e9.',
            f_cellar_attic: 'Cave et/ou grenier',
            f_cellar_attic_tooltip: '',
            f_garden_access: 'Jardin',
            f_garden_access_tooltip:
                "L'appartement peut donner acc\u00e8s \u00e0 un jardin privatif ou commun.",
            f_garden_common: 'Acc\u00e8s \u00e0 un jardin commun',
            f_garden_common_tooltip:
                "Un jardin commun n'est accessible qu'aux occupants de l'immeuble o\u00f9 le bien est \u00e9valu\u00e9. \nS'il existe un jardin commun, l'appartement \u00e9valu\u00e9 y donne-t-il acc\u00e8s ? Si oui, s\u00e9lectionnez oui.",
            f_garden_private: 'Acc\u00e8s \u00e0 un jardin priv\u00e9',
            f_garden_private_tooltip:
                "Un jardin priv\u00e9 est r\u00e9serv\u00e9 aux occupants de l'appartement \u00e9valu\u00e9 et est adjacent \u00e0 l'appartement. La diff\u00e9rence entre un jardin et une terrasse est qu'un jardin dispose de gazon.",
            f_volume: 'Volume du b\u00e2timent principal',
            f_volume_tooltip: '',
            f_roof_area: 'Surface du toit',
            f_roof_area_tooltip: '',
            f_mean_tilt: 'Inclinaison moyenne du toit',
            f_mean_tilt_tooltip: '',
            f_percent_of_roof_flat: 'Proportion de toit plat',
            f_percent_of_roof_flat_tooltip: '',
            f_min_height: 'Hauteur minimale du toit',
            f_min_height_tooltip: '',
            f_max_height: 'Hauteur maximale du toit',
            f_max_height_tooltip: '',
            f_n_parts: 'Parties du b\u00e2timent principal',
            f_n_parts_tooltip: '',
            f_n_roof_panes: 'Pans de toit',
            f_n_roof_panes_tooltip: '',
            f_x_matched_entities: 'Entit\u00e9s connues \u00e0 cette adresse',
            f_x_matched_entities_tooltip: '',
            c_economic_activity_class: "Classe d'activit\u00e9 \u00e9conomique",
            c_economic_activity_class_tooltip: '',
            c_economic_activity_level: "Niveau d'activit\u00e9 \u00e9conomique",
            c_economic_activity_level_tooltip: '',
            f_garden_area: 'Surface du jardin',
            f_garden_area_tooltip: '',
            f_distance_to_street: 'Distance par rapport au milieu de la rue',
            f_distance_to_street_tooltip: '',
            f_dist_building_flood: 'Zone inondable la plus proche',
            f_dist_building_flood_tooltip: '',
            f_dist_building_flood_insurance_exclusion:
                "Zone inondable la plus proche\n(zones d'al\u00e9a \u00e9lev\u00e9)",
            f_dist_building_flood_insurance_exclusion_tooltip: '',
            f_flood_risk: "Risque d'inondation",
            f_flood_risk_tooltip: '',
            f_flood_risk_insurance_exclusion:
                "Risque d'inondation\n(zones d'al\u00e9a \u00e9lev\u00e9)",
            f_flood_risk_insurance_exclusion_tooltip: '',
            f_flood_risk_composite: "Risque d'inondation composite",
            f_flood_risk_composite_tooltip: '',
            level: "Niveau d'information",
            level_tooltip: '',
            f_ground_height_above_sea: 'Altitude (au-dessus du niveau de la mer)',
            f_ground_height_above_sea_tooltip: '',
            property_type: 'Type de bien',
            property_type_tooltip: '',
            insulated_floors: 'Sols isol\u00e9s',
            insulated_floors_tooltip: '',
            insulated_roof: 'Toit isol\u00e9',
            insulated_roof_tooltip: '',
            insulated_walls: 'Murs isol\u00e9s',
            insulated_walls_tooltip: '',
            window_type: 'Vitrage',
            window_type_tooltip: '',
            has_attic: 'Grenier',
            has_attic_tooltip: '',
            floors_insulation_years: "Ann\u00e9e d'isolation des sols",
            floors_insulation_years_tooltip: '',
            roof_insulation_year: "Ann\u00e9e d'isolation du toit ",
            roof_insulation_year_tooltip: '',
            walls_insulation_year: "Ann\u00e9e d'isolation des murs",
            walls_insulation_year_tooltip: '',
            has_basement: 'Cave',
            has_basement_tooltip: '',
            is_attic_heated: 'Grenier chauff\u00e9',
            is_attic_heated_tooltip: '',
            epc_score: 'Score PEB',
            epc_score_tooltip: '',
            heating_fuel: 'Combustible de chauffage',
            heating_fuel_tooltip: '',
            heating_type: 'Type de chauffage',
            heating_type_tooltip: '',
            heating_installation_year: "Ann\u00e9e d'installation du chauffage",
            heating_installation_year_tooltip: '',
            ventilation_type: 'Type de ventilation',
            ventilation_type_tooltip: '',
            solar_boiler: 'Chauffe-eau solaire',
            solar_boiler_tooltip: '',
            solar_panels: 'Panneaux solaires',
            solar_panels_tooltip: '',
            battery: 'Batterie',
            battery_tooltip: '',
            air_conditioner: 'n/a',
            air_conditioner_tooltip: '',
            spa_room: 'n/a',
            spa_room_tooltip: '',
            swimming_pool: 'n/a',
            swimming_pool_tooltip: '',
            electric_vehicle: 'Voiture \u00e9lectrique',
            electric_vehicle_tooltip: '',
            n_residents: "Nombre d'occupants",
            n_residents_tooltip: '',
            protected_consumer: 'n/a',
            protected_consumer_tooltip: '',
            yearly_electricity_consumption:
                "Consommation d'\u00e9lectricit\u00e9 annuelle",
            yearly_electricity_consumption_tooltip: '',
            gas_consumption_approx: 'Consommation annuelle de gaz',
            gas_consumption_approx_tooltip: '',
        },
        disclaimer: {
            avm_tooltip: '',
            dvm:
                "Ce rapport de valorisation est le r\u00e9sultat d'une valorisation \u00e0 distance du bien par un expert immobilier. L'expert n'a pas visit\u00e9 le bien mais s'est appuy\u00e9 sur diverses sources de donn\u00e9es et un mod\u00e8le statistique pour effectuer une valorisation ind\u00e9pendante du bien. Pour plus d'informations concernant cette approche de valorisation, veuillez vous r\u00e9f\u00e9rer \u00e0 la derni\u00e8re page de ce rapport.",
            dvm_tooltip: '',
            ovm:
                "Ce rapport d'\u00e9valuation est le r\u00e9sultat d'une \u00e9valuation sur place du bien par un expert immobilier. La valorisation ind\u00e9pendante du bien, est bas\u00e9e sur la situation observ\u00e9e sur place par l'expert, et est soustenue par un mod\u00e8le statistique. Pour plus d'informations concernant cette approche d'\u00e9valuation, veuillez vous r\u00e9f\u00e9rer \u00e0 la derni\u00e8re page de ce rapport.",
            ovm_tooltip: '',
            part_1:
                "Cette valorisation a \u00e9t\u00e9 r\u00e9alis\u00e9e dans le but exprim\u00e9 ici, aucune responsabilit\u00e9 n'est accept\u00e9e dans le cas o\u00f9 ce rapport serait utilis\u00e9 dans un autre but ou par une autre partie que celle \u00e0 laquelle il est adress\u00e9. Si une autre partie souhaite utiliser ou s'appuyer sur ce rapport \u00e0 quelque fin que ce soit, elle doit d'abord obtenir le consentement \u00e9crit de Rock.estate.\n",
            part_1_tooltip: '',
            part_2:
                "La publication de ce rapport, en tout ou en partie, est interdite sans l'accord \u00e9crit de Rock.estate. Les droits d'auteur de ce rapport restent \u00e0 tout moment la propri\u00e9t\u00e9 de Rock.estate. Ce rapport ne peut \u00eatre c\u00e9d\u00e9 \u00e0 un tiers sans le consentement expr\u00e8s du propri\u00e9taire du droit d'auteur.",
            part_2_tooltip: '',
            reconstruction_value_title: 'Calcul de la valeur de reconstruction',
            reconstruction_value_title_tooltip: '',
            reconstruction_value:
                "La valeur de reconstruction est le co\u00fbt total de reconstruction d'un b\u00e2timent \u00e0 neuf consid\u00e9rant les normes de construction actuelles. Ce calcul inclus la TVA et les frais d'architecte, ne d\u00e9pend pas de l'emplacement, et n'inclus pas les frais de d\u00e9molition ni les b\u00e2timents secondaires. Par cons\u00e9quent, il est possible que la valeur de reconstruction soit plus \u00e9lev\u00e9e que la valeur de march\u00e9. La valeur de reconstruction est bas\u00e9e sur l'indice ABEX 847.",
            reconstruction_value_tooltip: '',
            part_3:
                "En faisant ce rapport de valorisation, nous n'exprimons aucune opinion et n'acceptons aucune responsabilit\u00e9 quant \u00e0 la capacit\u00e9 et \u00e0 la volont\u00e9 du d\u00e9biteur hypoth\u00e9caire de respecter ses engagements dans le cadre du pr\u00eat propos\u00e9.",
            part_3_tooltip: '',
        },
        info: {
            data_from_customer: 'donn\u00e9es saisies par le client',
            data_from_customer_tooltip: '',
        },
        dvm_features: {
            style_of_house: 'Style de construction',
            style_of_house_tooltip: '',
            exterior_state: '\u00c9tat ext\u00e9rieur de la propri\u00e9t\u00e9',
            exterior_state_tooltip: '',
            facade_material: 'Mat\u00e9riaux des fa\u00e7ades',
            facade_material_tooltip: '',
            roof_material: 'Mat\u00e9riaux de toiture',
            roof_material_tooltip: '',
            n_roof_windows: 'Nombre de fen\u00eatres de toit',
            n_roof_windows_tooltip: '',
            n_roof_dormers: 'Nombre de lucarnes',
            n_roof_dormers_tooltip: '',
            solar_panel_area: 'Surface des panneaux solaires',
            solar_panel_area_tooltip: '',
            f_building_listed: 'Class\u00e9 \u00e0 des fins historiques',
            f_building_listed_tooltip: '',
            garden_orientation: 'Orientation du jardin',
            garden_orientation_tooltip: '',
            garden_arrangement: 'Am\u00e9nagement (possible) du jardin',
            garden_arrangement_tooltip: '',
            f_swimming_pool: 'Piscine ext\u00e9rieure',
            f_swimming_pool_tooltip: '',
            f_swimming_pool_area: 'Taille de la piscine ext\u00e9rieure',
            f_swimming_pool_area_tooltip: '',
            direct_traffic_level: 'Niveau de trafic direct dans la voirie',
            direct_traffic_level_tooltip: '',
            indirect_traffic_level: 'Proximit\u00e9 du trafic indirecte sur la voirie',
            indirect_traffic_level_tooltip: '',
            surroundings: 'Description des environs',
            surroundings_tooltip: '',
            other_positive: 'Autres \u00e9l\u00e9ments ayant un impact positif',
            other_positive_tooltip: '',
            other_negative: 'Autres \u00e9l\u00e9ments ayant un impact n\u00e9gatif',
            other_negative_tooltip: '',
            remarks: "Remarques de l'expert immobilier",
            remarks_tooltip: '',
            internal_remarks: 'Remarques internes',
            internal_remarks_tooltip: '',
        },
        '': {
            'clarify here': 'Activit\u00e9 \u00e9conomique',
            'clarify here_tooltip': '',
        },
        fixed: {
            parcels_and_building_shapes: 'Formes de parcelles et de b\u00e2timents',
            parcels_and_building_shapes_tooltip: '',
        },
        footnote: {
            no_renovation_included:
                "Toutes les valeurs sont calcul\u00e9es pour l'\u00e9tat actuel du bien et ne tiennent pas compte des r\u00e9novations pr\u00e9vues.",
            no_renovation_included_tooltip: '',
            avm: 'Valorisation automatique',
            avm_tooltip: '',
            q25: '25\u00e8me percentile',
            q25_tooltip: '',
            q75: '75\u00e8me percentile',
            q75_tooltip: '',
        },
        reference_properties_location: {
            explanation_1:
                "La valorisation de cette propri\u00e9t\u00e9 repose sur {num_references} biens de r\u00e9f\u00e9rence qui ont \u00e9t\u00e9 s\u00e9lectionn\u00e9es dans le voisinage de la propri\u00e9t\u00e9 (dans un rayon de {reference_radius} km). Chaque bien de r\u00e9f\u00e9rence pr\u00e9sente un certain nombre de similitudes avec la propri\u00e9t\u00e9 \u00e9valu\u00e9e en termes d'emplacement, de taille et de plusieurs autres caract\u00e9ristiques. Le mod\u00e8le statistique sous-jacent de cette valorisation est bas\u00e9 sur une r\u00e9gression g\u00e9ographiquement pond\u00e9r\u00e9e (GWR) o\u00f9 les biens de r\u00e9f\u00e9rence situ\u00e9s \u00e0 proximit\u00e9 imm\u00e9diate de la propri\u00e9t\u00e9 re\u00e7oivent une pond\u00e9ration plus importante que les biens de r\u00e9f\u00e9rence situ\u00e9s dans un rayon plus \u00e9loign\u00e9.",
            explanation_1_tooltip: '',
            explanation_2:
                "La carte ci-dessous donne un aper\u00e7u de l'emplacement des biens de r\u00e9f\u00e9rence et de leur distance par rapport \u00e0 la propri\u00e9t\u00e9 \u00e9valu\u00e9e. La l\u00e9gende des couleur sur la carte donne une indication de la valeur de march\u00e9 moyenne des biens immobiliers dans votre r\u00e9gion.",
            explanation_2_tooltip: '',
        },
        reference_properties_characteristics: {
            explanation_1:
                "Une propri\u00e9t\u00e9 poss\u00e8de diverses caract\u00e9ristiques objectives qui contribuent \u00e0 son prix, telles que l'emplacement, la taille et l'efficacit\u00e9 \u00e9nerg\u00e9tique. Les graphiques ci-dessous comparent certaines caract\u00e9ristiques de cette propri\u00e9t\u00e9 avec d\u2019autres biens plus ou moins proches.",
            explanation_1_tooltip: '',
            property_characteristics: 'Caract\u00e9ristiques de la propri\u00e9t\u00e9',
            property_characteristics_tooltip: '',
            properties_in_the_area: 'Propri\u00e9t\u00e9s dans votre r\u00e9gion',
            properties_in_the_area_tooltip: '',
        },
        flood: {
            explanation_1:
                "Aper\u00e7u des zones d'inondation officielles \u00e0 proximit\u00e9 de la propri\u00e9t\u00e9. Plusieurs cat\u00e9gories de risque d'inondation sont calcul\u00e9es en fonction de diff\u00e9rents sc\u00e9narios de risque d'inondation, allant de faible \u00e0 \u00e9lev\u00e9.",
            explanation_1_tooltip: '',
        },
        faq: {
            q_incorrect_valuation:
                "L'adresse du bien \u00e9valu\u00e9 est incorrecte, que dois-je faire?",
            q_incorrect_valuation_tooltip: '',
            a_incorrect_valuation:
                "Il semble que quelque chose ait d\u00fb mal se passer lors de la s\u00e9lection de l'adresse, du b\u00e2timent ou des parcelles li\u00e9es \u00e0 la propri\u00e9t\u00e9. Vous pouvez revoir les informations sur l'adresse donn\u00e9e via le lien suivant : {url.dashboard}\u2028.",
            a_incorrect_valuation_tooltip: '',
            q_incorrect_characteristics:
                "L'adresse du bien \u00e9valu\u00e9 est correcte, mais certaines des autres caract\u00e9ristiques de ce rapport sont incorrectes, que dois-je faire?",
            q_incorrect_characteristics_tooltip: '',
            a_incorrect_characteristics:
                'Si vous pensez que certaines informations dans ce rapport peuvent diff\u00e9rer substantiellement de la situation r\u00e9elle, ce qui pourrait avoir un impact important sur la valorisation de la propri\u00e9t\u00e9, suivez ce lien {url.dashboard} pour revoir et clarifier les informations incorrectes/manquantes.\u2028',
            a_incorrect_characteristics_tooltip: '',
            q_transaction_vs_market_value:
                "Pourquoi le prix de la transaction que j'ai pay\u00e9 est diff\u00e9rent de la valeur de march\u00e9 d\u00e9termin\u00e9e dans ce rapport?",
            q_transaction_vs_market_value_tooltip: '',
            a_transaction_vs_market_value_dvm:
                "Ce rapport de valorisation est le r\u00e9sultat de l'\u00e9valuation \u00e0 distance du bien en question par un expert immobilier, soutenue par un mod\u00e8le statistique. Le rapport devrait donner une indication pr\u00e9cise de la valeur de march\u00e9 attendue du bien, \u00e0 base des informations disponibles. \u00c9tant donn\u00e9 que l'expert n'a pas r\u00e9ellement visit\u00e9 la propri\u00e9t\u00e9, il peut arriver que l'impact de certaines caract\u00e9ristiques sp\u00e9cifiques \u00e0 ce bien ne soit pas enti\u00e8rement refl\u00e9t\u00e9 dans la valuer de march\u00e9 estim\u00e9e. Veuillez consulter notre page d'information via le lien suivant : {url.rockestate_info}.",
            a_transaction_vs_market_value_dvm_tooltip: '',
            a_transaction_vs_market_value_ovm:
                "Ce rapport d'\u00e9valuation est le r\u00e9sultat de l'\u00e9valuation \u00e0 distance d'un expert immobilier, soutenue par un mod\u00e8le statistique bas\u00e9 sur des donn\u00e9es. Le rapport doit donner une indication pr\u00e9cise de la valeur attendue du bien, sur la base des informations disponibles. \u00c9tant donn\u00e9 que l'\u00e9valuateur n'a pas r\u00e9ellement visit\u00e9 le bien, il peut arriver que l'impact de certaines caract\u00e9ristiques sp\u00e9cifiques \u00e0 ce bien ne soit pas enti\u00e8rement refl\u00e9t\u00e9 dans le prix de transaction estim\u00e9. Veuillez consulter notre page d'information via le lien suivant : {url.rockestate_info}.",
            a_transaction_vs_market_value_ovm_tooltip: '',
            q_loan_accepted:
                "Maintenant que j'ai re\u00e7u mon rapport de valorisation, cela signifie-t-il que ma demande de pr\u00eat a \u00e9t\u00e9 accept\u00e9e?",
            q_loan_accepted_tooltip: '',
            a_loan_accepted:
                "Nous avons partag\u00e9 ce rapport de valorisation avec vous et votre banque. Ce rapport de valorisation sera utilis\u00e9 par la banque comme l'un des \u00e9l\u00e9ments de son processus d'approbation du risque de cr\u00e9dit. La d\u00e9cision finale reste la leur. Rock.estate n'intervient en aucune fa\u00e7on dans cette d\u00e9cision.",
            a_loan_accepted_tooltip: '',
            'where-bryan_tooltip': '',
            'where-bryan_question': 'O\u00f9 est Bryan ?',
            'where-bryan_answer': 'A la plage.',
            'internet-down_tooltip': '',
            'internet-down_question':
                "Pouvez-vous m'aider s'il-vous-pla\u00eet ? Mon internet ne fonctionne plus",
            'internet-down_answer':
                "Avez-vous essay\u00e9\n\n- De l'\u00e9teindre\n- Et de le rallumer ?",
            'where-dog_tooltip': '',
            'where-dog_question': 'Avez-vous vu mon chien?',
            'where-dog_answer':
                '![Shiba](https://www.meme-arsenal.com/memes/3cd52c228bb8aff4714b1ea387263803.jpg)\n\nMaintenant oui',
            'what-is-avm_tooltip': '',
            'what-is-avm_question': "Qu'est-ce qu'une \u00e9valuation automatique ?",
            'what-is-avm_answer':
                "Une m\u00e9thode d'\u00e9valuation statistique est toute forme d'outil math\u00e9matique qui peut \u00eatre utilis\u00e9 pour estimer la valeur d'un bien immobilier par le biais de calculs d\u00e9terministes.\n\nRock.estate utilise une impl\u00e9mentation d'un mod\u00e8le d'\u00e9valuation automatis\u00e9 bas\u00e9 sur des comparables (CBAVM ou simplement AVM). Les AVM bas\u00e9s sur des comparables utilisent des algorithmes sophistiqu\u00e9s pour s\u00e9lectionner des biens comparables et calculer des estimations de valeur pour un bien sp\u00e9cifique \u00e0 une date donn\u00e9e, en utilisant des techniques de mod\u00e9lisation math\u00e9matique de mani\u00e8re automatis\u00e9e. \n\nLes \u00e9valuations automatiques sont par conception automatiques : elles sont r\u00e9alis\u00e9es \u00e0 distance sans aucune intervention humaine.\n\n",
            'what-is-dvm_tooltip': '',
            'what-is-dvm_question': "Qu'est-ce qu'une \u00e9valuation \u00e0 distance ? ",
            'what-is-dvm_answer':
                " Une \u00e9valuation \u00e0 distance est r\u00e9alis\u00e9e par un \u00e9valuateur, \u00e0 distance, sans visiter l'int\u00e9rieur du bien. L'\u00e9valuateur s'appuie sur diverses sources de donn\u00e9es, un mod\u00e8le statistique et d'\u00e9ventuelles informations fournies par l'emprunteur.\n\n\nUne \u00e9valuation \u00e0 distance r\u00e9ussie combine le meilleur des deux mondes : un mod\u00e8le solide bas\u00e9 sur des donn\u00e9es, supervis\u00e9 par un \u00e9valuateur qui corrige et compl\u00e8te le mod\u00e8le. Il s'agit d'une alternative valable aux \u00e9valuations sur site pour la majeure partie des biens immobiliers r\u00e9sidentiels.",
            'what-is-ovm_tooltip': '',
            'what-is-ovm_question': "Qu'est-ce qu'une \u00e9valuation sur place ?",
            'what-is-ovm_answer':
                "L'\u00e9valuation sur place est l'\u00e9valuation traditionnelle au cours de laquelle un \u00e9valuateur visite l'int\u00e9rieur et l'ext\u00e9rieur d'une propri\u00e9t\u00e9 pour en estimer la valeur. Cette m\u00e9thode offre la vision la plus compl\u00e8te de la valeur d'un bien. C'est \u00e9galement l'alternative d'\u00e9valuation qui n\u00e9cessite le plus de ressources.",
            'what-is-dashboard_tooltip': '',
            'what-is-dashboard_question': "Qu'est-ce que le tableau de bord ?",
            'what-is-dashboard_answer':
                "Le tableau de bord est l'endroit o\u00f9 vous pouvez avoir acc\u00e8s \u00e0 toutes vos demandes et \u00eatre inform\u00e9 de leur statut actuel ; envoy\u00e9es, \u00e0 compl\u00e9ter, \u00e9valu\u00e9es par nos sp\u00e9cialistes de l'\u00e9valuation ou si une demande est recommand\u00e9e pour \u00eatre \u00e9valu\u00e9e sur place.\n\nCet endroit vous permet de :\n- d'avoir acc\u00e8s \u00e0 une demande qui n'a pas encore \u00e9t\u00e9 soumise pour \u00e9valuation\n- voir les informations remplies d'une demande d\u00e9j\u00e0 soumise\n- t\u00e9l\u00e9charger le rapport d'\u00e9valuation si la demande a \u00e9t\u00e9 examin\u00e9e par un sp\u00e9cialiste de l'\u00e9valuation\n- de prendre le relais sur une demande qui n'a pas encore \u00e9t\u00e9 finie par un autre membre de votre \u00e9quipe  par exemple (si applicable).\n",
            'what-is-draft_tooltip': '',
            'what-is-draft_question':
                'Que se passe-t-il lorsque ma demande a le statut de "\u00e0 compl\u00e9ter" ?',
            'what-is-draft_answer':
                'Il existe deux types de sc\u00e9narios lorsqu\'une demande est sous le statut "\u00e0 compl\u00e9ter". Ils n\u00e9cessitent tous deux des actions de la part du demandeur. \n1) Le conseiller hypoth\u00e9caire attend des informations manquantes de la part de son client concernant le bien.\n2) L\'\u00e9valuateur a trouv\u00e9 des informations incoh\u00e9rentes qui doivent \u00eatre corrig\u00e9es.',
            'market-price-incorrect_tooltip': '',
            'market-price-incorrect_question':
                'Le prix du march\u00e9 indiqu\u00e9 dans le rapport est incorrect',
            'market-price-incorrect_answer':
                "Si la valeur marchande donn\u00e9e par l'Exper immobilier ne correspond pas au prix de la transaction de la demande de pr\u00eat, veuillez vous assurer que la propri\u00e9t\u00e9 \u00e9valu\u00e9e est la bonne et que les donn\u00e9es fournies par le demandeur lors de la soumission de sa demande sont exactes. Si les donn\u00e9es sont correctes, contactez votre responsable pour savoir quelles sont les prochaines \u00e9tapes \u00e0 suivre. Si, apr\u00e8s examen, vous pensez toujours que l'\u00e9cart est trop important, veuillez nous contacter via notre formulaire de contact pour obtenir des informations suppl\u00e9mentaires.\n\n",
            'issue-parcels_tooltip': '',
            'issue-parcels_question':
                'Les parcelles indiqu\u00e9es dans le rapport ne correspondent pas \u00e0 la propri\u00e9t\u00e9',
            'issue-parcels_answer':
                "Si la ou les parcelles s\u00e9lectionn\u00e9es pour l'\u00e9valuation ne sont pas correctes, cela peut provenir d'une mauvaise s\u00e9lection lors du processus de saisie des donn\u00e9es. \nVeuillez suivre les \u00e9tapes simples ci-dessous pour v\u00e9rifier en premier lieu les donn\u00e9es saisies lors de la cr\u00e9ation de la demande :\n- Connectez-vous \u00e0 l'application d'\u00e9valuation de Rock.estate\n- Cliquez sur le bouton \"Tableau de bord\".\n- Dans la barre d'outils de recherche, remplissez la demande d'\u00e9valuation avec la mauvaise zone de parcelle.\n- Si vous voyez la demande, cliquez sur le bouton \"voir ma demande\" sur le c\u00f4t\u00e9 droit.\nVous devriez \u00eatre en mesure de v\u00e9rifier les donn\u00e9es saisies\n\nSi la saisie est correcte, veuillez nous en informer via notre formulaire de contact car il s'agit d'une information importante ayant un impact sur la valeur de la propri\u00e9t\u00e9",
            'old-picture-or-3d_tooltip': '',
            'old-picture-or-3d_question':
                "Le bien a consid\u00e9rablement chang\u00e9 par rapport aux photos ou au mod\u00e8le 3D figurant dans le rapport d'\u00e9valuation",
            'old-picture-or-3d_answer':
                "Si vous vous rendez compte que l'\u00e9valuation \u00e9tait bas\u00e9e sur d'anciennes photos de la propri\u00e9t\u00e9 qui ne correspondent plus \u00e0 la r\u00e9alit\u00e9, veuillez nous contacter via notre formulaire de contact pr\u00e9sent au bas de la page. ",
            'report-language_tooltip': '',
            'report-language_question':
                'Comment puis-je obtenir le rapport dans une autre langue ?',
            'report-language_answer':
                "Pour obtenir le rapport dans une autre langue : \n- Allez sur votre plateforme d'\u00e9valRock.estate \n- Allez sur votre tableau de bord\n- Dans le coin droit, s\u00e9lectionnez la langue dans laquelle vous souhaitez obtenir le rapport.\n\nVous pouvez maintenant t\u00e9l\u00e9charger le rapport dans la bonne langue !\n\nVeuillez noter que la remarque de notre \u00e9valuateur ne sera pas traduite car il s'agit d'une saisie manuelle effectu\u00e9e par un humain au moment de l'\u00e9valuation.",
            'wrong-pictures_tooltip': '',
            'wrong-pictures_question':
                "La photo figurant sur le rapport d'\u00e9valuation ne correspond pas au b\u00e2timent \u00e9valu\u00e9",
            'wrong-pictures_answer':
                "La vue de la rue sur le rapport est s\u00e9lectionn\u00e9e par Google et parfois ne repr\u00e9sente pas la propri\u00e9t\u00e9 \u00e9valu\u00e9e. Cela ne signifie pas que l'\u00e9valuateur a \u00e9valu\u00e9 la mauvaise propri\u00e9t\u00e9. Si vous avez acc\u00e8s au rapport Web, l'image de la vue des rues est en fait interactive et vous pouvez vous d\u00e9placer dans la rue.",
            sharing_tooltip: '',
            sharing_question:
                "Puis-je partager ce rapport d'\u00e9valuation avec mon client ?",
            sharing_answer:
                "Ceci est li\u00e9 aux r\u00e8gles internes appliqu\u00e9es par votre entreprise. \nNous vous recommandons de discuter avec votre responsable direct afin de confirmer si le rapport d'\u00e9valuation peut \u00eatre partag\u00e9.",
            'remarks-language_tooltip': '',
            'remarks-language_question':
                "Pourquoi les remarques du rapport d'\u00e9valuation ne changent-elles pas en fonction de la langue du tableau de bord si le reste du rapport le fait ?",
            'remarks-language_answer':
                "Lorsqu'un demande d'\u00e9valuation est entr\u00e9e dans une langue sp\u00e9cifique (par exemple, le fran\u00e7ais), la remarque sera r\u00e9dig\u00e9e soit dans la langue s\u00e9lectionn\u00e9e, soit en anglais. Si le demandeur change de langue apr\u00e8s avoir soumis sa demande, la langue des remarques ne sera pas modifi\u00e9e en cons\u00e9quence car ce n'est pas automatis\u00e9. ",
            'appeal-missing-feature_tooltip': '',
            'appeal-missing-feature_question':
                "L'expert immobilier n'a pas tenu compte d'un \u00e9l\u00e9ment (panneaux solaires, parcelle suppl\u00e9mentaire, piscine,...)",
            'appeal-missing-feature_answer':
                "Si l'expert immobilier n'a pas pris en compte un \u00e9l\u00e9ment qui pourrait potentiellement avoir un impact sur la valeur de la propri\u00e9t\u00e9, veuillez nous contacter via notre formulaire de contact pour nous le faire savoir.\nLes \u00e9l\u00e9ments consid\u00e9r\u00e9s comme potentiellement importants sont les suivants :\n- L'adresse\n- Surface du b\u00e2timent\n- Surface de la parcelle\n- Le score PEB\n- Surface habitable\n- Ann\u00e9e de construction\n- Nombre de chambres \u00e0 coucher\n- \u00c9tage(s) de l'appartement\n- Pr\u00e9sence d'un jardin priv\u00e9 ou commun\n- Terrasse\n- Piscine ext\u00e9rieure\n- Panneau(x) solaire(s)\n- Classement historique du bien (fa\u00e7ade ou b\u00e2timent complet)\n- Zones d'inondation\n- B\u00e2timents secondaires\n- Pr\u00e9sence d'un parking ou d'un garage ferm\u00e9\n\n",
            'where-customer-ref_tooltip': '',
            'where-customer-ref_question':
                'O\u00f9 puis-je trouver la r\u00e9f\u00e9rence interne du pr\u00eat ?',
            'where-customer-ref_answer':
                'Cette r\u00e9f\u00e9rence est interne \u00e0 la banque et nous vous sugg\u00e9rons de copier/coller cette r\u00e9f\u00e9rence depuis votre syst\u00e8me de cr\u00e9ation de pr\u00eats.',
            'building-type-apartment-building_tooltip': '',
            'building-type-apartment-building_question':
                'Comment puis-je demander une \u00e9valuation pour un immeuble de rapport ?',
            'building-type-apartment-building_answer':
                "Rock.estate n'\u00e9value pas les immeubles de rapport. Une demande de ce type entra\u00eenera une \u00e9valuation sur place et ne sera pas \u00e9valu\u00e9e.",
            'address-not-found_tooltip': '',
            'address-not-found_question':
                "L'application d'\u00e9valuation Rock.estate ne reconna\u00eet pas l'adresse du bien financ\u00e9",
            'address-not-found_answer':
                " Si l'auto-compl\u00e9tion ne fonctionne pas et que l'application ne trouve pas l'adresse, vous pouvez la saisir manuellement en : \n- Retournant sur votre demande d'\u00e9valuation.\n- Dans la page de s\u00e9lection des b\u00e2timents et parcelles, cliquez sur \"Je ne trouve pas le b\u00e2timent principal\".\n- Suivez les instructions",
            'building-not-found_tooltip': '',
            'building-not-found_question':
                "Le building s\u00e9lectionn\u00e9 automatiquement n'est pas le bon. Que dois-je faire?",
            'building-not-found_answer':
                'Si vous ne trouvez pas le b\u00e2timent, vous pouvez suivre la proc\u00e9dure suivante :\n-Cliquez sur "Ceci pas le bon b\u00e2timent" et la plateforme vous permettra d\'en s\u00e9lectionner un autre.\n-Si vous ne pouvez toujours pas le s\u00e9lectionner/le trouver, cliquez sur "Je ne trouve pas le b\u00e2timent principal" et vous serez redirig\u00e9 vers une nouvelle page. Sur cette page, vous devrez fournir des informations suppl\u00e9mentaires telles que la taille de la parcelle, la taille du b\u00e2timent et le nombre de c\u00f4t\u00e9s qui se touchent. \n\n',
            'find-epc_tooltip': '',
            'find-epc_question':
                "O\u00f9 puis-je trouver le PEB d'une propri\u00e9t\u00e9 ?",
            'find-epc_answer':
                "Le PEB est obligatoire lors de la vente d'un bien immobilier. Si l'emprunteur ne peut pas fournir cette information, essayez de la trouver sur l'annonce de vente. \nN'h\u00e9sitez pas \u00e0 sauver votre demande avant de chercher le PEB.",
            'epc-label-vs-number_tooltip': '',
            'epc-label-vs-number_question':
                "PEB : Est-il pr\u00e9f\u00e9rable de donner le label (lettre de A \u00e0 G) ou de donner l'exacte kWh/m\u00b2 ?",
            'epc-label-vs-number_answer':
                "L'\u00e9valuation d\u00e9pend fortement de l'emplacement, de la parcelle, de la surface habitable, de l'ann\u00e9e de construction et du score PEB. C'est pourquoi nous vous recommandons de saisir la demande avec le score PEB exact en kWh/m\u00b2/an. \nSi vous ne disposez que de la lettre du label de A \u00e0 G, nous \u00e9valuerons le bien en prenant le score moyen de chaque label, ce qui donnera une \u00e9valuation approximative de la valeur marchande du bien.",
            'find-living-area_tooltip': '',
            'find-living-area_question':
                "O\u00f9 puis-je trouver la surface habitable exacte d'une propri\u00e9t\u00e9 ?",
            'find-living-area_answer':
                "Vous pouvez trouver la surface habitable dans plusieurs documents li\u00e9s \u00e0 la propri\u00e9t\u00e9 comme, par exemple, le contrat de vente ou le certificat PEB. Veuillez noter que tout b\u00e2timent secondaire, sous-sol ou grenier non utilis\u00e9 n'est pas consid\u00e9r\u00e9 comme un espace habitable et donc comme une surface habitable. ",
            'find-box-number_tooltip': '',
            'find-box-number_question':
                "O\u00f9 puis-je trouver le num\u00e9ro de bo\u00eete aux lettres d'un appartement ?",
            'find-box-number_answer':
                "Le num\u00e9ro de bo\u00eete d'un appartement fait partie de l'adresse qui doit \u00eatre compl\u00e9t\u00e9e sur le contrat de vente ou le certificat PEB. Si vous n'\u00eates pas s\u00fbr de cette information, veuillez contacter l'emprunteur et compl\u00e9ter la demande une fois que vous aurez les informations. ",
            'find-construction-year_tooltip': '',
            'find-construction-year_question':
                "O\u00f9 puis-je trouver l'ann\u00e9e de construction ?",
            'find-construction-year_answer':
                "L'ann\u00e9e de construction peut \u00eatre trouv\u00e9e dans le contrat de vente ou le certificat PEB, par exemple. Veuillez noter que l'ann\u00e9e de construction est l'ann\u00e9e de la construction effective du b\u00e2timent et non l'ann\u00e9e d'une \u00e9ventuelle r\u00e9novation. ",
            'construction-vs-renovation_tooltip': '',
            'construction-vs-renovation_question':
                "La propri\u00e9t\u00e9 a \u00e9t\u00e9 enti\u00e8rement r\u00e9nov\u00e9e: quelle doit \u00eatre l'ann\u00e9e de construction, est-ce l'ann\u00e9e d'origine ou la date de la r\u00e9novation ?",
            'construction-vs-renovation_answer':
                "L'ann\u00e9e de construction est l'ann\u00e9e r\u00e9elle o\u00f9 le b\u00e2timent a \u00e9t\u00e9 construit, nous ne tenons pas compte de l'ann\u00e9e des r\u00e9novations. Le contrat de vente ou le certificat PEB refl\u00e8te ces r\u00e9novations. ",
            'over-two-days_tooltip': '',
            'over-two-days_question':
                "Cela fait plus de 2 jours ouvrables et ma demande n'est toujours pas valoris\u00e9e, que dois-je faire ?",
            'over-two-days_answer':
                "Les jours ouvrables de Rock.estate vont du lundi au vendredi. \nIl se peut que vous ayez compt\u00e9 un samedi ou un jour f\u00e9ri\u00e9 dans les deux jours ouvrables auxquels vous faites r\u00e9f\u00e9rence. \n\nSi ce n'est pas le cas, contactez-nous via notre formulaire de contact en indiquant le num\u00e9ro de r\u00e9f\u00e9rence.",
            'right-info-flagged-incorrect_tooltip': '',
            'right-info-flagged-incorrect_question':
                "Que se passe-t-il si l'expert signale une information saisie dans la demande comme \"incorrecte\" alors que je sais qu'il s'agit de la bonne information ?",
            'incoherent-submitted-twice_tooltip': '',
            'incoherent-submitted-twice_question':
                'Que se passe-t-il lorsqu\'une \u00e9valuation est renvoy\u00e9e deux fois avec des informations dites "incorrectes" ?',
            'processing-time_tooltip': '',
            'processing-time_question':
                "Combien de temps faut-il pour qu'une demande soit trait\u00e9e ?",
            'multiple-addresses_tooltip': '',
            'multiple-addresses_question':
                "Est-il normal qu'il y ait plus d'une adresse pour un m\u00eame b\u00e2timent ?",
            'what-is-bedroom_tooltip': '',
            'what-is-bedroom_question': "Qu'est-ce qu'une chambre ?",
            'what-is-garden_tooltip': '',
            'what-is-garden_question': "Qu'est-ce qu'un jardin commun ?",
            'what-is-private-garden_tooltip': '',
            'what-is-private-garden_question':
                "Qu'est-ce qui est consid\u00e9r\u00e9 comme un jardin priv\u00e9 ?",
            'what-is-closed-garage_tooltip': '',
            'what-is-closed-garage_question': "Qu'est-ce qu'un garage ferm\u00e9 ?",
            'what-is-parking-spot_tooltip': '',
            'what-is-parking-spot_question': "Qu'est-ce qu'une place de parking ?",
            'what-is-cellar-basement_tooltip': '',
            'what-is-cellar-basement_question': "Qu'est-ce qu'une cave/sous-sol ?",
            carport_tooltip: '',
            carport_question:
                'Un carport d\u00e9signe-t-il une place de stationnement ou un garage ?',
            carport_answer:
                'Un carport d\u00e9signe une place de stationnement et non un garage ferm\u00e9. ',
            'personal-use_tooltip': '',
            'personal-use_question':
                'Puis-je demander une \u00e9valuation de mon propre bien ?',
            'personal-use_answer':
                "La plateforme d'\u00e9valuation Rock.estate ne peut \u00eatre utilis\u00e9e qu'\u00e0 des fins professionnelles.\nIl est de la responsabilit\u00e9 de chaque utilisateur autoris\u00e9 de s'y conformer. Par cons\u00e9quent, nous ne vous recommandons pas de demander une \u00e9valuation de votre propre propri\u00e9t\u00e9 ou de celle d'une personne que vous connaissez.",
            'is-request-submitted_tooltip': '',
            'is-request-submitted_question':
                "Comment puis-je savoir si j'ai soumis ma demande avec succ\u00e8s ?",
            'is-request-submitted_answer':
                "Une fois que vous avez soumis/envoy\u00e9 votre demande avec succ\u00e8s, le statut de la demande dans votre tableau de bord devrait passer \u00e0 'Envoy\u00e9'. \nVous recevrez \u00e9galement un email vous informant que la demande a \u00e9t\u00e9 envoy\u00e9e et qu'un de nos sp\u00e9cialistes la traitera dans les deux jours ouvrables.",
            review_tooltip: '',
            review_question:
                'La demande que j\'ai soumise est revenue comme une demande "\u00e0 completer" en rouge, que dois-je faire ?',
            review_answer:
                'Si vous avez re\u00e7u une demande dont le statut est "\u00c0 compl\u00e9ter", cela signifie que l\'\u00e9valuateur a signal\u00e9 une ou plusieurs informations comme \u00e9tant "incorrectes". Si vous savez que cette ou ces informations sont effectivement correctes, vous pouvez soit d\u00e9cider de soumettre \u00e0 nouveau la demande telle quelle, soit nous contacter via notre formulaire de contact. ',
            'find-previous-request_tooltip': '',
            'find-previous-request_question':
                'Comment puis-je retrouver une demande ant\u00e9rieure ?',
            'find-previous-request_answer':
                "Pour retrouver une demande ant\u00e9rieure, allez dans votre tableau de bord et utilisez la barre d'outils de recherche. Vous pouvez entrer une date, une r\u00e9f\u00e9rence, une r\u00e9f\u00e9rence interne, une adresse, ...",
            'download-report_tooltip': '',
            'download-report_question':
                'Comment puis-je t\u00e9l\u00e9charger le rapport ?',
            'download-report_answer':
                "Pour t\u00e9l\u00e9charger le rapport, vous pouvez cliquer sur la case verte situ\u00e9e \u00e0 c\u00f4t\u00e9 de votre demande et intitul\u00e9e 'Rapport PDF'.  \nIl ne sera disponible qu'une fois que votre demande aura \u00e9t\u00e9 \u00e9valu\u00e9e avec succ\u00e8s par l'\u00e9quipe d'\u00e9valuation de Rock.estate.",
            'language-impact_tooltip': '',
            'language-impact_question':
                "Le fait d'utiliser l'application d'\u00e9valuation Rock.estate dans une autre langue change-t-il quelque chose au processus ?",
            'language-impact_answer':
                "Si vous demandez une \u00e9valuation dans une langue, vous recevrez un rapport sur ce bien dans la m\u00eame langue. Vous pouvez changer la langue du rapport mais la remarque restera dans la langue d'origine.\nIl n'y aura aucun impact sur les donn\u00e9es ou la valeur marchande d'un bien.",
            'unsubmission-window_tooltip': '',
            'unsubmission-window_question':
                "Comment puis-je modifier une demande apr\u00e8s l'avoir soumise ?",
            'unsubmission-window_answer':
                'Vous avez 60 minutes pour modifier ou effacer une demande. Pour ce faire, rendez-vous dans votre tableau de bord et cliquez sur la case jaune "Modifier". Apr\u00e8s avoir modifi\u00e9 votre demande, veillez \u00e0 cliquer sur "Envoyer" pour la soumettre \u00e0 nouveau. ',
            'after-sixty-minutes_tooltip': '',
            'after-sixty-minutes_question':
                'Comment puis-je modifier une demande soumise si les 60 minutes sont d\u00e9pass\u00e9es ?',
            'after-sixty-minutes_answer':
                "Si vous souhaitez modifier ou annuler une demande apr\u00e8s 60 minutes, faites-le nous savoir d\u00e8s que possible via notre formulaire de contact. Cette demande pourrait \u00eatre factur\u00e9e en fonction de la progression de l'\u00e9valuateur.",
            'review-billing_tooltip': '',
            'review-billing_question':
                'Ma demande est revenue sous forme de brouillon, cela signifie-t-il que la banque devra payer deux fois pour cette demande ?',
            'review-billing_answer':
                "Non, la banque ne sera factur\u00e9e qu'une seule fois apr\u00e8s que la demande ait \u00e9t\u00e9 \u00e9valu\u00e9e avec succ\u00e8s ou qu'une \u00e9valuation sur place ait \u00e9t\u00e9 recommand\u00e9e. \nSi, apr\u00e8s deux analyses de l'\u00e9quipe d'\u00e9valuation de Rock.estate, la demande ne peut \u00eatre \u00e9valu\u00e9e \u00e0 distance avec succ\u00e8s, une recommandation pour une \u00e9valuation sur place sera effectu\u00e9e.",
            'on-site-next-steps_tooltip': '',
            'on-site-next-steps_question':
                'Une \u00e9valuation sur place est recommand\u00e9e, quelle est la prochaine \u00e9tape ?',
            'on-site-next-steps_answer':
                "Rock.estate n'est actuellement pas impliqu\u00e9e dans le processus d'\u00e9valuation sur site. Nous vous recommandons de suivre la proc\u00e9dure interne de la Banque pour l'\u00e9valuation sur place.",
            'access-requests_tooltip': '',
            'access-requests_question':
                'Comment puis-je avoir acc\u00e8s aux demandes de mes coll\u00e8gues ?',
            substitution_tooltip: '',
            substitution_question:
                "Comment puis-je m'approprier une demande qui n'est pas la mienne ?",
        },
        questions_feedback: {
            explanation_1:
                "Vous avez une question ou un commentaire sp\u00e9cifique concernant ce rapport? Ou vous souhaitez simplement obtenir plus d'informations sur la mani\u00e8re dont Rock.estate proc\u00e8de \u00e0 ces valorisations?",
            explanation_1_tooltip: '',
            explanation_2:
                'Veuillez visiter notre plateforme de valorisation : {url.valuation_app}.',
            explanation_2_tooltip: '',
        },
        email: {
            submission_confirmation_subject:
                "Votre demande d'\u00e9valuation a \u00e9t\u00e9 soumise",
            submission_confirmation_subject_tooltip: '',
            submission_confirmation_1:
                "Ch\u00e8re Madame, cher Monsieur,\n\nNous avons bien re\u00e7u votre nouvelle demande d'\u00e9valuation avec les r\u00e9f\u00e9rences suivantes:",
            submission_confirmation_1_tooltip: '',
            submission_confirmation_2:
                "Notre \u00e9quipe d'experts en \u00e9valuation va traiter votre demande dans les plus brefs d\u00e9lais. Vous pouvez suivre le progr\u00e8s de votre demande dans votre tableau r\u00e9capitulatif Rock.estate.\n\nD'avance merci,\nL'\u00e9quipe d'experts en \u00e9valuation Rock.estate.",
            submission_confirmation_2_tooltip: '',
            valuation_notification_subject:
                "Votre demande d'\u00e9valuation a \u00e9t\u00e9 trait\u00e9e",
            valuation_notification_subject_tooltip: '',
            valuation_notification_1:
                'Cher Madame, Monsieur,\n\nLa demande suivante a \u00e9t\u00e9 examin\u00e9e et \u00e9valu\u00e9e avec succ\u00e8s par nos experts:',
            valuation_notification_1_tooltip: '',
            valuation_notification_2:
                "Veuillez vous connecter \u00e0 votre tableau de bord pour t\u00e9l\u00e9charger le rapport.\n\nBien \u00e0 vous,\nL'\u00e9quipe de sp\u00e9cialistes de l'\u00e9valuation de Rock.estate",
            valuation_notification_2_tooltip: '',
            postpone_subject:
                "Votre demande d'\u00e9valuation a \u00e9t\u00e9 suspendue. ",
            postpone_subject_tooltip: '',
            postpone_1: 'Ch\u00e8re Madame, cher Monsieur,',
            postpone_1_tooltip: '',
            postpone_2: 'suspendue',
            postpone_2_tooltip: '',
            postpone_3: 'merci',
            postpone_3_tooltip: '',
            incoherent_features_subject:
                "Votre demande d'\u00e9valuation n'a pas pu \u00eatre trait\u00e9e",
            incoherent_features_subject_tooltip: '',
            incoherent_features_1:
                "Ch\u00e8re Madame, cher Monsieur,\n\nLa demande d'\u00e9valuation que vous avez envoy\u00e9e (voir r\u00e9f\u00e9rences ci-dessous) ne peut pas \u00eatre compl\u00e9t\u00e9e en raison d'une ou plusieurs information(s) incorrecte(s):",
            incoherent_features_1_tooltip: '',
            incoherent_features_2:
                'Notre expert immobilier a remarqu\u00e9, pour cette demande, que les caract\u00e9ristiques suivantes pourraient \u00eatre incorrectes: ',
            incoherent_features_2_tooltip: '',
            incoherent_features_3: 'Commentaire additionel:',
            incoherent_features_3_tooltip: '',
            incoherent_features_4:
                "Nous vous remercions de corriger au besoin cette/ces information(s) depuis votre tableau de bord Rock.estate. D\u00e8s que les changements n\u00e9cessaires sont apport\u00e9s, veuillez soumettre \u00e0 nouveau votre demande et notre \u00e9quipe d'experts proc\u00e9dera \u00e0 l'\u00e9valuation. \n\nSi l'information s'av\u00e8re en effet correcte, vous ne devez rien modifier, il vous suffit de soumettre votre demande d'\u00e9valuation \u00e0 nouveau.\n\nD'avance merci,\nL'\u00e9quipe d'Experts Rock.estate.",
            incoherent_features_4_tooltip: '',
            owner_updated_subject:
                "Votre demande d'\u00e9valuation a chang\u00e9 d'utilisateur",
            owner_updated_subject_tooltip: '',
            owner_updated_1:
                "Ch\u00e8re Madame, Cher Monsieur,\n\nLa demande d'\u00e9valuation r\u00e9f\u00e9renc\u00e9e ci-dessous est maintenant attribu\u00e9e \u00e0 l'utilisateur suivant : ",
            owner_updated_1_tooltip: '',
            owner_updated_2:
                "Si vous souhaitez suivre l'\u00e9volution de cette demande ou en reprendre la charge, veuillez vous rendre dans l'onglet \"Toutes les demandes\" de votre tableau de bord Rock.estate et indiquer les r\u00e9f\u00e9rences ci-dessus dans les champs de recherche appropri\u00e9s.\n\nBien \u00e0 vous,\n\nL'\u00e9quipe d'experts immobilier de Rock.estate",
            owner_updated_2_tooltip: '',
            on_site_requested_value:
                "Ch\u00e8re Madame, cher Monsieur,\n\nSelon notre expert en \u00e9valuation, la demande d'\u00e9valuation que vous avez envoy\u00e9e (voir r\u00e9f\u00e9rences ci-dessous) ne peut pas \u00eatre finalis\u00e9e:\n- Identifiant LOP:\n- Num\u00e9ro d'\u00e9valuation:\n- Adresse:\n\nEn raison du caract\u00e8re unique de ce bien et du contraste avec d'autres propri\u00e9t\u00e9s avoisinantes, une expertise sur site est pr\u00e9f\u00e9rable.\n\nD'avance merci,\n\nL'\u00e9quipe d'experts en \u00e9valuation Rock.estate",
            on_site_requested_value_tooltip: '',
            argenta: {
                on_site_requested_characteristics_1:
                    "Cher Monsieur, Madame, \n\nEn raison du caract\u00e8re unique et du contraste avec les autres propri\u00e9t\u00e9s des environs, une \u00e9valuation \u00e0 distance ne peut \u00eatre effectu\u00e9e par notre \u00e9quipe d'expert immobilier.",
                on_site_requested_characteristics_1_tooltip: '',
                on_site_requested_characteristics_2:
                    "Rock.estate sugg\u00e8re qu'une \u00e9valuation sur place soit effectu\u00e9e pour cette propri\u00e9t\u00e9.\n\nBien \u00e0 vous,\n\nL'\u00e9quipe d'experts immobilier de Rock.estate",
                on_site_requested_characteristics_2_tooltip: '',
                on_site_requested_characteristics_subject_tooltip: '',
            },
            onsite_request_subject: 'Une \u00e9valuation sur place est recommand\u00e9e',
            onsite_request_subject_tooltip: '',
            onsite_request_1:
                "Madame, Monsieur,\n\nApr\u00e8s examen par notre expert immobiler, la demande d'\u00e9valuation que vous avez soumise (voir r\u00e9f\u00e9rences ci-dessous) ne peut \u00eatre trait\u00e9e :",
            onsite_request_1_tooltip: '',
            onsite_request_2:
                "Par cons\u00e9quent, nous vous sugg\u00e9rons de suivre le proc\u00e9d\u00e9 interne de votre banque lorsqu'une expertise \u00e0 distance ne peut \u00eatre effectu\u00e9e.\nDans les cas ou des informations que vous avez remplies soient erron\u00e9es (exemple: mauvaise s\u00e9lection d'une parcelle, d'un batiment ou de certaines charact\u00e9ristiques du bien) nous vous invitons \u00e0 cr\u00e9er une nouvelle demande avec les informations correctes et nous ferons le n\u00e9cessaire.\n\nD'avance merci,\nL'\u00e9quipe d'experts en \u00e9valuation Rock.estate",
            onsite_request_2_tooltip: '',
            address_not_found:
                "Cher Monsieur, Madame,\n\nVous avez demand\u00e9 une \u00e9valuation via notre outil, cependant, il semble y avoir un probl\u00e8me concernant l'adresse du bien. \n\nPourriez-vous revoir la demande pour la propri\u00e9t\u00e9 avec les r\u00e9f\u00e9rences suivantes : \n- LOP ID : [LOP ID]\n- Valuation ID : [ID d'\u00e9valuation]\n- Adresse : [Adresse]\n\nNous n'avons pas pu trouver cette adresse dans nos outils internes ni sur internet. \nSi l'adresse est incorrecte, veuillez la corriger directement dans votre tableau de bord.\n\nNous avons besoin que toutes les informations soient remplies et enti\u00e8rement compl\u00e9t\u00e9es pour donner \u00e0 [BANK] une estimation correcte de la valeur de ce bien. D\u00e8s que l'adresse sera confirm\u00e9e, notre sp\u00e9cialiste en \u00e9valuation poursuivra l'\u00e9valuation. \n\nBien \u00e0 vous,\n\nL'\u00e9quipe de sp\u00e9cialistes en \u00e9valuation de Rock.estate",
            address_not_found_tooltip: '',
            onsite_streetview:
                "Nous ne sommes pas en mesure de fournir une expertise, car soit le bien n'est pas visible via nos outils streetview, soit les images streetview sont trop anciennes.",
            onsite_streetview_tooltip: '',
            onsite_incoherent_features:
                "Les caract\u00e9ristiques list\u00e9es ci-dessus semblent incorrectes pour cette propri\u00e9t\u00e9, de plus, nous avons renvoy\u00e9 cette demande pour r\u00e9vision et nous l'avons ensuite re\u00e7u avec un r\u00e9sultat toujours incorrect. ",
            onsite_incoherent_features_tooltip: '',
            onsite_wrong_building_selected:
                "Le b\u00e2timent ou la parcelle s\u00e9lectionn\u00e9(e) est incorrect(e) pour cette propri\u00e9t\u00e9, \nPour cette raison, nous ne pouvons pas faire d'expertise \u00e0 distance et vous recommandons de cr\u00e9er une nouvelle demande afin de s\u00e9lectionner le bon b\u00e2timent. \nNous examinerons alors la propri\u00e9t\u00e9 d\u00e8s que possible.",
            onsite_wrong_building_selected_tooltip: '',
            onsite_exceptional_property:
                'Nous ne sommes pas en mesure de fournir une expertise car le bien est consid\u00e9r\u00e9 comme exceptionnel pour le quartier. \nNous consid\u00e9rons comme "exceptionnel" tout bien qui poss\u00e8de des caract\u00e9ristiques inhabituelles pour le quartier, comme par exemple une surface habitable tr\u00e8s \u00e9lev\u00e9e/petite, la pr\u00e9sence d\'un court de tennis ou d\'une piscine alors que le reste du quartier en est d\u00e9pourvu, mais aussi si le bien est en mauvais \u00e9tat par rapport au reste de la rue par exemple.',
            onsite_exceptional_property_tooltip: '',
            onsite_commercial_building:
                'Nous ne sommes pas en mesure de fournir une expertise car le bien est un b\u00e2timent commercial ou a une activit\u00e9 commerciale qui lui est li\u00e9e.',
            onsite_commercial_building_tooltip: '',
            onsite_volume_missing:
                "Nous ne sommes pas en mesure de fournir une expertise car notre mod\u00e8le n'a pas pu r\u00e9cup\u00e9rer certains \u00e9l\u00e9ments essentiels qui pourraient calculer automatiquement le volume du b\u00e2timent et/ou de la parcelle. \nLe mod\u00e8le de Rock.estate rassemble des donn\u00e9es provenant de plusieurs sources telles que la commune ou la r\u00e9gion, il est possible que les donn\u00e9es n'aient pas encore \u00e9t\u00e9 mises \u00e0 jour par l'une de ces sources, ce qui emp\u00eache Rock.estate de proc\u00e9der \u00e0 une analyse objective.",
            onsite_volume_missing_tooltip: '',
            onsite_flood_zone:
                'Rock.estate a pour objectif de fournir \u00e0 votre banque une expertise objective de cette propri\u00e9t\u00e9.\nPour le bien en question, notre mod\u00e8le a d\u00e9tect\u00e9 que le bien pourrait \u00eatre situ\u00e9 dans une zone inondable. Cela pourrait potentiellement rendre difficile son assurance. De ce fait, nous ne somme pas en mesure de proc\u00e9d\u00e9 \u00e0 une expertise \u00e0 distance.',
            onsite_flood_zone_tooltip: '',
            onsite_few_comparison:
                "Nous ne sommes pas en mesure de fournir une expertise car notre mod\u00e8le n'a pas pu r\u00e9cup\u00e9rer suffisamment de points de donn\u00e9es pour les comparer avec le bien demand\u00e9.\nNotre expert a choisi de ne pas l'\u00e9valuer \u00e0 distance car il/elle a estim\u00e9 que l'expertise pouvait \u00eatre erron\u00e9e.",
            onsite_few_comparison_tooltip: '',
            onsite_extensive_renovation:
                "Nous ne sommes pas en mesure d'\u00e9tablir une expertise. Soit parce que la valeur des travaux pr\u00e9vus est trop \u00e9lev\u00e9e au regard de la valeur actuelle du bien. Soit parce que des travaux au dessus de 50 000\u20ac sont pr\u00e9vus. ",
            onsite_extensive_renovation_tooltip: '',
        },
        common: {
            cancel: 'Annuler',
            cancel_tooltip: '',
            save: 'Enregistrer',
            save_tooltip: '',
            edit: 'Modifier',
            edit_tooltip: '',
            total: 'Total',
            total_tooltip: '',
            previous: 'Pr\u00e9c\u00e9dent',
            previous_tooltip: '',
            next: 'Suivant',
            next_tooltip: '',
            uploading_tooltip: '',
            thank_you_so_far: 'Merci de nous avoir fourni ces informations',
            thank_you_so_far_tooltip: '',
            yes: 'Oui',
            yes_tooltip: '',
            no: 'Non',
            no_tooltip: '',
            number_input: 'Indiquez le score \u00e9nerg\u00e9tique ici',
            number_input_tooltip: '',
            multiple_choice: 'S\u00e9lectionnez une des options',
            multiple_choice_tooltip: '',
            we_are_sorry: 'Nous sommes d\u00e9sol\u00e9s',
            we_are_sorry_tooltip: '',
            sorry: 'Oups\u00a0!',
            sorry_tooltip: '',
            enter_address: "Indiquez l'adresse ici ",
            enter_address_tooltip: '',
            back_to_ing: 'Retour au site web ING',
            back_to_ing_tooltip: '',
            maintenance:
                'Ce site web est momentan\u00e9ment en maintenance. Nous esp\u00e9rons vous revoir bient\u00f4t',
            maintenance_tooltip: '',
            send: 'Envoyer',
            send_tooltip: '',
            start_now: 'D\u00e9marrer',
            start_now_tooltip: '',
            i_dont_know: 'Je ne sais pas',
            i_dont_know_tooltip: '',
        },
        renovation_info: {
            title: 'Devis de r\u00e9novation',
            title_tooltip: '',
            add_document_tooltip: '',
            unlink_document_tooltip: '',
            link_document_tooltip: '',
            items_tooltip: '',
            linked_items_tooltip: '',
        },
        renovation_item: {
            title_tooltip: '',
            amount_tooltip: '',
            category_tooltip: '',
            subcategory_tooltip: '',
            modal_title_tooltip: '',
        },
        renovation_category: {
            energy_tooltip: '',
            structural_tooltip: '',
            aesthetic_tooltip: '',
            other_tooltip: '',
        },
        renovation_subcategory: {
            insulation_tooltip: '',
            solar_panel_tooltip: '',
            heating_tooltip: '',
            other_tooltip: '',
            wall_extension_tooltip: '',
            demolition_tooltip: '',
            kitchen_tooltip: '',
            bathroom_tooltip: '',
        },
        steps: {
            building_type: 'Type de bien',
            building_type_tooltip:
                "S'agit-il d'une maison unifamiliale ou d'un appartement ?",
            address: 'Adresse',
            address_tooltip: '',
            address_confirmation: "Confirmation de l'adresse",
            address_confirmation_tooltip:
                "S\u00e9lectionnez l'adresse de l'habitation que vous souhaitez r\u00e9nover.",
            building_selection: 'B\u00e2timent',
            building_selection_tooltip: '',
            house_info: 'Informations maison',
            house_info_tooltip: '',
            epc: 'PEB',
            epc_tooltip:
                "Le certificat PEB (Performance \u00c9nerg\u00e9tique du B\u00e2timent) est la carte d'identit\u00e9 \u00e9nerg\u00e9tique d'un b\u00e2timent. Cette information est fourni dans l'annonce immo, ou dans un document PEB si vous achetez un bien. Le score est exprim\u00e9 en kWh/m2/an.",
            f_construction_year: 'Ann\u00e9e de construction',
            f_construction_year_tooltip:
                "L'ann\u00e9e de construction officielle du b\u00e2timent. Ceci ne tient pas compte d'\u00e9ventuelles r\u00e9novations ayant eu lieu plus tard. Cette information est g\u00e9n\u00e9ralement fournie dans l'annonce immo, dans le certificat PEB ou dans le plan cadastral.",
            living_area: 'Surface habitable',
            living_area_tooltip:
                "La surface habitable totale du bien. Cette information est g\u00e9n\u00e9ralement fournie dans l'annonce immo, ou dans le certificat PEB.",
            insulation: 'Isolation',
            insulation_tooltip: '',
            walls: 'Murs',
            walls_tooltip: '',
            roof: 'Toit',
            roof_tooltip: '',
            floor: 'Sols',
            floor_tooltip: '',
            windows: 'Ch\u00e2ssis',
            windows_tooltip: '',
            attic: 'Grenier',
            attic_tooltip: '',
            basement: 'Cave',
            basement_tooltip: '',
            consumption: 'Consommation',
            consumption_tooltip: '',
            heating: 'Chauffage',
            heating_tooltip: '',
            electricity_consumption: 'Consommation \u00e9lectrique',
            electricity_consumption_tooltip: '',
            gas_consumption: 'Consommation de gaz',
            gas_consumption_tooltip: '',
            oil_consumption: 'Consommation de mazout',
            oil_consumption_tooltip: '',
            coal_consumption: 'Consommation de charbon',
            coal_consumption_tooltip: '',
            wood_consumption: 'Consommation de bois',
            wood_consumption_tooltip: '',
            pellets_consumption: 'Consommation de pellets',
            pellets_consumption_tooltip: '',
            district_heating_consumption: 'R\u00e9seau de chaleur/ chauffage urbain',
            district_heating_consumption_tooltip: '',
            solar_panels: 'Panneaux solaires',
            solar_panels_tooltip: '',
            ventilation: 'Ventilation',
            ventilation_tooltip: '',
            consumers: 'Consommateurs',
            consumers_tooltip: '',
            electric_vehicles: 'Voitures \u00e9lectriques',
            electric_vehicles_tooltip: '',
            processing: 'Calcul en cours',
            processing_tooltip: '',
        },
        property_type: {
            apartments: 'Appartements',
            apartments_tooltip: '',
            coming_soon:
                'Notre app ne fonctionne pas encore pour les appartements.  C\u2019est pour bient\u00f4t !',
            coming_soon_tooltip: '',
            select_type: " Indiquez l'adresse ici",
            select_type_tooltip: '',
            title: 'Quel est le type de bien ?',
            title_tooltip: '',
        },
        out_of_bounds_page: {
            title: "Nous n'avons pas trouv\u00e9 des donn\u00e9es sur cette addresse",
            title_tooltip: '',
            content_tooltip: '',
            button: 'Essayer avec une autre adresse',
            button_tooltip: '',
            ing_button: 'Retourner sur le site d\u2019ING',
            ing_button_tooltip: '',
        },
        terms_page: {
            welcome: 'n/a',
            welcome_tooltip: '',
            epc: 'n/a',
            epc_tooltip: '',
            disclaimer: 'n/a',
            disclaimer_tooltip: '',
        },
        features_question: {
            insulated_floors: 'Les sols sont-ils isol\u00e9s ?',
            insulated_floors_tooltip: '',
            insulated_roof: 'Le toit est-il isol\u00e9 ?',
            insulated_roof_tooltip: '',
            insulated_walls: 'Les murs sont-ils isol\u00e9s ?',
            insulated_walls_tooltip: '',
            has_attic: 'Y a-t-il un grenier ?',
            has_attic_tooltip: '',
            insulation_floors_when: "De quand datent les derniers travaux d'isolation ?",
            insulation_floors_when_tooltip: '',
            insulation_roof_when: 'Quand\u00a0?',
            insulation_roof_when_tooltip: '',
            insulation_walls_when: 'Quand\u00a0?',
            insulation_walls_when_tooltip: '',
            has_basement: 'Y a-t-il une cave ?',
            has_basement_tooltip: '',
            is_attic_heated: 'Cette pi\u00e8ce est-elle chauff\u00e9e ?',
            is_attic_heated_tooltip: '',
            epc_known: "Connaissez-vous le score PEB officiel de l'habitation ?",
            epc_known_tooltip: '',
            select_heating_fuel: 'S\u00e9lectionnez le combustible de chauffage ',
            select_heating_fuel_tooltip: '',
            ventilation_type: 'Quel type de ventilation y a-t-il ?',
            ventilation_type_tooltip: '',
            ventilation_subtype: 'Quel type de ventilation y a-t-il ?',
            ventilation_subtype_tooltip: '',
            n_residents: "Combien de personnes vivent dans l'habitation ?",
            n_residents_tooltip: '',
            protected_consumer: 'n/a',
            protected_consumer_tooltip: '',
            heating_consumption_approx:
                'Merci de nous donner une estimation de la consommation de chauffage',
            heating_consumption_approx_tooltip: '',
            electric_consumption_approx:
                "Merci de nous donner une estimation de la consommation d'\u00e9lectricit\u00e9",
            electric_consumption_approx_tooltip: '',
            installation_year: "De quelle ann\u00e9e date l'installation ?",
            installation_year_tooltip: '',
            heater: 'Quel type de chauffage y a-t-il ?',
            heater_tooltip: '',
            consumption_known: "Connaissez-vous votre consommation d'\u00e9nergie ?",
            consumption_known_tooltip: '',
            yearly_electricity_consumption:
                "Connaissez-vous votre consommation d'\u00e9lectricit\u00e9 annuelle ?",
            yearly_electricity_consumption_tooltip: '',
            yearly_fuel_consumption:
                'Connaissez-vous votre consommation de combustible ?',
            yearly_fuel_consumption_tooltip: '',
            yearly_heating_consumption:
                'Connaissez-vous votre consommation annuelle de chauffage ?',
            yearly_heating_consumption_tooltip: '',
            f_construction_year:
                "En quelle ann\u00e9e l'habitation a-t-elle \u00e9t\u00e9 construite ?",
            f_construction_year_tooltip: '',
            f_living_area: "Quelle est la surface habitable de l'habitation ?",
            f_living_area_tooltip:
                "La surface habitable totale du bien. Cette information est g\u00e9n\u00e9ralement fournie dans l'annonce immo, ou dans le certificat PEB.",
            solar_panels: 'Y a-t-il des panneaux solaires ?',
            solar_panels_tooltip: '',
            insulated_windows: "Quel type de vitrage a l'habitation ?",
            insulated_windows_tooltip: '',
            insulated_single_renovated:
                "A-t-il subi une intervention pour en am\u00e9liorer l'isolation ?",
            insulated_single_renovated_tooltip: '',
            insulated_plural_renovated:
                "Ont-ils subi une intervention pour am\u00e9liorer l'isolation ?",
            insulated_plural_renovated_tooltip: '',
            gas_consumption_known: 'Connaissez-vous la consommation annuelle de gaz ?',
            gas_consumption_known_tooltip: '',
            oil_consumption_known: 'Connaissez-vous la consommation annuelle de mazout ?',
            oil_consumption_known_tooltip: '',
            wood_consumption_known: 'Connaissez-vous la consommation annuelle de bois ?',
            wood_consumption_known_tooltip: '',
            coal_consumption_known:
                'Connaissez-vous la consommation annuelle de charbon ?',
            coal_consumption_known_tooltip: '',
            pellets_consumption_known:
                'Connaissez-vous la consommation annuelle de pellets ?',
            pellets_consumption_known_tooltip: '',
            district_heating_consumption_known:
                'Connaissez-vous la consommation annuelle du r\u00e9seau de chaleur ?',
            district_heating_consumption_known_tooltip: '',
            electricity_consumption_known:
                "Connaissez-vous la consommation annuelle d'\u00e9lectricit\u00e9 ?",
            electricity_consumption_known_tooltip: '',
            gas_consumption_approx: 'Pouvez-vous estimer la consommation de gaz ?',
            gas_consumption_approx_tooltip: '',
            oil_consumption_approx: 'Pouvez-vous estimer la consommation de mazout ?',
            oil_consumption_approx_tooltip: '',
            wood_consumption_approx: 'Pouvez-vous estimer la consommation de bois ?',
            wood_consumption_approx_tooltip: '',
            coal_consumption_approx: 'Pouvez-vous estimer la consommation de charbon ?',
            coal_consumption_approx_tooltip: '',
            pellets_consumption_approx:
                'Pouvez-vous estimer la consommation de pellets ?',
            pellets_consumption_approx_tooltip: '',
            district_heating_consumption_approx:
                'Pouvez-vous estimer la consommation du r\u00e9seau de chaleur ?',
            district_heating_consumption_approx_tooltip: '',
            electricity_consumption_approx:
                "Pouvez-vous estimer la consommation d'\u00e9lectricit\u00e9 ?",
            electricity_consumption_approx_tooltip: '',
        },
        map_page: {
            '3d_model':
                "Sur base des informations fournies, nous avons pu \u00e9tablir ce mod\u00e8le 3D simplifi\u00e9 de l'habitation.",
            '3d_model_tooltip': '',
        },
        address_page: {
            title: "Quelle est l'adresse du bien ?",
            title_tooltip: '',
            control_address: "Compl\u00e9tez l'adresse si n\u00e9cessaire",
            control_address_tooltip: '',
        },
        validation: {
            field_required: 'Ce champ est obligatoire',
            field_required_tooltip: '',
            only_numbers: 'Veuillez introduire uniquement des chiffres',
            only_numbers_tooltip: '',
            min_4_characters: 'Ce champ requiert au minimum 4 caract\u00e8res',
            min_4_characters_tooltip: '',
            wrong_epc: "Merci d'indiquer un label PEB correct",
            wrong_epc_tooltip: '',
            valid_years: 'Entre {min} et {max}',
            valid_years_tooltip: '',
            value_too_large: 'Ce nombre est trop grand',
            value_too_large_tooltip: '',
        },
        f_epc: {
            label: 'Label PEB',
            label_tooltip: '',
        },
        features_page: {
            title: "Nous avons besoin d'informations au sujet de l\u2019habitation.",
            title_tooltip: '',
        },
        consumption_page: {
            title:
                "Veuillez nous fournir plus d'informations sur la consommation d'\u00e9nergie.",
            title_tooltip: '',
            electric_vehicle: 'Avez-vous une voiture \u00e9lectrique ?',
            electric_vehicle_tooltip: '',
        },
        login_page: {
            login: "Nom d'utilisateur",
            login_tooltip: '',
        },
        welcome_page: {
            intro:
                'D\u00e9couvrez quels investissements seraient les plus efficaces et une estimation de budget.',
            intro_tooltip: '',
            corpus_tooltip: '',
            disclaimer_tooltip: '',
            by_clicking: 'En cliquant sur "D\u00e9marrer", vous acceptez nos',
            by_clicking_tooltip: '',
            terms: "Conditions G\u00e9n\u00e9rales d'Utilisation",
            terms_tooltip: '',
            curious:
                'Curieux de savoir comment am\u00e9liorer le PEB de votre (future) habitation ?',
            curious_tooltip: '',
        },
        error_page: {
            title: 'Une erreur est survenue',
            title_tooltip: '',
            intro:
                'Le service est momentan\u00e9ment indisponible. Veuillez r\u00e9essayer plus tard.',
            intro_tooltip: '',
            button: 'Retour au site web ING',
            button_tooltip: '',
        },
        insulation: {
            title: "De quelle mani\u00e8re est isol\u00e9e l'habitation ?",
            title_tooltip: '',
        },
        consumption: {
            gas_consumption_title: 'Consommation de gaz',
            gas_consumption_title_tooltip: '',
            oil_consumption_title: 'Consommation de mazout',
            oil_consumption_title_tooltip: '',
            wood_consumption_title: 'Consommation de bois',
            wood_consumption_title_tooltip: '',
            coal_consumption_title: 'Consommation de charbon',
            coal_consumption_title_tooltip: '',
            pellets_consumption_title: 'Consommation de pellets',
            pellets_consumption_title_tooltip: '',
            district_heating_consumption_title: 'Consommation du r\u00e9seau de chaleur',
            district_heating_consumption_title_tooltip: '',
            electricity_consumption_title: "Consommation d'\u00e9lectricit\u00e9",
            electricity_consumption_title_tooltip: '',
        },
        ventilation_page: {
            ventilation_title: 'Ventilation',
            ventilation_title_tooltip: '',
        },
        consumers_page: {
            title: "Veuillez nous donner plus d'infos sur la consommation d'\u00e9nergie",
            title_tooltip: '',
        },
        energy_quizz: {
            solar: {
                qa: {
                    q:
                        "D'apr\u00e8s nos donn\u00e9es, voici comment il faudrait placer les panneaux solaires sur le toit. Est-ce que cela semble correct ?",
                    q_tooltip: '',
                    a1: "Oui, c'est correct",
                    a1_tooltip: '',
                    a2: "Non, ce n'est pas le c\u00f4t\u00e9 le plus ensoleill\u00e9",
                    a2_tooltip: '',
                    a3: 'Non, la surface ne convient pas',
                    a3_tooltip: '',
                },
                qb: {
                    q:
                        "Vous avez indiqu\u00e9 qu'il y a des panneaux solaires. Nous estimons que le toit de l'habitation peut accueillir {x} panneaux. Est-ce que cela semble correct ?",
                    q_tooltip: '',
                    a1: "Oui, c'est correct",
                    a1_tooltip: '',
                    a2: "Non, c'est impossible ",
                    a2_tooltip: '',
                    a3:
                        'Oui, mais nous avons d\u00e9cid\u00e9 de placer une autre quantit\u00e9 de panneaux solaires.',
                    a3_tooltip: '',
                },
            },
            q1: {
                q:
                    'Laquelle de ces affirmations \u00e0 propos des panneaux solaires est correcte ?',
                q_tooltip: '',
                a1:
                    'Les panneaux solaires sont rentables au bout de 15 ans en moyenne. Leur dur\u00e9e de vie est de 25 ans.',
                a1_tooltip: '',
                f1:
                    'R\u00e9ponse incorrecte. Les panneaux solaires sont rentables au bout de 6 \u00e0 8 ans. La r\u00e9ponse correcte est la num\u00e9ro 2.',
                f1_tooltip: '',
                a2:
                    "Les panneaux solaires permettent d'obtenir un meilleur PEB pour une habitation. Un meilleur PEB augmente la valeur de l'habitation.",
                a2_tooltip: '',
                f2: 'R\u00e9ponse correcte.',
                f2_tooltip: '',
                a3:
                    "Les panneaux solaires ne peuvent pas \u00eatre utilis\u00e9s pour chauffer de l'eau.",
                a3_tooltip: '',
                f3:
                    "R\u00e9ponse incorrecte. Il existe deux moyens de chauffer de l'eau avec des panneaux solaires : avec un chauffe-eau solaire, ou en alimentant le chauffe-eau. La bonne r\u00e9ponse est la 2.",
                f3_tooltip: '',
            },
            q2: {
                q:
                    "Dans quel ordre vaut-il mieux effectuer des travaux pour am\u00e9liorer l'efficacit\u00e9 \u00e9nerg\u00e9tique d'une maison ?",
                q_tooltip: '',
                a1: 'Murs - toit - ch\u00e2ssis - sols - syst\u00e8me de chauffage',
                a1_tooltip: '',
                f1: 'R\u00e9ponse incorrecte. La bonne r\u00e9ponse est la 2.',
                f1_tooltip: '',
                a2: 'Toit - murs - ch\u00e2ssis - sols - syst\u00e8me de chauffage',
                a2_tooltip: '',
                f2: 'R\u00e9ponse correcte.',
                f2_tooltip: '',
                a3: 'Toit - murs - sols - ch\u00e2ssis - syst\u00e8me de chauffage',
                a3_tooltip: '',
                f3: 'R\u00e9ponse incorrecte. La bonne r\u00e9ponse est la 2.',
                f3_tooltip: '',
                a4: 'Murs - toit - ch\u00e2ssis - sols - syst\u00e8me de chauffage',
                a4_tooltip: '',
                f4: 'R\u00e9ponse incorrecte. La bonne r\u00e9ponse est la 2.',
                f4_tooltip: '',
            },
            q3: {
                q:
                    "Que peut-on faire quand des panneaux solaires g\u00e9n\u00e8rent un surplus de production d'\u00e9nergie ?",
                q_tooltip: '',
                a1: 'Rien. Le surplus est perdu.',
                a1_tooltip: '',
                f1:
                    'R\u00e9ponse incorrecte. Ces surplus peuvent \u00eatre rentabilis\u00e9s. La bonne r\u00e9ponse est la 3.',
                f1_tooltip: '',
                a2: "Stocker l'\u00e9nergie pour plus tard.",
                a2_tooltip: '',
                f2:
                    "R\u00e9ponse incorrecte. On peut stocker une partie de l'\u00e9nergie solaire dans une batterie, mais pas l'enti\u00e8ret\u00e9, et pour une dur\u00e9e d\u00e9termin\u00e9e seulement. La bonne r\u00e9ponse est la 3.",
                f2_tooltip: '',
                a3:
                    "Vendre le surplus d'\u00e9nergie \u00e0 un fournisseur ou un distributeur d'\u00e9nergie. ",
                a3_tooltip: '',
                f3: 'R\u00e9ponse correcte.',
                f3_tooltip: '',
            },
            q4: {
                q:
                    'Parmi les astuces suivantes pour r\u00e9duire sa facture de gaz sans passer par des travaux, laquelle est d\u00e9conseill\u00e9e ?',
                q_tooltip: '',
                a1:
                    'Couvrir de film aluminium les murs non isol\u00e9s derri\u00e8re les radiateurs.',
                a1_tooltip: '',
                f1:
                    "R\u00e9ponse incorrecte. Poser un film aluminium entre des murs non isol\u00e9s et des radiateurs aide bel et bien \u00e0 r\u00e9duire la d\u00e9perdition de chaleur. Il s'agit donc d'un bon moyen de r\u00e9duire la consommation de gaz. La bonne r\u00e9ponse est la 3.",
                f1_tooltip: '',
                a2:
                    'Isoler les tuyaux de chauffage qui vont de la chaudi\u00e8re aux radiateurs situ\u00e9s au grenier ou \u00e0 la cave.',
                a2_tooltip: '',
                f2:
                    'R\u00e9ponse incorrecte. Isoler les tuyaux de chauffage dans les pi\u00e8ces non chauff\u00e9es contribue \u00e0 r\u00e9duire la d\u00e9perdition de chaleur, et donc la consommation de gaz. La bonne r\u00e9ponse est la 3.',
                f2_tooltip: '',
                a3: 'Chauffer avec une chaufferette \u00e9lectrique',
                a3_tooltip: '',
                f3:
                    "R\u00e9ponse correcte. Les chaufferettes \u00e9lectriques consomment beaucoup d'\u00e9lectricit\u00e9. Leur utilisation est couteuse et mauvaise pour l'environnement.",
                f3_tooltip: '',
            },
            q5: {
                q:
                    "Laquelle de ces mesures r\u00e9duit le plus les \u00e9missions de CO2 d'une habitation ?",
                q_tooltip: '',
                a1: 'Ne pas utiliser de s\u00e8che-linge.',
                a1_tooltip: '',
                f1: 'R\u00e9ponse incorrecte. La bonne r\u00e9ponse est la 2.',
                f1_tooltip: '',
                a2: 'Installer du chauffage par le sol.',
                a2_tooltip: '',
                f2: 'R\u00e9ponse correcte.',
                f2_tooltip: '',
                a3: 'Ne prendre une douche que tous les deux jours.',
                a3_tooltip: '',
                f3: 'R\u00e9ponse incorrecte. La bonne r\u00e9ponse est la 2.',
                f3_tooltip: '',
            },
            q6: {
                q: "Laquelle de ces affirmations n'est pas correcte ?",
                q_tooltip: '',
                a1:
                    "Isoler les sols est plus efficace en termes d'efficacit\u00e9 \u00e9nerg\u00e9tique qu'isoler les murs. ",
                a1_tooltip: '',
                f1: 'R\u00e9ponse correcte.',
                f1_tooltip: '',
                a2:
                    'Isoler son toit est plus efficace pour r\u00e9duire les \u00e9missions de CO2 que poser des panneaux solaires.',
                a2_tooltip: '',
                f2:
                    'R\u00e9ponse incorrecte. Isoler son toit est une meilleure mesure pour r\u00e9duire les \u00e9missions de CO2 que poser des panneaux solaires. La bonne r\u00e9ponse est la 1.',
                f2_tooltip: '',
                a3:
                    'Remplacer ses ch\u00e2ssis a un impact sup\u00e9rieur que remplacer son syst\u00e8me de chauffage.',
                a3_tooltip: '',
                f3:
                    "R\u00e9ponse incorrecte. Remplacer ses ch\u00e2ssis permet d'\u00e9conomiser plus d'\u00e9nergie qu'installer un nouveau syst\u00e8me de chauffage. La bonne r\u00e9ponse est la 1.",
                f3_tooltip: '',
            },
            discovery_phase: 'Phase de d\u00e9couverte',
            discovery_phase_tooltip: '',
            discovery_description:
                "C'est d\u00e9j\u00e0 pas mal ! Il faut du temps pour ma\u00eetriser le sujet de l'\u00e9nergie. Restez curieux(se) et vous apprendrez d'autres fa\u00e7ons d'\u00e9conomiser de l'\u00e9nergie.  Etudier les r\u00e9ponses correctes ci-dessus est un bon d\u00e9but.",
            discovery_description_tooltip: '',
            learning_phase: "Phase d'apprentissage",
            learning_phase_tooltip: '',
            learning_description:
                "Ne vous arr\u00eatez pas en si bon chemin ! Vous savez d\u00e9j\u00e0 tr\u00e8s bien comment obtenir une habitation plus \u00e9conome en \u00e9nergie. Continuez \u00e0 d\u00e9couvrir d'autres fa\u00e7ons de r\u00e9aliser des \u00e9conomies d'\u00e9nergie !",
            learning_description_tooltip: '',
            expert_phase: "Phase d'expertise",
            expert_phase_tooltip: '',
            expert_description:
                "F\u00e9licitations ! Vous \u00eates expert(e) en \u00e9conomies d'\u00e9nergie pour votre habitation. Il est temps de mettre tout cela  en pratique !",
            expert_description_tooltip: '',
        },
        ers_report: {
            language: 'FR',
            language_tooltip: '',
            header_title: 'Merci pour votre int\u00e9r\u00eat.',
            header_title_tooltip: '',
            header_subtitle:
                'Sur la base des informations fournies, nous avons estim\u00e9 le PEB actuel.*',
            header_subtitle_tooltip: '',
            header_subtitle_pdf:
                'Sur la base des informations fournies, nous avons estim\u00e9 le PEB actuel*. Ceci ne remplace pas un certificat PEB. Consultez la FAQ pour plus de d\u00e9tails.',
            header_subtitle_pdf_tooltip: '',
            epc_graph_title: 'Score PEB actuel*',
            epc_graph_title_tooltip: '',
            consumption_title: "Consommation de l'habitation",
            consumption_title_tooltip: '',
            gas_title: 'Gaz',
            gas_title_tooltip: '',
            electric_title: 'Electricit\u00e9',
            electric_title_tooltip: '',
            low_title: 'Bas',
            low_title_tooltip: '',
            high_title: 'Haut',
            high_title_tooltip: '',
            average_title: 'Moyen',
            average_title_tooltip: '',
            you_title: 'Vous',
            you_title_tooltip: '',
            current_epc: 'PEB actuel',
            current_epc_tooltip: '',
            future_epc: 'Futur PEB',
            future_epc_tooltip: '',
            summary_title: 'R\u00e9sum\u00e9',
            summary_title_tooltip: '',
            summary_note:
                'Les prix et primes indiqu\u00e9s ici sont fournis \u00e0 titre indicatif. Les prix sont TVAC inclus et ils peuvent changer avec la s\u00e9lection des r\u00e9novations.** ',
            summary_note_tooltip: '',
            price_title: 'Prix TVAC',
            price_title_tooltip: '',
            subsidy_title: 'Subs.',
            subsidy_title_tooltip: '',
            after_subsidy_title: 'Apr\u00e8s subs.',
            after_subsidy_title_tooltip: '',
            total_title: 'Total',
            total_title_tooltip: '',
            calculate_button_text: 'Calculer mon pr\u00eat ING',
            calculate_button_text_tooltip: '',
            download_report: 'T\u00e9l\u00e9charger mon rapport',
            download_report_tooltip: '',
            renovation_title: 'Votre objectif de r\u00e9novation',
            renovation_title_tooltip: '',
            renovation_subtitle:
                'Quel est votre objectif de r\u00e9novation ? S\u00e9lectionnez un ensemble de mesures pour voir lequel convient \u00e0 votre objectif. Ou s\u00e9lectionnez des mesures de r\u00e9novation pour d\u00e9couvrir \u00e0 quel score PEB elles correspondent.',
            renovation_subtitle_tooltip: '',
            renovation_price: 'Prix ',
            renovation_price_tooltip: '',
            renovation_subsidy: 'Subside',
            renovation_subsidy_tooltip: '',
            renovation_yearly: 'Economies annuelles',
            renovation_yearly_tooltip: '',
            renovation_windowsDouble: 'Ch\u00e2ssis',
            renovation_windowsDouble_tooltip: '',
            renovation_wallExt: 'Mur ext\u00e9rieur',
            renovation_wallExt_tooltip: '',
            renovation_wallCav: 'Isolation par la coulisse',
            renovation_wallCav_tooltip: '',
            renovation_roofIn: 'Toiture int\u00e9rieure',
            renovation_roofIn_tooltip: '',
            renovation_roofExt: 'Toiture ext\u00e9rieure',
            renovation_roofExt_tooltip: '',
            renovation_roofIn_attic_floor: 'Sol du grenier',
            renovation_roofIn_attic_floor_tooltip: '',
            renovation_condGas: 'Chaudi\u00e8re',
            renovation_condGas_tooltip: '',
            renovation_heatPump: 'Pompe \u00e0 chaleur',
            renovation_heatPump_tooltip: '',
            renovation_ventC: 'Ventilation type C',
            renovation_ventC_tooltip: '',
            renovation_solar: 'Panneaux solaires',
            renovation_solar_tooltip: '',
            compliance_pack_title: 'Bon d\u00e9part',
            compliance_pack_title_tooltip: '',
            compliance_pack_desc:
                'Je veux que mon habitation soit conforme aux normes l\u00e9gales en mati\u00e8re \u00e9nerg\u00e9tique.',
            compliance_pack_desc_tooltip: '',
            budget_pack_title: 'Budget vert',
            budget_pack_title_tooltip: '',
            budget_pack_desc:
                'Je veux optimiser un budget de x euros d\u00e9di\u00e9 aux r\u00e9novations \u00e9nerg\u00e9tiques.',
            budget_pack_desc_tooltip: '',
            lowest_pack_title: 'BENovation',
            lowest_pack_title_tooltip: '',
            lowest_pack_desc:
                'Je veux voir ce que donnerait une r\u00e9novation \u00e9nerg\u00e9tique totale de mon habitation.',
            lowest_pack_desc_tooltip: '',
            custom_pack_title: 'Personnalis\u00e9',
            custom_pack_title_tooltip: '',
            custom_pack_desc:
                'Je veux choisir moi-m\u00eame des mesures et travaux \u00e0 entreprendre en priorit\u00e9.',
            custom_pack_desc_tooltip: '',
            add_remove_renovations: 'Ajouter ou retirer des travaux de r\u00e9novation.',
            add_remove_renovations_tooltip: '',
            submit_button_budget: 'Soumettre',
            submit_button_budget_tooltip: '',
            already_compilance:
                'Fantastique ! Avec ces mesures, votre habitation sera conforme aux normes l\u00e9gales en mati\u00e8re \u00e9nerg\u00e9tique. Cliquez sur les autres ensembles de mesures si vous voulez d\u00e9couvrir quels autres travaux vous pourriez entreprendre.',
            already_compilance_tooltip: '',
            no_renovation_found:
                "Aucune r\u00e9novation trouv\u00e9e. Merci d'indiquer votre budget vert.",
            no_renovation_found_tooltip: '',
            renovation_subtitle_compliance_pdf:
                "F\u00e9licitations pour votre Bon D\u00e9part ! Un budget de {montant} euros peut faire passer le score PEB de votre habitation de {score_epc_actuel} \u00e0 {score_epc_futur}. \n\nVous trouverez ci-dessous l'ensemble de mesures le plus rentable pour vous permettre d'atteindre cet objectif, ainsi que les \u00e9conomies annuelles que vous pourriez faire et une indication du montant de subventions que vous pourriez toucher. \n\nPour plus d'informations sur les subventions, veuillez consulter les sites web suivants :\n- Pour la Wallonie : https://energie.wallonie.be/fr/primes.html?IDC=7015\n- Pour Bruxelles : https://renolution.brussels/fr\n- Pour la Flandre : https://www.mijnbenovatie.be/nl/wat-waarom/benovatiepremies-2022/\n",
            renovation_subtitle_compliance_pdf_tooltip: '',
            renovation_subtitle_budget_pdf:
                "F\u00e9licitations pour votre budget vert ! Votre objectif de r\u00e9novation est d'optimiser un budget de {montant} euros. Cet investissement peut faire passer le score PEB de votre habitation de {score_epc_actuel} \u00e0 {score_epc_futur}. \n\nVous trouverez ci-dessous l'ensemble de mesures le plus rentable pour vous permettre d'atteindre cet objectif, ainsi que les \u00e9conomies annuelles que vous pourriez faire et une indication du montant de subventions que vous pourriez toucher. \n\nPour plus d'informations sur les subventions, veuillez consulter les sites web suivants :\n- Pour la Wallonie : https://energie.wallonie.be/fr/primes.html?IDC=7015\n- Pour Bruxelles : https://renolution.brussels/fr\n- Pour la Flandre : https://www.mijnbenovatie.be/nl/wat-waarom/benovatiepremies-2022/\n",
            renovation_subtitle_budget_pdf_tooltip: '',
            renovation_subtitle_custom_pdf:
                "F\u00e9licitations pour votre budget personnalis\u00e9 ! Un budget de {montant} euros peut faire passer le score PEB de votre habitation de {score_epc_actuel} \u00e0 {score_epc_futur}. \n\nVous trouverez ci-dessous l'ensemble de mesures le plus rentable pour vous permettre d'atteindre cet objectif, ainsi que les \u00e9conomies annuelles que vous pourriez faire et une indication du montant de subventions que vous pourriez toucher. \n\nPour plus d'informations sur les subventions, veuillez consulter les sites web suivants :\n- Pour la Wallonie : https://energie.wallonie.be/fr/primes.html?IDC=7015\n- Pour Bruxelles : https://renolution.brussels/fr\n- Pour la Flandre : https://www.mijnbenovatie.be/nl/wat-waarom/benovatiepremies-2022/\n",
            renovation_subtitle_custom_pdf_tooltip: '',
            disclaimer_title: 'Mentions l\u00e9gales',
            disclaimer_title_tooltip: '',
            disclaimer_body:
                "*L'estimation du score PEB actuel est bas\u00e9e sur les informations fournies dans le questionnaire, combin\u00e9es aux donn\u00e9es du b\u00e2timent et aux normes techniques telles qu'indiqu\u00e9es par le logiciel PEB officiel. Plus ces informations sont pr\u00e9cises, mieux nous pouvons estimer le score PEB r\u00e9el. Ceci ne remplace pas un certificat PEB officiel. Veuillez consulter un professionnel pour obtenir des conseils personnalis\u00e9s. \n**Les prix incluent la TVA de 6\u00a0% ou 21\u00a0%, en fonction des donn\u00e9es fournies. Ils ne comprennent pas les travaux structurels suppl\u00e9mentaires qui pourraient \u00eatre requis avant les travaux de r\u00e9novation \u00e9nerg\u00e9tique. Nous actualisons r\u00e9guli\u00e8rement les prix et les subventions. Ils peuvent donc varier avec le temps. De m\u00eame, les subventions peuvent \u00eatre diff\u00e9rentes en fonction de votre situation. Consultez toujours un professionnel pour obtenir des conseils personnalis\u00e9s. \n\n",
            disclaimer_body_tooltip: '',
            disclaimer_body_pdf:
                "*L'estimation du score PEB actuel est bas\u00e9e sur les informations fournies dans le questionnaire, combin\u00e9es aux donn\u00e9es du b\u00e2timent et aux normes techniques telles qu'indiqu\u00e9es par le logiciel PEB officiel. Plus ces informations sont pr\u00e9cises, mieux nous pouvons estimer le score PEB r\u00e9el. Ceci ne remplace pas un certificat PEB officiel. Veuillez consulter un professionnel pour obtenir des conseils personnalis\u00e9s. \n**Les prix incluent la TVA de 6\u00a0% ou 21\u00a0%, en fonction des donn\u00e9es fournies. Ils ne comprennent pas les travaux structurels suppl\u00e9mentaires qui pourraient \u00eatre requis avant les travaux de r\u00e9novation \u00e9nerg\u00e9tique. Nous actualisons r\u00e9guli\u00e8rement les prix et les subventions. Ils peuvent donc varier avec le temps. De m\u00eame, les subventions peuvent \u00eatre diff\u00e9rentes en fonction de votre situation. Consultez toujours un professionnel pour obtenir des conseils personnalis\u00e9s. \n\n",
            disclaimer_body_pdf_tooltip: '',
        },
        processing_page: {
            title_started: 'Votre simulation est en cours de traitement',
            title_started_tooltip: '',
            title_finished: 'Votre simulation est pr\u00eate !',
            title_finished_tooltip: '',
            title_failed: "Une erreur s'est produite lors de votre simulation",
            title_failed_tooltip: '',
            subtitle:
                "Pendant que nous traitons vos r\u00e9sultats, testez vos connaissances en mati\u00e8re d'\u00e9nergie avec notre quizz ci-dessous :",
            subtitle_tooltip: '',
            ing_button: 'Retourner vers ING',
            ing_button_tooltip: '',
            check_results: 'Consultez les r\u00e9sultats',
            check_results_tooltip: '',
            results_ready: 'Vos r\u00e9sultats sont pr\u00eats !',
            results_ready_tooltip: '',
            something_went_wrong: "Une erreur s'est produite lors de votre simulation",
            something_went_wrong_tooltip: '',
        },
    },
    'nl-BE': {
        unit: {
            m: 'm',
            m_tooltip: '',
            m2: 'm\u00b2',
            m2_tooltip: '',
            m3: 'm\u00b3',
            m3_tooltip: '',
            'kwh/m2year': 'kWh/m\u00b2jr',
            'kwh/m2year_tooltip': '',
            euro: '\u20ac',
            euro_tooltip: '',
            'euro/month': '\u20ac/maand',
            'euro/month_tooltip': '',
            'euro/m2': '\u20ac/m\u00b2',
            'euro/m2_tooltip': '',
            'kgco2/m2year': 'kgCO\u2082/m\u00b2jr',
            'kgco2/m2year_tooltip': '',
            kWh: 'kWh',
            kWh_tooltip: '',
            l: 'l',
            l_tooltip: '',
            kg: 'kg',
            kg_tooltip: '',
            'kWh/yr': 'kWh/jaar',
            'kWh/yr_tooltip': '',
            'l/yr': 'l/jaar',
            'l/yr_tooltip': '',
            'm3/yr': 'm\u00b3/jaar',
            'm3/yr_tooltip': '',
            'kg/yr': 'kg/jaar',
            'kg/yr_tooltip': '',
        },
        address: {
            full_address: 'Adres',
            full_address_tooltip:
                'Selecteer het adres van het pand dat wordt gefinancierd. Gelieve dit niet te verwarren met het adres van eventuele andere panden die gebruikt worden als extra zekerheid.',
            streetname: 'Straat',
            streetname_tooltip: '',
            streetnumber: 'Nummer',
            streetnumber_tooltip: '',
            boxnumber: 'Bus',
            boxnumber_tooltip: '',
            postalcode: 'Postcode',
            postalcode_tooltip: '',
            municipality: 'Stad',
            municipality_tooltip: '',
        },
        valuation: {
            date: 'Waarderingsdatum',
            date_tooltip: '',
            type: 'Soort waardering',
            type_tooltip: '',
            valuer: 'Uitgevoerd door',
            valuer_tooltip: '',
            monitor: 'Gecontroleerd door',
            monitor_tooltip: '',
            market_value: 'Marktwaarde',
            market_value_tooltip: '',
            market_value_post_renovation: 'Verwachte marktwaarde na renovatie',
            market_value_post_renovation_tooltip: '',
            forced_sale_value: 'Gedwongen verkoopwaarde',
            forced_sale_value_tooltip: '',
            rental_value: 'Maandelijkse huurprijs',
            rental_value_tooltip: '',
            reconstruction_value: 'Heropbouwwaarde',
            reconstruction_value_tooltip: '',
            index_value: 'Index',
            index_value_tooltip: '',
            index_type: 'Soort index',
            index_type_tooltip: '',
            explanation_1:
                'De waardering van dit gebouw berust gedeeltelijk op een data-gedreven statistisch model. Het resultaat werd door een vastgoedschatter gecontroleerd en bijgestuurd waar nodig. Het betreft een proces in twee stappen:',
            explanation_1_tooltip: '',
            explanation_2: 'Stap 1: Schatting door waarderingsmodel',
            explanation_2_tooltip: '',
            explanation_3:
                'Een eerste schatting van de marktwaarde van het pand wordt door het model berekend op basis van alle input van de klant alsook enkele bijkomende inputparameters (cf. blz. 2). Deze schatting geeft de gemiddelde marktwaarde voor een pand met deze locatie en karakteristieken.(*)',
            explanation_3_tooltip: '',
            explanation_4: 'Stap 2: Evaluatie en verfijning door vastgoedschatter',
            explanation_4_tooltip: '',
            explanation_5:
                'De vastgoedschatter verifieert het model in stap 1 en houdt vervolgens rekening met de andere inputparameters. Dit resulteert in de marktwaarde van het pand (rode lijn in grafiek). Afhankelijk van de correcties die door de vastgoedschatter worden toegepast, kan de marktwaarde lager of hoger uitvallen dan de oorspronkelijke waardering van het model.\nDe onderstaande grafiek illustreert hoe de marktwaarde van dit pand zich vergelijkt met andere panden in de omgeving. Het kan helpen om deze waardering te contextualiseren.',
            explanation_5_tooltip: '',
            explanation_6:
                'De marktwaarde in dit rapport is gebaseerd op een waardering uitgevoerd door een vastgoedschatter op afstand. De waardering tracht een zo goed mogelijk zicht te geven op de huidige waarde van het pand. Het blijft echter een schatting waarbij de volgende elementen in aanmerking moeten worden genomen:\n- Waardering op afstand: de schatter heeft het pand van op afstand ge\u00ebvalueerd en heeft dit niet effectief bezocht.\n- Nauwkeurigheid van het model: de waardering berust gedeeltelijk op een statistisch model met een bepaalde foutenmarge.\n- Subjectiviteit: zelfs de best mogelijke waardering uitgevoerd door een schatter bevat een zeker niveau van subjectiviteit.',
            explanation_6_tooltip: '',
        },
        value: {
            avm: 'Automatische waardering',
            avm_tooltip: '',
            dvm: 'Waardering op afstand',
            dvm_tooltip: '',
            ovm: 'Waardering ter plaatse',
            ovm_tooltip: '',
            new_loan: 'Nieuwe kredietaanvraag',
            new_loan_tooltip: '',
            revaluation: 'Herwaardering',
            revaluation_tooltip: '',
            classic_tooltip: '',
            renovation_light_tooltip: '',
            renovation_full_tooltip: '',
            house: 'Huis',
            house_tooltip: 'Gebouw met \u00e9\u00e9n enkele woonentiteit',
            apartment: 'Appartement',
            apartment_tooltip: 'Een woonentiteit die deel uitmaakt van een groter gebouw',
            annex: 'Bijgebouw',
            annex_tooltip: '',
            new_construction: 'Nieuwbouw',
            new_construction_tooltip: '',
            construction_plot: 'Bouwgrond',
            construction_plot_tooltip: '',
            attached: 'Gesloten bebouwing',
            attached_tooltip: '',
            semi: 'Half-open bebouwing',
            semi_tooltip: '',
            detached: 'Open bebouwing',
            detached_tooltip: '',
            yes: 'Ja',
            yes_tooltip: '',
            no: 'Nee',
            no_tooltip: '',
            traditional: 'Traditioneel',
            traditional_tooltip: '',
            modern: 'Modern',
            modern_tooltip: '',
            contemporary: 'Hedendaags',
            contemporary_tooltip: '',
            mediterranean: 'Mediterraans',
            mediterranean_tooltip: '',
            other: 'Andere',
            other_tooltip: '',
            ground_floor: 'Gelijkvloers',
            ground_floor_tooltip: '',
            1: '1',
            '1_tooltip': '',
            2: '2',
            '2_tooltip': '',
            3: '3',
            '3_tooltip': '',
            4: '4',
            '4_tooltip': '',
            '>4': '>4',
            '>4_tooltip': '',
            private: 'Priv\u00e9 tuin',
            private_tooltip: '',
            common: 'Gemeenschappelijke tuin',
            common_tooltip: '',
            'private+common': 'Priv\u00e9 en gemeenschappelijke tuin',
            'private+common_tooltip': '',
            maintenance_required: 'Onderhoud nodig',
            maintenance_required_tooltip: '',
            good: 'Goed',
            good_tooltip: '',
            very_good: 'Zeer goed',
            very_good_tooltip: '',
            cinder_blocks: 'Sintelblokken',
            cinder_blocks_tooltip: '',
            wood: 'Hout',
            wood_tooltip: '',
            plaster: 'Gips',
            plaster_tooltip: '',
            natural_stone: 'Natuursteen',
            natural_stone_tooltip: '',
            metal: 'Metaal',
            metal_tooltip: '',
            concrete: 'Beton',
            concrete_tooltip: '',
            brick: 'Baksteen',
            brick_tooltip: '',
            tiles: 'Pannen',
            tiles_tooltip: '',
            bitumen_roofing: 'Bitumen dakbedekking',
            bitumen_roofing_tooltip: '',
            slate_shingles: 'Leistenen dakpan',
            slate_shingles_tooltip: '',
            black_concrete_tiles: 'Zwarte betontegels',
            black_concrete_tiles_tooltip: '',
            thatched: 'Rieten dak',
            thatched_tooltip: '',
            asbestos: 'Asbest',
            asbestos_tooltip: '',
            green_roof: 'Groen dak',
            green_roof_tooltip: '',
            home_liberal_profession_or_office: 'Huis, vrij beroep of kantoor',
            home_liberal_profession_or_office_tooltip: '',
            home_other_professional_activity: 'Andere beroepsactiviteit',
            home_other_professional_activity_tooltip: '',
            home: 'Huis',
            home_tooltip: '',
            no_economic_activity: 'Geen economische activiteit',
            no_economic_activity_tooltip: '',
            activity_unlikely: 'Activiteit onwaarschijnlijk',
            activity_unlikely_tooltip: '',
            activity_possible: 'Activiteit mogelijk',
            activity_possible_tooltip: '',
            activity_likely: 'Activiteit waarschijnlijk',
            activity_likely_tooltip: '',
            activity_very_likely: 'Activiteit zeer waarschijnlijk',
            activity_very_likely_tooltip: '',
            n: 'N',
            n_tooltip: '',
            ne: 'NO',
            ne_tooltip: '',
            e: 'O',
            e_tooltip: '',
            se: 'ZO',
            se_tooltip: '',
            s: 'Z',
            s_tooltip: '',
            sw: 'ZW',
            sw_tooltip: '',
            w: 'W',
            w_tooltip: '',
            nw: 'NW',
            nw_tooltip: '',
            limited: 'Beperkt',
            limited_tooltip: '',
            details_previous_page: '(details op vorige pagina)',
            details_previous_page_tooltip: '',
            low: 'Laag',
            low_tooltip: '',
            medium: 'Gemiddeld',
            medium_tooltip: '',
            high: 'Hoog',
            high_tooltip: '',
            unknown: 'Onbekend',
            unknown_tooltip: '',
            urban: 'Stedelijk ',
            urban_tooltip: '',
            rural: 'Landelijk',
            rural_tooltip: '',
            isolated: 'Ge\u00efsoleerd',
            isolated_tooltip: '',
            city: 'Stad',
            city_tooltip: '',
            suburbs: 'Stedelijk ',
            suburbs_tooltip: '',
            village: 'Dorp',
            village_tooltip: '',
            none: 'Geen',
            none_tooltip: '',
            classified_facade: 'Beschermde gevel',
            classified_facade_tooltip: '',
            green_neighbourhood: 'Groene buurt',
            green_neighbourhood_tooltip: '',
            sea_view: 'Zeezicht',
            sea_view_tooltip: '',
            no_direct_traffic: 'Geen direct verkeer',
            no_direct_traffic_tooltip: '',
            exceptional_view: 'Uitzonderlijk zicht',
            exceptional_view_tooltip: '',
            tram: 'Tram',
            tram_tooltip: '',
            train: 'Trein',
            train_tooltip: '',
            metro: 'Metro',
            metro_tooltip: '',
            traffic: 'Verkeer',
            traffic_tooltip: '',
            busy_area: 'Drukke omgeving',
            busy_area_tooltip: '',
            abex: 'ABEX',
            abex_tooltip: '',
            single: 'Enkel',
            single_tooltip: '',
            ordinary_double: 'Standaard dubbel',
            ordinary_double_tooltip: '',
            hr_double_after_2000: 'Hoog rendement dubbel (na 2000)',
            hr_double_after_2000_tooltip: '',
            hr_double_before_2000: 'Hoog rendement dubbel (voor 2000)',
            hr_double_before_2000_tooltip: '',
            triple: 'Driedubbel',
            triple_tooltip: '',
            gas: 'Gas',
            gas_tooltip: '',
            oil: 'Stookolie',
            oil_tooltip: '',
            pellets: 'Pellets',
            pellets_tooltip: '',
            coal: 'Kool',
            coal_tooltip: '',
            district_heating: 'Warmtedistributie/stadsverwarming',
            district_heating_tooltip: '',
            'boiler:condensing': 'Condensatieketel',
            'boiler:condensing_tooltip': '',
            heat_pump: 'Warmtepomp',
            heat_pump_tooltip: '',
            'boiler:standard': 'Standaard ketel',
            'boiler:standard_tooltip': '',
            chp: 'Warmetkrachtkoppeling',
            chp_tooltip: '',
            stove: 'Kachel',
            stove_tooltip: '',
            electricity: 'Elektriciteit',
            electricity_tooltip: '',
            electric_heater: 'Elektrische verwarming',
            electric_heater_tooltip: '',
            no_ventilation: 'Geen ventilatie',
            no_ventilation_tooltip: '',
            natural: 'Natuurlijk',
            natural_tooltip:
                'Natuurlijke toe- en afvoer van lucht via ramen en roosters.',
            mechanical: 'Mechanisch',
            mechanical_tooltip: '',
            system_b: 'Systeem B',
            system_b_tooltip:
                'Mechanische toevoer van verse lucht, natuurlijke afvoer van vervuilde lucht.',
            system_c: 'Systeem C',
            system_c_tooltip:
                'Natuurlijke toevoer van verse lucht, mechanische afvoer van vervuilde lucht.',
            system_cplus: 'Systeem C+',
            system_cplus_tooltip:
                'Vraaggestuurde mechanische toe- en afvoer van lucht op basis van luchtkwaliteit.',
            system_d: 'Systeel D',
            system_d_tooltip:
                'Volledig geautomatiseerde continue mechanische toe- en afvoer van lucht, ook wel "balansventilatie" genoemd.',
        },
        request: {
            references: 'Referenties',
            references_tooltip: '',
            valuation_request_ref: 'Waarderingsreferentie',
            valuation_request_ref_tooltip: '',
            customer_ref: 'Interne referentie',
            customer_ref_tooltip:
                'Hiermee wordt de link gelegd tussen de aanvraag en de lening.',
            bank: 'Bank',
            bank_tooltip: '',
            customer: 'Klant',
            customer_tooltip: '',
            purpose: 'Doel van waardering',
            purpose_tooltip: '',
            package_tooltip: '',
        },
        footer: {
            text: 'Voor meer informatie, bezoek {url.rockestate_main}',
            text_tooltip: '',
        },
        url: {
            rockestate_main: 'https://www.rock.estate',
            rockestate_main_tooltip: '',
            dashboard: '/valuation/default/dashboard',
            dashboard_tooltip: '',
            rockestate_info: 'https://www.rock.estate/',
            rockestate_info_tooltip: '',
            valuation_app: '/valuation/default/',
            valuation_app_tooltip: '',
        },
        export: {
            by: 'Uitgevoerd door',
            by_tooltip: '',
            date: 'Datum',
            date_tooltip: '',
            reference: 'Referentie',
            reference_tooltip: '',
        },
        section: {
            reconstruction_value: 'Heropbouw',
            reconstruction_value_tooltip: '',
            address_location: 'Adres en locatie',
            address_location_tooltip: '',
            secondary_buildings: 'Bijgebouwen',
            secondary_buildings_tooltip: '',
            economic_activity: 'Economische activiteit',
            economic_activity_tooltip: '',
            roof: 'Dak',
            roof_tooltip: '',
            transaction: 'Transacties',
            transaction_tooltip: '',
            general: 'Algemeen',
            general_tooltip: '',
            report: 'Verslag',
            report_tooltip: '',
            'valuation-app': 'Toepassing',
            'valuation-app_tooltip': '',
            dashboard: 'Dashboard',
            dashboard_tooltip: '',
            'faq-search-results': 'Zoekresultaten',
            'faq-search-results_tooltip': '',
            warnings: 'Waarschuwingen',
            warnings_tooltip: '',
            renovation: 'Geplande renovatie',
            renovation_tooltip: '',
            general_info: 'Algemene informatie',
            general_info_tooltip: '',
            parcels_and_buildings: 'Percelen en gebouwen',
            parcels_and_buildings_tooltip: '',
            c_parcels: 'Grond',
            c_parcels_tooltip: '',
            c_building: 'Hoofdgebouw',
            c_building_tooltip: '',
            building: 'Gebouw',
            building_tooltip: '',
            building_structure: 'Structuur van het gebouw',
            building_structure_tooltip: '',
            building_purpose_classification: 'Bestemming en klassering van het gebouw',
            building_purpose_classification_tooltip: '',
            parcels: 'Perceel',
            parcels_tooltip: '',
            surroundings: 'Directe omgeving',
            surroundings_tooltip: '',
            valuations: 'Waarderingen',
            valuations_tooltip: '',
            reference_properties_location: 'Referentiepanden: locatie',
            reference_properties_location_tooltip: '',
            reference_properties_characteristics: 'Referentiepanden: kenmerken',
            reference_properties_characteristics_tooltip: '',
            flood: 'Overstromingsgebieden',
            flood_tooltip: '',
            energy: 'Energie',
            energy_tooltip: '',
            flood_simple: 'Overstromingsgebieden',
            flood_simple_tooltip: '',
            faq: 'Veelgestelde vragen',
            faq_tooltip: '',
            questions_feedback: 'Vragen en feedback',
            questions_feedback_tooltip: '',
            contact_info: 'Contactgegevens',
            contact_info_tooltip: '',
            disclaimer: 'Disclaimer',
            disclaimer_tooltip: '',
            heating: 'Verwarming',
            heating_tooltip: '',
            ventilation: 'Ventilatie',
            ventilation_tooltip: '',
            renewables: 'Hernieuwbare energie',
            renewables_tooltip: '',
            additional_consumption: 'n/a',
            additional_consumption_tooltip: '',
            consumers: 'Verbruikers',
            consumers_tooltip: '',
            house_info: 'Woning gegevens',
            house_info_tooltip: '',
            consumption: 'Verbruik',
            consumption_tooltip: '',
            property_type: 'Type woning',
            property_type_tooltip: '',
            address: 'Adres bevestiging',
            address_tooltip: '',
            insulation: 'Isolatie',
            insulation_tooltip: '',
            house_information: 'Woning gegevens',
            house_information_tooltip: '',
        },
        front: {
            title: 'Waarderingsverslag',
            title_tooltip: '',
            core_title: 'Eigendomsrapport ',
            core_title_tooltip: '',
        },
        features: {
            renovation_cost: 'Renovatiekosten (excl BTW)',
            renovation_cost_tooltip: '',
            f_building_type: 'Type woning',
            f_building_type_tooltip:
                'Financiert de klant een \u00e9\u00e9ngezinswoning of een appartement?',
            f_number_of_facades: 'Aantal gevels',
            f_number_of_facades_tooltip: '',
            f_parcel_area: 'Totale perceeloppervlakte',
            f_parcel_area_tooltip: '',
            f_building_area: 'Oppervlakte aan de grond van het hoofdgebouw',
            f_building_area_tooltip: '',
            f_others: 'Aandere',
            f_others_tooltip: '',
            f_x_annexes: 'Bijgebouwen',
            f_x_annexes_tooltip: '',
            f_annexes_area: 'Totale oppervlakte van bijgebouwen',
            f_annexes_area_tooltip: '',
            f_area_largest_annex: 'Grootste bijgebouw',
            f_area_largest_annex_tooltip: '',
            parcel_ids: 'Kadastrale referenties',
            parcel_ids_tooltip: '',
            f_lng: 'Lengtegraad',
            f_lng_tooltip: '',
            f_lat: 'Breedtegraad',
            f_lat_tooltip: '',
            f_number_of_addresses: 'Aantal adressen',
            f_number_of_addresses_tooltip: '',
            f_living_area: 'Bewoonbare oppervlakte',
            f_living_area_tooltip:
                'De totale bewoonbare oppervlakte van de woning. Deze info is doorgaans te vinden in de advertentie van de woning.',
            f_expected_living_area: '(Verwacht) bewoonbare oppervlakte',
            f_expected_living_area_tooltip: '',
            f_approx_living_area_tooltip: '',
            n_rooms_tooltip: '',
            approx_n_rooms_tooltip: '',
            transaction_value_tooltip: '',
            transaction_date_tooltip: '',
            approx_price_sqm_tooltip: '',
            co2_sqm_tooltip: '',
            co2_label_tooltip: '',
            f_approx_epc_tooltip: '',
            f_construction_year: 'Bouwjaar',
            f_construction_year_tooltip:
                'Het jaar waarin de woning officieel gebouwd werd. Dit houdt geen rekening met eventuele renovaties op een later tijdstip. Deze info is doorgaans te vinden in de verkoopadvertentie.',
            f_expected_construction_year: '(Verwacht) bouwjaar',
            f_expected_construction_year_tooltip: '',
            f_epc: 'EPC',
            f_epc_tooltip:
                'Het Energie Prestatie Co\u00ebfficient (EPC) is de energiescore van een woning. Deze info is doorgaans te vinden in de verkoopadvertentie.',
            f_epc_label: 'EPC',
            f_epc_label_tooltip: '',
            f_epc_numeric: 'EPC',
            f_epc_numeric_tooltip: '',
            f_expected_epc: '(Verwacht) EPC',
            f_expected_epc_tooltip: '',
            f_bedrooms: 'Aantal slaapkamers',
            f_bedrooms_tooltip:
                'Totaal aantal kamers die kunnen dienen als slaapkamer. Keuken, badkamer en woonkamer worden hier niet bij meegerekend.',
            f_floor: 'Verdieping',
            f_floor_tooltip:
                'De verdieping waarop het appartement zich bevindt. Indien meerdere, kies de laagste.',
            f_bottom_floor: 'Onderste verdieping van het gebouw',
            f_bottom_floor_tooltip:
                'De onderste verdieping van een gebouw is ofwel 0 ofwel een negatief getal, afhankelijk van het aantal ondergrondse verdiepingen. \nWijs een waarde van 0 toe als het gebouw geen ondergrondse verdiepingen heeft. \nWijs een negatieve waarde toe als het gebouw een kelder/ondergrondse garage/... heeft.',
            f_top_floor: 'Bovenste verdieping van het gebouw',
            f_top_floor_tooltip:
                'De bovenste verdieping van een gebouw is de verdieping onder het dak van het gebouw. Er wordt geen rekening gehouden met een niet afgewerkte zolderruimte.',
            f_floors: 'Verdieping(en) van het appartement',
            f_floors_tooltip:
                'Selecteer de verdieping(en) waarop het appartement zich bevindt in de gegenereerde lijst.\nBijvoorbeeld: voor een duplex gelegen op verdiepingen 2 en 3, selecteert u beide verdiepingen.',
            f_balcony_present: 'Terras',
            f_balcony_present_tooltip:
                "Indien het appartment minstens \u00e9\u00e9n terras heeft waarop een tafel voor 4 personen kan staan, dan selecteert u hier 'Ja'.",
            f_garage_present: 'Garage',
            f_garage_present_tooltip:
                'Het appartment kan beschikken over \u00e9\u00e9n of meerdere garageplaatsen. Dit kan zowel een standplaats zijn als een box, binnen of buiten.',
            f_n_closed_garage: 'Aantal gesloten garages',
            f_n_closed_garage_tooltip:
                'Een gesloten garage is een garagebox, dit kan binnen of buiten zijn. Vermeld het aantal gesloten garages die bij het beoordeelde appartement behoren.',
            f_n_parking_spot: 'Aantal parkeerplaatsen',
            f_n_parking_spot_tooltip:
                'Een parkeerplaats is een staanplaats buiten of binnen en is afgebakend door markeringen en niet door muren (in tegenstelling tot gesloten garages).\nVermeld het aantal parkeerplaatsen die bij het pand horen.',
            f_cellar_attic: 'Kelder en/of zolder',
            f_cellar_attic_tooltip: '',
            f_garden_access: 'Tuin',
            f_garden_access_tooltip:
                'Het appartement kan toegang hebben tot een privatieve of gemeenschappelijke tuin.',
            f_garden_common: 'Toegang tot een gemeenschappelijke tuin',
            f_garden_common_tooltip:
                'Een gemeenschappelijke tuin is alleen toegankelijk voor de bewoners van het gebouw waar de woning wordt beoordeeld. \nAls er een gemeenschappelijke tuin is, heeft het te beoordelen appartement er dan toegang toe? Zo ja, selecteer ja.',
            f_garden_private: 'Toegang tot een privatieve tuin',
            f_garden_private_tooltip:
                'Een priv\u00e9tuin is enkel voor de bewoners van het beoordeelde appartement en grenst aan het appartement. Het verschil tussen een tuin en een terras is dat een tuin gras heeft.',
            f_volume: 'Volume hoofdgebouw',
            f_volume_tooltip: '',
            f_roof_area: 'Dakoppervlakte',
            f_roof_area_tooltip: '',
            f_mean_tilt: 'Gemiddelde dakhelling',
            f_mean_tilt_tooltip: '',
            f_percent_of_roof_flat: '% plat dak',
            f_percent_of_roof_flat_tooltip: '',
            f_min_height: 'Minimale dakhoogte',
            f_min_height_tooltip: '',
            f_max_height: 'Maximale dakhoogte',
            f_max_height_tooltip: '',
            f_n_parts: 'Delen van het hoofdgebouw',
            f_n_parts_tooltip: '',
            f_n_roof_panes: 'Dakdelen',
            f_n_roof_panes_tooltip: '',
            f_x_matched_entities: 'Entiteiten bekend op dit adres',
            f_x_matched_entities_tooltip: '',
            c_economic_activity_class: 'Soort economische activiteit',
            c_economic_activity_class_tooltip: '',
            c_economic_activity_level: 'Niveau van economische activiteit',
            c_economic_activity_level_tooltip: '',
            f_garden_area: 'Tuinoppervlak',
            f_garden_area_tooltip: '',
            f_distance_to_street: 'Afstand tot midden van straat',
            f_distance_to_street_tooltip: '',
            f_dist_building_flood: 'Dichtstbijzijnd overstromingsgebied',
            f_dist_building_flood_tooltip: '',
            f_dist_building_flood_insurance_exclusion:
                'Dichtstbijzijnd overstromingsgebied\n(risicozones)',
            f_dist_building_flood_insurance_exclusion_tooltip: '',
            f_flood_risk: 'Overstromingsrisico',
            f_flood_risk_tooltip: '',
            f_flood_risk_insurance_exclusion: 'Overstromingsrisico\n(risicozones)',
            f_flood_risk_insurance_exclusion_tooltip: '',
            f_flood_risk_composite: 'Samengesteld overstromingsrisico',
            f_flood_risk_composite_tooltip: '',
            level: 'Informatieniveau',
            level_tooltip: '',
            f_ground_height_above_sea: 'Hoogte (boven zeespiegel)',
            f_ground_height_above_sea_tooltip: '',
            property_type: 'Type woning',
            property_type_tooltip: '',
            insulated_floors: 'Ge\u00efsoleerde vloeren',
            insulated_floors_tooltip: '',
            insulated_roof: 'Ge\u00efsoleerd dak',
            insulated_roof_tooltip: '',
            insulated_walls: 'Ge\u00efsoleerde muren',
            insulated_walls_tooltip: '',
            window_type: 'Beglazing',
            window_type_tooltip: '',
            has_attic: 'Zolder',
            has_attic_tooltip: '',
            floors_insulation_years: 'Jaar plaatsing vloerisolatie',
            floors_insulation_years_tooltip: '',
            roof_insulation_year: 'Jaar plaatsing dakisolatie',
            roof_insulation_year_tooltip: '',
            walls_insulation_year: 'Jaar plaatsing muurisolatie',
            walls_insulation_year_tooltip: '',
            has_basement: 'Kelder',
            has_basement_tooltip: '',
            is_attic_heated: 'Verwarmde zolderruimte',
            is_attic_heated_tooltip: '',
            epc_score: 'EPC score',
            epc_score_tooltip: '',
            heating_fuel: 'Brandstof',
            heating_fuel_tooltip: '',
            heating_type: 'Type verwarming',
            heating_type_tooltip: '',
            heating_installation_year: 'Installatiejaar verwarming',
            heating_installation_year_tooltip: '',
            ventilation_type: 'Type ventilatie',
            ventilation_type_tooltip: '',
            solar_boiler: 'Zonneboiler',
            solar_boiler_tooltip: '',
            solar_panels: 'Zonnepanelen',
            solar_panels_tooltip: '',
            battery: 'Batterij',
            battery_tooltip: '',
            air_conditioner: 'n/a',
            air_conditioner_tooltip: '',
            spa_room: 'n/a',
            spa_room_tooltip: '',
            swimming_pool: 'n/a',
            swimming_pool_tooltip: '',
            electric_vehicle: 'Elektrische wagens',
            electric_vehicle_tooltip: '',
            n_residents: 'Aantal bewoners',
            n_residents_tooltip: '',
            protected_consumer: 'n/a',
            protected_consumer_tooltip: '',
            yearly_electricity_consumption: 'Jaarlijks elektriciteitsverbruik',
            yearly_electricity_consumption_tooltip: '',
            gas_consumption_approx: 'Jaarlijks gasverbruik',
            gas_consumption_approx_tooltip: '',
        },
        disclaimer: {
            avm_tooltip: '',
            dvm:
                'Dit waarderingsverslag is het resultaat van een evaluatie op afstand van het pand door een vastgoedschatter. Deze persoon heeft het pand niet bezocht, maar heeft op basis van diverse beschikbare data en een statistisch model een onafhankelijke waardering van het pand gemaakt. Voor meer informatie over deze waarderingsmethode verwijzen wij u naar de laatste pagina van dit rapport.',
            dvm_tooltip: '',
            ovm:
                'Dit waarderingsverslag is het resultaat van een evaluatie van het pand gemaakt ter plaatse door een vastgoedexpert. Deze onafhankelijke waardering werd gemaakt op basis van de geobserveerde situatie van het pand door de expert, en wordt verder ondersteund door een statistisch model. Voor meer informatie over deze waarderingsmethode verwijzen wij u naar de laatste pagina van dit verslag.',
            ovm_tooltip: '',
            part_1:
                'Deze evaluatie werd uitgevoerd voor het hierbij aangegeven doel. Er wordt geen verantwoordelijkheid aanvaard voor het geval dat dit rapport wordt gebruikt voor een ander doel of door een andere partij dan de partij tot wie het is gericht. Indien een andere partij dit rapport wenst te gebruiken, dient zij vooraf de schriftelijke toestemming van Rock.estate te krijgen.\n',
            part_1_tooltip: '',
            part_2:
                'De publicatie van dit waarderingsverslag, in zijn geheel of gedeeltelijk, is verboden zonder de schriftelijke toestemming van Rock.estate. Het auteursrecht van dit rapport blijft te allen tijde eigendom van Rock.estate. Dit rapport mag niet worden overgedragen aan een derde partij zonder de expliciete toestemming van de eigenaar van het copyright.',
            part_2_tooltip: '',
            reconstruction_value_title: 'Heropbouwwaarde berekening',
            reconstruction_value_title_tooltip: '',
            reconstruction_value:
                'De heropbouwwaarde is de totale kostprijs om een gebouw opnieuw op te bouwen volgens de huidige bouwnormen. Deze berekening is inclusief BTW en erelonen van architecten, is niet afhankelijk van de locatie en omvat geen sloopkosten of bijgebouwen. Hierdoor is het mogelijk dat de heropbouwwaarde hoger is dan de marktwaarde. De heropbouwwaarde is gebaseerd op de ABEX 847 index.',
            reconstruction_value_tooltip: '',
            part_3:
                'Dit waarderingsverslag spreekt zich niet uit over, noch aanvaardt het enige verantwoordelijkheid voor, het (on)vermogen en de (on)wil van de hypotheeknemer om zijn verplichtingen in het kader van de voorgestelde lening na te komen.',
            part_3_tooltip: '',
        },
        info: {
            data_from_customer: 'data verkregen van de klant',
            data_from_customer_tooltip: '',
        },
        dvm_features: {
            style_of_house: 'Stijl van gebouw',
            style_of_house_tooltip: '',
            exterior_state: 'Staat van het pand aan de buitenkant',
            exterior_state_tooltip: '',
            facade_material: 'Gevelmateriaal',
            facade_material_tooltip: '',
            roof_material: 'Materiaal dak',
            roof_material_tooltip: '',
            n_roof_windows: 'Aantal dakvensters',
            n_roof_windows_tooltip: '',
            n_roof_dormers: 'Aantal dakkapellen',
            n_roof_dormers_tooltip: '',
            solar_panel_area: 'Zonnepanelen oppervlakte',
            solar_panel_area_tooltip: '',
            f_building_listed: 'Erfgoed',
            f_building_listed_tooltip: '',
            garden_orientation: 'Ori\u00ebntatie van de tuin',
            garden_orientation_tooltip: '',
            garden_arrangement: '(Mogelijke) tuininrichting',
            garden_arrangement_tooltip: '',
            f_swimming_pool: 'Buitenzwembad',
            f_swimming_pool_tooltip: '',
            f_swimming_pool_area: 'Grootte buitenzwembad',
            f_swimming_pool_area_tooltip: '',
            direct_traffic_level: 'Niveau van direct straatverkeer',
            direct_traffic_level_tooltip: '',
            indirect_traffic_level: 'Nabijheid van indirect straatverkeer',
            indirect_traffic_level_tooltip: '',
            surroundings: 'Beschrijving van de omgeving',
            surroundings_tooltip: '',
            other_positive:
                'Andere elementen die een positieve invloed hebben op de vastgoedwaardering',
            other_positive_tooltip: '',
            other_negative:
                'Andere elementen die een negatieve invloed hebben op de vastgoedwaardering',
            other_negative_tooltip: '',
            remarks: 'Opmerkingen van de vastgoeddeskundige',
            remarks_tooltip: '',
            internal_remarks: 'Interne opmerkingen',
            internal_remarks_tooltip: '',
        },
        '': {
            'clarify here': 'Economische activiteit',
            'clarify here_tooltip': '',
        },
        fixed: {
            parcels_and_building_shapes: 'Vorm van perceel en gebouw',
            parcels_and_building_shapes_tooltip: '',
        },
        footnote: {
            no_renovation_included:
                'Alle waarden zijn berekend voor de huidige staat van het pand en houden geen rekening met eventuele geplande renovaties',
            no_renovation_included_tooltip: '',
            avm: 'Automatische waardering',
            avm_tooltip: '',
            q25: '25e percentiel',
            q25_tooltip: '',
            q75: '75e percentiel',
            q75_tooltip: '',
        },
        reference_properties_location: {
            explanation_1:
                'De waardering van dit pand is gebaseerd op {num_references} referentiepanden in de buurt (binnen een afstand van {reference_radius} km). Elk referentiepand deelt een aantal elementen inzake ligging, grootte en andere vergelijkbare kenmerken. Het onderliggende statistische model van deze waardering is gebaseerd op een "Geographically Weighted Regression" (GWR), waarbij referentiepunten in de onmiddellijke nabijheid van het pand een grotere gewicht krijgen in vergelijking met referentiepunten die zich verderaf bevinden.',
            explanation_1_tooltip: '',
            explanation_2:
                'De onderstaande kaart geeft een overzicht van de locatie van de referentiepanden en de afstand tot het te evalueren pand. De kleurenlegende geeft een goede indicatie van de gemiddelde marktwaarde van woningen in uw omgeving.',
            explanation_2_tooltip: '',
        },
        reference_properties_characteristics: {
            explanation_1:
                'Een pand heeft diverse objectieve kenmerken die bijdragen aan de prijs zoals locatie, grootte en energie-effici\u00ebntie. De onderstaande grafieken vergelijken een aantal van deze kenmerken van deze woning met de andere referentiepanden in de buurt.',
            explanation_1_tooltip: '',
            property_characteristics: 'Kenmerken pand',
            property_characteristics_tooltip: '',
            properties_in_the_area: 'Referentiepanden in uw buurt',
            properties_in_the_area_tooltip: '',
        },
        flood: {
            explanation_1:
                "Overzicht van de offici\u00eble overstromingsgebieden in de nabijheid van de woning. Er worden verschillende risicocategorie\u00ebn berekend op basis van verschillende overstromingsrisicoscenario's, vari\u00ebrend van laag tot hoog.",
            explanation_1_tooltip: '',
        },
        faq: {
            q_incorrect_valuation:
                'Het adres van de gewaardeerde woning is onjuist, wat moet ik doen?',
            q_incorrect_valuation_tooltip: '',
            a_incorrect_valuation:
                'Misschien ging er iets mis bij het selecteren van het adres, het gebouw of de percelen die bij het pand horen. U kunt de adresgegevens bekijken via de volgende link: {url.dashboard}.\u2028',
            a_incorrect_valuation_tooltip: '',
            q_incorrect_characteristics:
                'Het adres van het pand is juist, maar andere kenmerken in dit waarderingsverslag zijn niet correct, wat moet ik doen?',
            q_incorrect_characteristics_tooltip: '',
            a_incorrect_characteristics:
                'Indien u denkt dat bepaalde informatie in dit verslag substantieel kunnen verschillen, en dit zou kunnen leiden tot een belangrijke impact op de waardering van het onroerend goed, volg dan deze link {url.dashboard} om de onjuiste/ontbrekende informatie te controleren en te verduidelijken.',
            a_incorrect_characteristics_tooltip: '',
            q_transaction_vs_market_value:
                'Waarom verschilt de transactieprijs die ik heb betaald van de marktwaarde die in dit verslag wordt bepaald?',
            q_transaction_vs_market_value_tooltip: '',
            a_transaction_vs_market_value_dvm:
                'Dit waarderingsverslag is het resultaat van de waardering van het pand in kwestie, uitgevoerd door een vastgoedschatter op afstand, en ondersteund door een statistisch model. Het verslag geeft een nauwkeurige indicatie van de geschatte waarde van het pand, op basis van de beschikbare informatie. Aangezien de schatter het onroerend goed niet effectief heeft bezocht, kan het gebeuren dat de impact van bepaalde, specifieke kenmerken van het onroerend goed, niet volledig tot uiting komt in de geschatte transactieprijs. Neem een kijkje op onze website voor meer informatie: {url.rockestate_info}.',
            a_transaction_vs_market_value_dvm_tooltip: '',
            a_transaction_vs_market_value_ovm:
                'Dit waarderingsverslag is het resultaat van de evaluatie, door een vastgoedexpert, op afstand en ondersteund door een statistisch model. Het verslag geeft een nauwkeurige indicatie van de geschatte waarde van het pand, op basis van de beschikbare informatie. Aangezien de expert het onroerend goed niet effectief heeft bezocht, kan het gebeuren dat de impact van bepaalde, specifieke kenmerken van het onroerend goed, niet volledig tot uiting komt in de geschatte transactieprijs. Neem een kijkje op onze website voor meer informatie: {url.rockestate_info}.',
            a_transaction_vs_market_value_ovm_tooltip: '',
            q_loan_accepted:
                'Nu ik mijn waarderingsverslag heb ontvangen, betekent dit dat mijn kredietaanvraag is goedgekeurd?',
            q_loan_accepted_tooltip: '',
            a_loan_accepted:
                'Wij hebben dit waarderingsverslag met u en uw bank gedeeld. Dit verslag zal door de bank worden gebruikt als een van de verschillende elementen in haar beslissingsproces. De uiteindelijke beslissing komt toe aan de bank. Rock.estate komt hier op geen enkele manier in tussen.',
            a_loan_accepted_tooltip: '',
            'where-bryan_tooltip': '',
            'where-bryan_question': 'Waar is Bryan?',
            'where-bryan_answer': "Aan 't zee.",
            'internet-down_tooltip': '',
            'internet-down_question':
                'Kan je me alsjeblieft helpen ? Mijn internet werkt niet meer',
            'internet-down_answer':
                'Heb je geprobeerd\n\n- Om het uit te schakelen\n- En weer aanzetten?',
            'where-dog_tooltip': '',
            'where-dog_question': 'Heb je mijn hond gezien?',
            'where-dog_answer':
                '![Shiba](https://www.meme-arsenal.com/memes/3cd52c228bb8aff4714b1ea387263803.jpg)\n\nNu heb je',
            'what-is-avm_tooltip': '',
            'what-is-avm_question': 'Wat is een automatische schatting? ',
            'what-is-avm_answer':
                "Een Statistische Waarderingsmethode is elke vorm van wiskundige tool die gebruikt kan worden om de waarde van een onroerend goed te schatten door middel van berekeningen.\n\nRock.estate gebruikt een implementatie van een Comparables-Based Automated Valuation Model (CBAVM of kortweg AVM). Comparable-based AVM's maken gebruik van gesofisticeerde algoritmen om vergelijkbare panden te selecteren en op een geautomatiseerde manier een waarde te berekenen voor een specifiek onroerend goed op een bepaalde datum met behulp van wiskundige modelleringstechnieken. \n\nAutomatic Valuations zijn per definitie automatisch: zij worden op afstand uitgevoerd zonder enige menselijke tussenkomst.\n\n",
            'what-is-dvm_tooltip': '',
            'what-is-dvm_question': 'Wat is een schatting op afstand? ',
            'what-is-dvm_answer':
                'Een schatting op afstand wordt uitgevoerd door een schatter, zonder dat deze het onroerend goed van binnen bezoekt. De schatter baseert zich op verschillende gegevensbronnen, een statistisch model en eventuele informatie die door de kredietnemer wordt verstrekt.\n\n\nEen succesvolle schatting op afstand combineert het beste van twee werelden: een sterk data gedreven model, onder supervisie van een schatter om het model te corrigeren en aan te vullen. Dit is een valabel alternatief voor schattingen ter plaatse voor het grootste deel van het residentieel vastgoed.\n',
            'what-is-ovm_tooltip': '',
            'what-is-ovm_question': 'Wat is een schatting ter plaatse? ',
            'what-is-ovm_answer':
                'Een schatting ter plaatse is de traditionele schatting waarbij een schatter het interieur en exterieur van een woning bezoekt om een schatting te maken van de waarde. Dit biedt het meest complete beeld van de waarde. Het is wel het alternatief dat de meeste middelen en tijd vergt.',
            'what-is-dashboard_tooltip': '',
            'what-is-dashboard_question': 'Wat is het Dashboard?',
            'what-is-dashboard_answer':
                'Het Dashboard is de plaats waar je toegang hebt tot al jouw aanvragen en op de hoogte bent van hun huidige status; ingediend, nog in ontwerp, gewaardeerd of als een schatting ter plaatse wordt aanbevolen.\n\nDeze plaats geeft je de mogelijkheid om:\n- toegang te hebben tot een verzoek dat nog niet voor een schatting is ingediend\n- de ingevulde informatie te zien van een verzoek dat al is ingediend\n- het verslag van de schatting te downloaden als de aanvraag door een schatter is beoordeeld\n- eigenaar te worden van een verzoek dat nog niet volledig is ingevuld door een ander lid van jouw team (indien van toepassing) \n\n',
            'what-is-draft_tooltip': '',
            'what-is-draft_question':
                'Wat gebeurt er als mijn aanvraag de status "Ontwerp" heeft? ',
            'what-is-draft_answer':
                "Er zijn twee verschillende scenario's waar een  aanvraag als \u2018Ontwerp\u2019 wordt gezien. Beiden vragen om een actie van de aanvrager.\n1) De hypotheekadviseur wacht op ontbrekende informatie van zijn cli\u00ebnt over het onroerend goed.\n2) De schatter expert heeft incorrecte informatie gezien die gecorrigeerd dient te worden.",
            'market-price-incorrect_tooltip': '',
            'market-price-incorrect_question':
                'De marktwaarde in het verslag is niet juist.',
            'market-price-incorrect_answer':
                'Als de door de schatter opgegeven marktwaarde niet overeenkomt met de transactieprijs van de leningaanvraag, controleer dan of het geschatte onroerend goed het juiste is en of de gegevens die de aanvrager bij het indienen van zijn aanvraag heeft ingevuld, juist zijn. Als de gegevens juist zijn, neem dan contact op met jouw manager om te weten wat de volgende stappen zijn. Als je na controle nog steeds van mening bent dat de afwijking te groot is, neem dan contact met ons op via het contactformulier voor meer informatie.',
            'issue-parcels_tooltip': '',
            'issue-parcels_question':
                'De percelen in het verslag komen niet overeen met de werkelijke percelen.',
            'issue-parcels_answer':
                'Als de voor schatting geselecteerde percelen niet juist zijn, kan dit te wijten zijn aan een onjuiste selectie tijdens het invoeren van de gegevens. \nVolg de eenvoudige stappen hieronder om in eerste instantie de ingevulde gegevens van de aanvraag te controleren:\n- Log in op het Rock.estate schatting platform\n- Klik op de knop "Dashboard"\n- Vul in de zoekbalk de aanvraag in met het verkeerde perceel\n- Als je het verzoek ziet klik dan op de knop "Aanvraag bekijken" aan de rechterkant\nNu zou je de ingevulde gegevens moeten zien. \n\nAls de invoer correct is, laat het ons dan weten via ons contactformulier. Dit is belangrijke informatie die van invloed is op de waarde van het onroerend goed.',
            'old-picture-or-3d_tooltip': '',
            'old-picture-or-3d_question':
                "De woning is aanzienlijk veranderd ten opzichte van de foto's of het 3D model in het verslag.",
            'old-picture-or-3d_answer':
                "Als je ziet dat de schatting gebaseerd is op oude foto's van het onroerend goed die niet meer overeenkomen met de werkelijkheid, neem dan contact op via ons contactformulier onderaan de pagina. ",
            'report-language_tooltip': '',
            'report-language_question':
                'Hoe kan ik het verslag in een andere taal verkrijgen? ',
            'report-language_answer':
                'Om het verslag in een andere taal te krijgen: \n- Ga naar het Rock.estate schatting platform\n- Ga naar jouw Dashboard\n- In de rechter bovenhoek, selecteer de gewenste taal voor het verslag.\n\nJe kan nu het verslag downloaden in de juiste taal!\n\nHoud er rekening mee dat de opmerking van onze schatters niet worden vertaald, omdat het handmatige invoer betreft die door een schatter is ingevuld op het moment van de schatting.',
            'wrong-pictures_tooltip': '',
            'wrong-pictures_question':
                'De foto in het verslag komt niet overeen met de geschatte woning.',
            'wrong-pictures_answer':
                'De street view op het verslag is geselecteerd door Google en geeft soms niet het het juiste gebouw weer. Dit betekent niet dat de schatter het verkeerde pand heeft geschat. Als je toegang hebt tot het webverslag, kan je de street view zelf wijzigen.',
            sharing_tooltip: '',
            sharing_question: 'Mag ik dit verslag delen met mijn klant?',
            sharing_answer:
                'Dit hangt af van de interne regels die door jouw bedrijf worden opgelegd. \nWij raden je aan om bij jouw leidinggevende te bevestigen of het verslag gedeeld mag worden',
            'remarks-language_tooltip': '',
            'remarks-language_question':
                'Waarom worden de opmerkingen in het verslag niet aangepast aan de taal van het Dashboard en de rest van het verslag wel?',
            'remarks-language_answer':
                'Wanneer een aanvrager een schatting aanvraagt in een specifieke taal (bv. Frans), worden de opmerkingen geschreven in de geselecteerde taal of in het Engels. Als de aanvrager de taal wijzigt na het indienen van zijn aanvraag, zal de taal van de opmerkingen niet worden gewijzigd, aangezien dit niet geautomatiseerd is. ',
            'appeal-missing-feature_tooltip': '',
            'appeal-missing-feature_question':
                'De schatter heeft met een bepaald element geen rekening gehouden (zonnepanelen, extra percelen, zwembad,...)',
            'appeal-missing-feature_answer':
                'Als de schatter geen rekening heeft gehouden met een element dat mogelijk van invloed is op de waarde van het onroerend goed, neem dan contact op met ons via het contactformulier om dit te laten weten.\nElementen die als potentieel belangrijk worden beschouwd zijn de volgende:\n- Adres\n- BEbouw oppervlakte\n- Oppervlakte van het perceel\n- EPC\n- Bewoonbaar oppervlakte\n- Bouwjaar\n- Aantal slaapkamers\n- Appartement verdieping(en)\n- Aanwezigheid van een priv\u00e9 of gemeenschappelijke tuin\n- Terras\n- Buitenzwembad\n- Zonnepanelen\n- Historische classificatie van het eigendom (voorgevel of volledig gebouw)\n- Overstromingsgebieden\n- Secundaire gebouwen\n- Aanwezigheid van parking of gesloten garage\n\n',
            'where-customer-ref_tooltip': '',
            'where-customer-ref_question':
                'Waar kan ik de interne referentie van de lening vinden? ',
            'where-customer-ref_answer':
                'Deze referentie is intern bij de bank en wij raden je aan deze referentie te kopi\u00ebren/plakken uit jullie systeem voor het opstellen/aanmaken van leningen.',
            'building-type-apartment-building_tooltip': '',
            'building-type-apartment-building_question':
                'Hoe kan ik een schatting aanvragen voor een appartementsgebouw?',
            'building-type-apartment-building_answer':
                'Rock.estate schat geen appartementsgebouwen. Een dergelijk verzoek zal resulteren in een schatting ter plaatse.',
            'address-not-found_tooltip': '',
            'address-not-found_question':
                "Rock.estate's schatting applicatie kent het adres van de te financiering woning niet.",
            'address-not-found_answer':
                'Als het automatisch aanvullen niet werkt en de applicatie het adres niet kan vinden, kan je het adres handmatig invoeren door: \n- Terug te keren naar je schatting aanvraag\n- In de Gebouw & Perceelselectie pagina klik je op "Ik kan het hoofdgebouw niet vinden"\n- Volg de instructies',
            'building-not-found_tooltip': '',
            'building-not-found_answer':
                'Als je het gebouw niet kan vinden, volg je deze procedure:\n-Klik op "Dit is niet het juiste gebouw" en het platform zal je toelaten een ander gebouw te selecteren.\n-Als je het nog steeds niet kan vinden, klik dan op "Ik kan het hoofdgebouw niet vinden" en je wordt doorgestuurd naar een nieuwe pagina. Op deze pagina moet je extra informatie opgeven, zoals de oppervlakte van het perceel, de bebouwde oppervlakte en het aantal gevels. \n\n',
            'find-epc_tooltip': '',
            'find-epc_question': 'Waar kan ik de EPC van een woning vinden?',
            'find-epc_answer':
                'Een EPC is verplicht bij de verkoop van een woning. Als de kredietnemer deze informatie niet kan verstrekken, probeer het dan te vinden op de verkoopadvertentie. \nAarzel niet om jouw aanvraag op te slaan voordat je op zoek gaat naar de EPC. ',
            'epc-label-vs-number_tooltip': '',
            'epc-label-vs-number_question':
                'EPC: Is het beter om het label te geven (letter van A tot G) of om het exacte aantal kWh/(m\u00b2 jaar) op te geven?',
            'epc-label-vs-number_answer':
                'De schatting is sterk afhankelijk van de locatie, het perceel, de bewoonbare oppervlakte, het bouwjaar en de EPC-score. Daarom raden wij je aan de aanvraag in te dienen met de exacte EPC score in kWh/sqm-jaar. \nAls je alleen de labelletter van A tot en met G geeft, zullen wij de woning waarderen door de gemiddelde score van elk label te nemen, wat zal resulteren in een benaderende schatting van de marktwaarde van de woning.',
            'find-living-area_tooltip': '',
            'find-living-area_question':
                'Waar kan ik de correcte bewoonbare oppervlakte van een woning vinden?',
            'find-living-area_answer':
                'Je kan de bewoonbare oppervlakte terugvinden in verschillende documenten met betrekking tot het onroerend goed, zoals bijvoorbeeld de verkoopovereenkomst of het EPC-certificaat. Houd er rekening mee dat een bijgebouw/kelder/ongebruikte zolder niet als bewoonbare ruimte en dus niet als bewoonbaar oppervlakte worden beschouwd. ',
            'find-box-number_tooltip': '',
            'find-box-number_question':
                'Waar kan ik het busnummer van een appartement vinden?',
            'find-box-number_answer':
                'Het busnummer van een appartement maakt deel uit van het adres dat moet worden ingevuld op de verkoopovereenkomst of het EPC-certificaat. Als je niet zeker bent van deze informatie, neem dan contact op met de kredietnemer en vul de aanvraag aan zodra je de informatie hebt. ',
            'find-construction-year_tooltip': '',
            'find-construction-year_question': 'Waar kan ik het bouwjaar vinden?',
            'find-construction-year_answer':
                'Het bouwjaar kan bijvoorbeeld gevonden worden in de verkoopovereenkomst of op het EPC-certificaat. Let op: het bouwjaar is het jaar van de daadwerkelijke bouw van het gebouw, niet het jaar van de eventuele renovaties. ',
            'construction-vs-renovation_tooltip': '',
            'construction-vs-renovation_question':
                'Het pand is volledig verbouw: wat is het bouwjaar dan, het oorspronkelijk bouwjaar of het jaar van de renvoatie?',
            'construction-vs-renovation_answer':
                'Het bouwjaar is het werkelijke jaar waarin het gebouw werd gebouwd, wij houden geen rekening met het jaar van eventuele renovaties. De verkoopsovereenkomst of het EPC-certificaat moeten deze renovaties weergeven. ',
            'over-two-days_tooltip': '',
            'over-two-days_question':
                'Het is meer dan 2 werkdagen geleden en mijn aanvraag is nog steeds niet geschat, wat moet ik doen?',
            'over-two-days_answer':
                'De werkdagen van Rock.estate zijn van maandag tot en met vrijdag. \nHet is mogelijk dat je een zaterdag of een feestdag hebt meegerekend in de twee werkdagen waarnaar je verwijst.\n\nZo niet, neem dan contact op via ons contactformulier met vermelding van het referentienummer.',
            'right-info-flagged-incorrect_tooltip': '',
            'right-info-flagged-incorrect_question':
                'Wat gebeurt er als de schatter bepaalde informatie als "incorrect" heeft gemarkeerd, maar ik weet zeker dat dit de juiste informatie is?',
            'incoherent-submitted-twice_tooltip': '',
            'incoherent-submitted-twice_question':
                'Wat gebeurt er als een schatting tweemaal is teruggestuurd met "incorrecte informatie"?',
            'processing-time_tooltip': '',
            'processing-time_question':
                'Hoe lang duurt het voordat een aanvraag wordt verwerkt? ',
            'multiple-addresses_tooltip': '',
            'multiple-addresses_question':
                'Is het normaal dat er meer dan \u00e9\u00e9n adres is voor \u00e9\u00e9n gebouw? ',
            'what-is-bedroom_tooltip': '',
            'what-is-bedroom_question': 'Wat is een slaapkamer?',
            'what-is-garden_tooltip': '',
            'what-is-garden_question': 'Wat is een gemeenschappelijke tuin?',
            'what-is-private-garden_tooltip': '',
            'what-is-private-garden_question':
                'Wat wordt gezien als een privatieve tuin? ',
            'what-is-closed-garage_tooltip': '',
            'what-is-closed-garage_question': 'Wat is een gesloten garage?',
            'what-is-parking-spot_tooltip': '',
            'what-is-parking-spot_question': 'Wat is een parkeerplaats? ',
            'what-is-cellar-basement_tooltip': '',
            'what-is-cellar-basement_question': 'Wat is een kelder/zolder? ',
            carport_tooltip: '',
            carport_question: 'Is een carport een parkeerplaats of een gesloten garage? ',
            carport_answer:
                'Een carport verwijst naar een parkeerplaats, niet naar een gesloten garage. ',
            'personal-use_tooltip': '',
            'personal-use_question':
                'Kan ik een schatting aanvragen van mijn eigen woning? ',
            'personal-use_answer':
                "Rock.estate's Schatting Platform kan alleen worden gebruikt voor professionele doeleinden.\nHet is de verantwoordelijkheid van elke geautoriseerde gebruiker om zich hieraan te houden. Vandaar dat wij je niet aanraden om een schatting aan te vragen van jouw eigen woning of van iemand die je kent.",
            'is-request-submitted_tooltip': '',
            'is-request-submitted_question':
                'Hoe kan ik zien of ik mijn aanvraag met succes heb ingediend?',
            'is-request-submitted_answer':
                "Zodra je jouw aanvraag met succes hebt ingediend, zou de status van de aanvraag in het Dashboard moeten veranderen naar 'Ingediend'. \nJe ontvangt ook een e-mail met de melding dat het verzoek is ingediend en dat een van onze schatters het binnen twee werkdagen zal verwerken.",
            review_tooltip: '',
            review_question:
                'De aanvraag die ik indiende kwam terug als "ontwerp" in het rood, wat moet ik doen? ',
            review_answer:
                'Als je een aanvraag hebt ontvangen met de status "Ontwerp", betekent dit dat de schatter \u00e9\u00e9n of meerdere gegevens als "onjuist" heeft gemarkeerd. Als je zeker bent dat deze informatie toch juist is, dien je de aanvraag opnieuw in zoals ze is of neem contact op met ons via het contactformulier. ',
            'find-previous-request_tooltip': '',
            'find-previous-request_question':
                'Hoe kan ik een eerdere aanvraag terugvinden? ',
            'find-previous-request_answer':
                'Om een eerdere aanvraag terug te vinden, ga je naar jouw Dashboard en gebruik je de zoekfunctiebalk. je kan een datum invoeren, een referentie, een interne referentie, een adres, ...',
            'download-report_tooltip': '',
            'download-report_question': 'Hoe kan ik het verslag downloaden? ',
            'download-report_answer':
                "Om het verslag te downloaden, kilk je op het groene vakje naast jouw aanvraag genaamd 'PDF verslag'.  \nHet verslag is alleen beschikbaar nadat jouw aanvraag met succes is geschat door een schatter van Rock.estate.",
            'language-impact_tooltip': '',
            'language-impact_question':
                'Verandert er iets aan het proces als ik het Rock.estate schatting platform in een andere taal gebruik? ',
            'language-impact_answer':
                'Als je een schatting aanvraagt in een bepaalde taal, ontvang je een verslag van dat onroerend goed in diezelfde taal. Je kan de taal van het verslag wijzigen, maar de opmerking blijven in de oorspronkelijke taal.\nEr zal geen invloed zijn op de gegevens of de marktwaarde van een onroerend goed.',
            'unsubmission-window_tooltip': '',
            'unsubmission-window_question':
                'Hoe kan ik een aanvraag wijzigen nadat het in ingediend? ',
            'unsubmission-window_answer':
                'Je hebt 60 minuten de tijd om een verzoek te wijzigen of te verwijderen. Om dit te doen, ga je naar jouw Dashboard en klik je op het gele vakje \'Bewerken\'. Na het bewerken van jouw verzoek klik je op "Verzenden" om het verzoek opnieuw in te dienen. ',
            'after-sixty-minutes_tooltip': '',
            'after-sixty-minutes_question':
                'Wat kan ik doen als ik mijn aanvraag na 60 minuten na het indienden nog moet wijzigen? ',
            'after-sixty-minutes_answer':
                'Als je een aanvraag na 60 minuten wil wijzigen of annuleren, laat het ons dan zo snel mogelijk weten via ons contactformulier. Dit verzoek kan in rekening worden gebracht afhankelijk van de voortgang van de schatter.',
            'review-billing_tooltip': '',
            'review-billing_question':
                'Mijn aanvraag kwam terug als een "ontwerp", betekent dit dat de bank twee keer moet betalen voor deze aanvraag?',
            'review-billing_answer':
                'Nee, de bank wordt slechts eenmaal gefactureerd nadat de aanvraag succesvol is geschat of wordt aanbevolen voor een schatting ter plaatse. \nIndien de aanvraag na twee keer terugsturen als "ontwerp" niet met succes op afstand kan worden geschat, zal een aanbeveling worden gedaan voor een schatting ter plaatse.',
            'on-site-next-steps_tooltip': '',
            'on-site-next-steps_question':
                'Een schatting ter plaatse is aangevraagd, wat is de volgende stap? ',
            'on-site-next-steps_answer':
                'Rock.estate is momenteel niet betrokken bij de schattingen ter plaatse. Wij raden je aan het interne proces van de Bank voor schattingen ter plaatse te volgen. ',
            'access-requests_tooltip': '',
            'access-requests_question':
                "Hoe kan ik toegang krijgen tot de verzoeken van mijn collega's? ",
            substitution_tooltip: '',
            substitution_question:
                'Hoe kan ik eigenaar worden van een verzoek dat niet van mij is? ',
        },
        questions_feedback: {
            explanation_1:
                'Heeft u een specifieke vraag of opmerking over dit rapport? Of bent u gewoon op zoek naar wat meer informatie over hoe Rock.estate tot deze waardering komt?',
            explanation_1_tooltip: '',
            explanation_2: 'Bezoek ons waarderingsportaal: {url.valuation_app}.',
            explanation_2_tooltip: '',
        },
        email: {
            submission_confirmation_subject: 'Je schattingsaanvraag is ingediend',
            submission_confirmation_subject_tooltip: '',
            submission_confirmation_1:
                'Beste,\n\nWe hebben je nieuwe schattingsaanvraag in goede orde ontvangen en dit met de volgende referentie:',
            submission_confirmation_1_tooltip: '',
            submission_confirmation_2:
                'Ons team van schatters-experten zal je aanvraag zo spoedig mogelijk behandelen. Je kan steeds de status van je aanvraag opvolgen via je persoonlijke Rock.estate dashboard.\n\nMet vriendelijke groeten,\nTeam van de schatters-experten Rock.estate',
            submission_confirmation_2_tooltip: '',
            valuation_notification_subject: 'Je schattingsaanvraag is verwerkt',
            valuation_notification_subject_tooltip: '',
            valuation_notification_1:
                'Beste,\n\nDe volgende aanvraag is met succes beoordeeld en ge\u00ebvalueerd door onze experten:',
            valuation_notification_1_tooltip: '',
            valuation_notification_2:
                'Gelieve in te loggen op je dashboard om het rapport te downloaden.\n\nMet vriendelijke groeten,\nTeam van de schatters-experten Rock.estate',
            valuation_notification_2_tooltip: '',
            postpone_subject: 'Uw schattingsaanvraag werd uitgesteld. ',
            postpone_subject_tooltip: '',
            postpone_1: 'Geachte mevrouw, geachte heer,',
            postpone_1_tooltip: '',
            postpone_2: 'uitgesteld',
            postpone_2_tooltip: '',
            postpone_3: 'met dank',
            postpone_3_tooltip: '',
            incoherent_features_subject: 'Je schattingsaanvraag kon niet worden verwerkt',
            incoherent_features_subject_tooltip: '',
            incoherent_features_1:
                'Geachte mevrouw, geachte heer,\n\nUw schattingsaanvraag (zie referentie hieronder) kan niet worden gefinaliseerd en dit omwille van incorrecte informatie:',
            incoherent_features_1_tooltip: '',
            incoherent_features_2:
                'Onze schatter-expert heeft opgemerkt dat bij deze aanvraag volgende kenmerken mogelijk incorrect zijn: ',
            incoherent_features_2_tooltip: '',
            incoherent_features_3: 'Aanvullend commentaar: ',
            incoherent_features_3_tooltip: '',
            incoherent_features_4:
                'Gelieve deze informatie te corrigeren op jouw Rock.estate dashboard indien nodig. Zodra de nodige wijzigingen zijn aangebracht, dien je jouw aanvraag opnieuw in en zal ons team van experts deze beoordelen. \n\nAls de informatie inderdaad juist is, hoef je niets te veranderen en alleen jouw verzoek opnieuw in te dienen.\n\n\nMet vriendelijke groeten,\nTeam van de schatters-experten Rock.estate',
            incoherent_features_4_tooltip: '',
            owner_updated_subject: 'Uw schattingsaanvraag is nu in andere handen',
            owner_updated_subject_tooltip: '',
            owner_updated_1:
                'Geachte mevrouw, geachte heer,\n\nDe schattingsaanvraag met onderstaande referentie wordt nu behandeld door:',
            owner_updated_1_tooltip: '',
            owner_updated_2:
                "Indien u de opvolging van uw aanvraag wenst op te volgen of terug eigenaar wenst te worden, kunt u naar de 'Alle aanvragen' sectie gaan in uw Rock.estate overzicht en de bovenstaande referentie invullen in de aangeduide zoek criteria.\n\nMet vriendelijke groeten.\n\nTeam van de schatters-experten Rock.estate",
            owner_updated_2_tooltip: '',
            on_site_requested_value:
                'Beste,\n\nNa een grondige analyse kan onze schatting-expert je schattingsaanvraag (zie referentie hieronder) niet finaliseren:\n- LOP ID:\n- Schatting ID:\n- Adres:\n\nDoor het unieke karakter van deze woning en het contrast met andere eigendommen in de buurt, stellen wij voor om een schatter ter plaatse te sturen.\n\nMet vriendelijke groeten,\n\nTeam van de schatters-experten Rock.estate',
            on_site_requested_value_tooltip: '',
            argenta: {
                on_site_requested_characteristics_1:
                    'Beste,\n\nVanwege het unieke karakter en het contrast met andere panden in de omgeving kan ons team van schatters-experten geen schatting op afstand uitvoeren.',
                on_site_requested_characteristics_1_tooltip: '',
                on_site_requested_characteristics_2:
                    'Rock.estate stelt voor om een schatting ter plaatste uit te voeren voor dit pand. \n\nMet vriendelijke groeten,\n\nTeam van de schatters-experten Rock.estate',
                on_site_requested_characteristics_2_tooltip: '',
                on_site_requested_characteristics_subject:
                    'Een schatting op afstand voor jouw verzoek kan niet worden uitgevoerd',
                on_site_requested_characteristics_subject_tooltip: '',
            },
            onsite_request_subject: 'Een schatting ter plaatse wordt aanbevolen',
            onsite_request_subject_tooltip: '',
            onsite_request_1:
                'Beste,\n\nNa onderzoek door onze schatter-expert kan de door u ingediende schattingsaanvraag (zie onderstaande referenties) niet in behandeling worden genomen:',
            onsite_request_1_tooltip: '',
            onsite_request_2:
                'Daarom stellen wij voor dat je de interne procedure van jouw bank volgt voor wanneer een waardering op afstand niet kan worden uitgevoerd.\nIndien \u00e9\u00e9n van de ingevulde gegevens onjuist is (bv. verkeerde selectie van een perceel, een gebouw of bepaalde kenmerken van het onroerend goed) verzoeken wij je een nieuwe aanvraag in te dienen met de juiste gegevens en dan zullen wij het nodige doen.\n\nMet vriendelijke groeten,\nTeam van de schatters-experten Rock.estate',
            onsite_request_2_tooltip: '',
            address_not_found:
                'Beste,\n\nJe hebt een schatting aangevraagd via onze tool, maar er blijkt een probleem te zijn met het adres van de woning.\n \nKan je de aanvraag nakijken voor de woning met volgende referenties: \n- LOP ID: [LOP ID]\n- Valuation ID: [Valuation ID]\n- Address: [Address]\n\nWij konden dit adres zowel in onze tools als online niet terugvinden. Gelieve het adres in je dashboard aan te passen indien dit incorrect is. \n\nWij hebben alle correcte informatie nodig om [BANK] een correcte waardebepaling te geven van deze woning. Van zodra het adres bevestigd is zullen onze schatter-experts de waardebepaling van de desbetreffende woning zo snel mogelijk afronden.\n\nMet vriendelijke groeten,\n\nTeam van de schatters-experten Rock.estate',
            address_not_found_tooltip: '',
            onsite_streetview:
                'Wij kunnen geen schatting uitvoeren, omdat het eigendom niet zichtbaar is via onze streetview-tools of omdat de streetview-beelden te oud zijn.',
            onsite_streetview_tooltip: '',
            onsite_incoherent_features:
                'De hierboven vermelde kenmerken lijken onjuist te zijn voor deze woning, bovendien hebben we deze aanvraag teruggestuurd voor herziening en vervolgens ontvangen met een nog steeds onjuist resultaat. \n',
            onsite_incoherent_features_tooltip: '',
            onsite_wrong_building_selected:
                'Het geselecteerde gebouw is niet correct voor deze woning, \nOm deze reden kunnen wij geen schatting op afstand uitvoeren en raden wij jou aan een nieuwe aanvraag te maken en het juiste gebouw te selecteren. \nWe zullen de aanvraag dan zo snel mogelijk bekijken.',
            onsite_wrong_building_selected_tooltip: '',
            onsite_exceptional_property:
                'Wij kunnen geen schatting op afstand uitvoeren aangezien dit pand als uitzonderlijk voor het gebied wordt beschouwd. \nWij beschouwen een eigendom als "uitzonderlijk" als het ongewone kenmerken heeft voor de buurt, zoals een zeer groot/klein bewoonbaar oppervlakte, een tennisveld of zwembad terwijl de rest van de buurt dat niet heeft, maar bijvoorbeeld ook als het eigendom in slechte staat is in vergelijking met de rest van de straat.',
            onsite_exceptional_property_tooltip: '',
            onsite_commercial_building:
                'Wij kunnen geen schatting op afstand uitvoeren aangezien dit pand een commercieel gebouw is of er een commerci\u00eble activiteit aan is verbonden.',
            onsite_commercial_building_tooltip: '',
            onsite_volume_missing:
                'Wij kunnen geen schatting op afstand uitvoeren omdat ons model niet in staat is geweest bepaalde essenti\u00eble elementen te achterhalen waarmee het volume van het gebouw en/of het perceel automatisch zou kunnen worden berekend. \nHet Rock.estate-model verzamelt gegevens uit verschillende bronnen, zoals de gemeente of de regio, het is mogelijk dat de gegevens nog niet zijn bijgewerkt door \u00e9\u00e9n van deze bronnen, waardoor Rock.estate geen objectieve analyse kan uitvoeren.',
            onsite_volume_missing_tooltip: '',
            onsite_flood_zone:
                'Rock.estate heeft als doel jouw bank een objectieve waardebepaling van het onroerend goed te bezorgen.\nVoor het eigendom in kwestie heeft ons model vastgesteld dat het eigendom zich in een overstromingsgebied kan bevinden. Dit zou het moeilijk kunnen maken om te verzekeren. Als gevolg daarvan zijn wij niet in staat om een schatting op afstand uit te voeren.',
            onsite_flood_zone_tooltip: '',
            onsite_few_comparison:
                'Wij zijn niet in staat om een waardebepaling op te stellen. Ofwel omdat de waarde van de geplande werken te hoog is in verhouding tot de huidige waarde van het onroerend goed. Of omdat de geplande werkzaamheden meer dan 50.000 euro kosten.',
            onsite_few_comparison_tooltip: '',
            onsite_extensive_renovation:
                'Wij zijn niet in staat om een taxatie op te stellen. Ofwel omdat de waarde van de geplande werken te hoog is in verhouding tot de huidige waarde van het onroerend goed. Of omdat de geplande werkzaamheden meer dan 50.000 euro kosten.',
            onsite_extensive_renovation_tooltip: '',
        },
        common: {
            cancel: 'Annuleren',
            cancel_tooltip: '',
            save: 'Opslaan',
            save_tooltip: '',
            edit: 'Bewerken',
            edit_tooltip: '',
            total_tooltip: '',
            previous: 'Vorige',
            previous_tooltip: '',
            next: 'Volgende',
            next_tooltip: '',
            uploading_tooltip: '',
            thank_you_so_far: 'Bedankt om ons de nodige informatie te bezorgen',
            thank_you_so_far_tooltip: '',
            yes: 'Ja',
            yes_tooltip: '',
            no: 'Nee',
            no_tooltip: '',
            number_input: 'Vul de energiescore hier in',
            number_input_tooltip: '',
            multiple_choice: 'Selecteer \u00e9\u00e9n van de opties',
            multiple_choice_tooltip: '',
            we_are_sorry: 'Onze excuses.',
            we_are_sorry_tooltip: '',
            sorry: 'Oeps!',
            sorry_tooltip: '',
            enter_address: 'Vul het adres hier in',
            enter_address_tooltip: '',
            back_to_ing: 'Terug naar ING website',
            back_to_ing_tooltip: '',
            maintenance:
                'Deze website is momenteel in onderhoud. Graag verwelkomen we je snel terug!',
            maintenance_tooltip: '',
            send: 'Verzenden',
            send_tooltip: '',
            start_now: 'Start',
            start_now_tooltip: '',
            i_dont_know: 'Weet ik niet',
            i_dont_know_tooltip: '',
        },
        renovation_info: {
            title_tooltip: '',
            add_document_tooltip: '',
            unlink_document_tooltip: '',
            link_document_tooltip: '',
            items_tooltip: '',
            linked_items_tooltip: '',
        },
        renovation_item: {
            title_tooltip: '',
            amount_tooltip: '',
            category_tooltip: '',
            subcategory_tooltip: '',
            modal_title_tooltip: '',
        },
        renovation_category: {
            energy_tooltip: '',
            structural_tooltip: '',
            aesthetic_tooltip: '',
            other_tooltip: '',
        },
        renovation_subcategory: {
            insulation_tooltip: '',
            solar_panel_tooltip: '',
            heating_tooltip: '',
            other_tooltip: '',
            wall_extension_tooltip: '',
            demolition_tooltip: '',
            kitchen_tooltip: '',
            bathroom_tooltip: '',
        },
        steps: {
            building_type: 'Type woning',
            building_type_tooltip:
                'Gaat het om een \u00e9\u00e9ngezinswoning of een appartement?',
            address: 'Adres',
            address_tooltip: '',
            address_confirmation: 'Adres bevestiging',
            address_confirmation_tooltip:
                'Selecteer het adres van het gebouw dat u wilt renoveren.',
            building_selection: 'Gebouw',
            building_selection_tooltip: '',
            house_info: 'Woning informatie',
            house_info_tooltip: '',
            epc: 'EPC',
            epc_tooltip:
                'Het Energie Prestatie Co\u00ebfficient (EPC) is de energiescore van een woning. Deze info is doorgaans te vinden in de verkoopadvertentie en in het EPC certificaat. Het wordt uitgedrukt in kWh/m2jaar.',
            f_construction_year: 'Bouwjaar',
            f_construction_year_tooltip:
                'Het jaar waarin de woning officieel gebouwd werd. Dit houdt geen rekening met eventuele renovaties op een later tijdstip. Deze info is doorgaans te vinden in de verkoopadvertentie, het referentie bouwjaar in het EPC attest of de kadastrale legger.',
            living_area: 'Bewoonbare oppervlakte',
            living_area_tooltip:
                'De totale bewoonbare oppervlakte van de woning. Deze info is doorgaans te vinden in de advertentie van de woning of de bruikbare vloeroppervlakte in het EPC attest.',
            insulation: 'Isolatie',
            insulation_tooltip: '',
            walls: 'Muren',
            walls_tooltip: '',
            roof: 'Dak',
            roof_tooltip: '',
            floor: 'Vloer',
            floor_tooltip: '',
            windows: 'Ramen',
            windows_tooltip: '',
            attic: 'Zolder',
            attic_tooltip: '',
            basement: 'Kelder',
            basement_tooltip: '',
            consumption: 'Consumptie',
            consumption_tooltip: '',
            heating: 'Verwarming',
            heating_tooltip: '',
            electricity_consumption: 'Elektriciteitsverbruik',
            electricity_consumption_tooltip: '',
            gas_consumption: 'Gas verbruik',
            gas_consumption_tooltip: '',
            oil_consumption: 'Stookolie verbruik',
            oil_consumption_tooltip: '',
            coal_consumption: 'Kool verbruik',
            coal_consumption_tooltip: '',
            wood_consumption: 'Hout verbruik',
            wood_consumption_tooltip: '',
            pellets_consumption: 'Pellets verbruik',
            pellets_consumption_tooltip: '',
            district_heating_consumption: 'Warmtedistributie/stadsverwarming',
            district_heating_consumption_tooltip: '',
            solar_panels: 'Zonnepanelen',
            solar_panels_tooltip: '',
            ventilation: 'Ventilatie',
            ventilation_tooltip: '',
            consumers: 'Verbruikers',
            consumers_tooltip: '',
            electric_vehicles: 'Elektrische wagens',
            electric_vehicles_tooltip: '',
            processing: 'Berekenen',
            processing_tooltip: '',
        },
        property_type: {
            apartments: 'Appartementen',
            apartments_tooltip: '',
            coming_soon:
                'De tool werkt nog niet voor appartementen. Dat is voor binnenkort!',
            coming_soon_tooltip: '',
            select_type: 'Vul het adres hier in',
            select_type_tooltip: '',
            title: 'Wat is het type pand?',
            title_tooltip: '',
        },
        out_of_bounds_page: {
            title: 'We hebben geen gegevens gevonden voor dit adres',
            title_tooltip: '',
            content_tooltip: '',
            button: 'Probeer een ander adres',
            button_tooltip: '',
            ing_button: 'Keer terug naar de ING website',
            ing_button_tooltip: '',
        },
        terms_page: {
            welcome: 'n/a',
            welcome_tooltip: '',
            epc: 'n/a',
            epc_tooltip: '',
            disclaimer: 'n/a',
            disclaimer_tooltip: '',
        },
        features_question: {
            insulated_floors: 'Zijn de vloeren ge\u00efsoleerd?',
            insulated_floors_tooltip: '',
            insulated_roof: 'Is het dak ge\u00efsoleerd?',
            insulated_roof_tooltip: '',
            insulated_walls: 'Zijn de muren ge\u00efsoleerd?',
            insulated_walls_tooltip: '',
            has_attic: 'Heeft u een zolder?',
            has_attic_tooltip: '',
            insulation_floors_when:
                'Wanneer vond de meest recente verbetering in isolatie plaats?',
            insulation_floors_when_tooltip: '',
            insulation_roof_when:
                'Wanneer vond de meest recente verbetering in isolatie plaats?',
            insulation_roof_when_tooltip: '',
            insulation_walls_when:
                'Wanneer vond de meest recente verbetering in isolatie plaats?',
            insulation_walls_when_tooltip: '',
            has_basement: 'Is er een kelder?',
            has_basement_tooltip: '',
            is_attic_heated: 'Is de zolderruimte verwarmd?',
            is_attic_heated_tooltip: '',
            epc_known: 'Ken je de offici\u00eble EPC score van de woning?',
            epc_known_tooltip: '',
            select_heating_fuel: 'Selecteer eerst de brandstof voor verwarming',
            select_heating_fuel_tooltip: '',
            ventilation_type: 'Welk type ventilatiesysteem heeft de woning?',
            ventilation_type_tooltip: '',
            ventilation_subtype: 'Welk type ventilatiesysteem heeft de woning?',
            ventilation_subtype_tooltip: '',
            n_residents: 'Hoeveel personen leven in de woning?',
            n_residents_tooltip: '',
            protected_consumer: 'n/a',
            protected_consumer_tooltip: '',
            heating_consumption_approx:
                'Geef ons een schatting van uw verbruik voor verwarming',
            heating_consumption_approx_tooltip: '',
            electric_consumption_approx:
                'Geef ons een schatting van uw elektriciteitsverbruik',
            electric_consumption_approx_tooltip: '',
            installation_year: 'Wanneer werd dit ge\u00efnstalleerd?',
            installation_year_tooltip: '',
            heater: 'Welk type verwarming heeft u?',
            heater_tooltip: '',
            consumption_known: 'Kent u uw huidig energievebruik?',
            consumption_known_tooltip:
                'U kan deze informatie opzoeken op uw meest recente energiefactuur, of door de meterstanden te vergelijken. Opgelet, wij houden rekening met jaarlijkse waardes. Mogelijks dien je de waarde op de factuur om te rekenen naar een jaarlijkse verbruik.',
            yearly_electricity_consumption: 'Kent u uw huidig elektriciteitsvebruik?',
            yearly_electricity_consumption_tooltip:
                'U kan deze informatie opzoeken op uw meest recente elektriciteteitsfactuur, of door de meterstanden te vergelijken. Opgelet, wij houden rekening met jaarlijkse waardes. Mogelijks dien je de waarde op de factuur om te rekenen naar een jaarlijkse verbruik.',
            yearly_fuel_consumption: 'Kent u uw jaarlijks vebruik aan brandstof?',
            yearly_fuel_consumption_tooltip:
                'U kan deze informatie opzoeken op uw meest recente factuur aan brandstof of door de meterstanden te vergelijken. Opgelet, wij houden rekening met jaarlijkse waardes. Mogelijks dien je de waarde op de factuur om te rekenen naar een jaarlijkse verbruik.',
            yearly_heating_consumption: 'Kent u uw jaarlijks vebruik voor verwarming?',
            yearly_heating_consumption_tooltip: '',
            f_construction_year: 'In welk jaar werd de woning gebouwd?',
            f_construction_year_tooltip: '',
            f_living_area: 'Wat is de bewoonbare oppervlakte van de woning?',
            f_living_area_tooltip:
                'De totale bewoonbare oppervlakte van de woning. Deze info is doorgaans te vinden in de advertentie van de woning of de bruikbare vloeroppervlakte in het EPC attest.',
            solar_panels: 'Zijn er zonnepanelen aanwezig?',
            solar_panels_tooltip: '',
            insulated_windows: 'Welk type beglazing heeft de woning?',
            insulated_windows_tooltip: '',
            insulated_single_renovated:
                'Werd er betere isolatie geplaatst na het bouwen?',
            insulated_single_renovated_tooltip: '',
            insulated_plural_renovated:
                'Werd er betere isolatie geplaatst na het bouwen?',
            insulated_plural_renovated_tooltip: '',
            gas_consumption_known: 'Kent u uw jaarlijks gasverbruik?',
            gas_consumption_known_tooltip: '',
            oil_consumption_known: 'Kent u uw jaarlijks mazoutverbruik?',
            oil_consumption_known_tooltip: '',
            wood_consumption_known: 'Kent u uw jaarlijks hout verbruik?',
            wood_consumption_known_tooltip: '',
            coal_consumption_known: 'Kent u uw jaarlijks kool verbruik?',
            coal_consumption_known_tooltip: '',
            pellets_consumption_known: 'Kent u uw jaarlijks verbruik aan pellets?',
            pellets_consumption_known_tooltip: '',
            district_heating_consumption_known:
                'Kent u uw jaarlijks verbruik aan stadsverwarming?',
            district_heating_consumption_known_tooltip: '',
            electricity_consumption_known: 'Kent u uw jaarlijks elektriciteitsverbruik?',
            electricity_consumption_known_tooltip: '',
            gas_consumption_approx: 'Hoe zou u uw gasverbruik inschatten?',
            gas_consumption_approx_tooltip: '',
            oil_consumption_approx: 'Hoe zou u uw mazoutverbruik inschatten?',
            oil_consumption_approx_tooltip: '',
            wood_consumption_approx: 'Hoe zou u uw verbruik aan hout inschatten?',
            wood_consumption_approx_tooltip: '',
            coal_consumption_approx: 'Hoe zou u uw verbruik aan kolen inschatten?',
            coal_consumption_approx_tooltip: '',
            pellets_consumption_approx: 'Hoe zou u uw verbruik aan pellets inschatten?',
            pellets_consumption_approx_tooltip: '',
            district_heating_consumption_approx:
                'Hoe zou u uw verbruik aan stadsverwarming inschatten?',
            district_heating_consumption_approx_tooltip: '',
            electricity_consumption_approx:
                'Hoe zou u uw elektriciteitsverbruik inschatten?',
            electricity_consumption_approx_tooltip: '',
        },
        map_page: {
            '3d_model':
                'Gebaseerd op de informatie die we ontvingen, hebben we dit vereenvoudigd 3D model van de woning in kwestie kunnen genereren.',
            '3d_model_tooltip': '',
        },
        address_page: {
            title: 'Wat is het adres van het pand?',
            title_tooltip: '',
            control_address: 'Vervolledig het adres indien nodig',
            control_address_tooltip: '',
        },
        validation: {
            field_required: 'Dit veld is verplicht',
            field_required_tooltip: '',
            only_numbers: 'Gelieve enkel cijfers in te vullen',
            only_numbers_tooltip: '',
            min_4_characters: 'Dit veld vereist min. 4 karakters',
            min_4_characters_tooltip: '',
            wrong_epc: 'Gelieve een correct EPC label in te geven',
            wrong_epc_tooltip: '',
            valid_years: 'Tussen {min} en {max}',
            valid_years_tooltip: '',
            value_too_large: 'Het aantal is te groot',
            value_too_large_tooltip: '',
        },
        f_epc: {
            label: 'EPC label',
            label_tooltip: '',
        },
        features_page: {
            title: 'We hebben wat informatie over de woning nodig',
            title_tooltip: '',
        },
        consumption_page: {
            title: 'Kan u ons meer informatie geven omtrent uw energieverbruik?',
            title_tooltip: '',
            electric_vehicle: 'Heeft u een elektrische wagen?',
            electric_vehicle_tooltip: '',
        },
        login_page: {
            login: 'Login',
            login_tooltip: '',
        },
        welcome_page: {
            intro:
                'Ontdek welke de meest effici\u00ebnte investeringen zijn en een schatting van het budget. ',
            intro_tooltip: '',
            corpus_tooltip: '',
            disclaimer_tooltip: '',
            by_clicking: "Door op 'Start' te klikken, gaat u akkoord met onze",
            by_clicking_tooltip: '',
            terms: 'voorwaarden',
            terms_tooltip: '',
            curious: 'Benieuwd naar de EPC score van je (toekomstige) woning?',
            curious_tooltip: '',
        },
        error_page: {
            title: 'Er liep iets mis',
            title_tooltip: '',
            intro:
                'De service is momenteel niet beschikbaar. Probeer het later nog eens.',
            intro_tooltip: '',
            button: 'Terug naar de ING website',
            button_tooltip: '',
        },
        insulation: {
            title: 'Hoe is de isolatie van de woning?',
            title_tooltip: '',
        },
        consumption: {
            gas_consumption_title: 'Gasverbruik',
            gas_consumption_title_tooltip: '',
            oil_consumption_title: 'Mazoutverbruik',
            oil_consumption_title_tooltip: '',
            wood_consumption_title: 'Verbruik aan hout',
            wood_consumption_title_tooltip: '',
            coal_consumption_title: 'Verbruik aan kolen',
            coal_consumption_title_tooltip: '',
            pellets_consumption_title: 'Verbruik aan pellets',
            pellets_consumption_title_tooltip: '',
            district_heating_consumption_title: 'Verbruk aan stadsverwarming',
            district_heating_consumption_title_tooltip: '',
            electricity_consumption_title: 'Elektriciteitsverbruik',
            electricity_consumption_title_tooltip: '',
        },
        ventilation_page: {
            ventilation_title: 'Ventilatie',
            ventilation_title_tooltip: '',
        },
        consumers_page: {
            title: 'Geef ons meer informatie over uw energieverbruik.',
            title_tooltip: '',
        },
        energy_quizz: {
            solar: {
                qa: {
                    q:
                        'Volgens onze data is dit de beste manier om zonnepanelen te plaatsen. Gaat u hiermee akkoord?',
                    q_tooltip: '',
                    a1: 'Ja, ik ga akkoord',
                    a1_tooltip: '',
                    a2: 'Neen, dit is niet de zijde waarop het meeste zonlicht valt',
                    a2_tooltip: '',
                    a3: 'Neen, dit is niet de juiste oppervlakte',
                    a3_tooltip: '',
                },
                qb: {
                    q:
                        'U duidde aan dat u zonnepanelen heeft. Wij schatten dat er {x} zonnepanelen op het dak passen. Gaat u hiermee akkoord?',
                    q_tooltip: '',
                    a1: 'Ja, ik ga akkoord',
                    a1_tooltip: '',
                    a2: 'Neen, dat is niet mogelijk.',
                    a2_tooltip: '',
                    a3:
                        'Ja, maar we hebben beslist voor een ander aantal panelen te gaan.',
                    a3_tooltip: '',
                },
            },
            q1: {
                q: 'Welke stelling over zonnepanelen is correct?',
                q_tooltip: '',
                a1:
                    'Zonnepanelen hebben een gemiddelde terugverdientijd van 15 jaar. De gemiddelde levensduur van zonnepanelen is 25 jaar.',
                a1_tooltip: '',
                f1:
                    'Dat is niet juist. De gemiddelde terugverdientijd is 6-8 jaar. Het juiste antwoord is 2.',
                f1_tooltip: '',
                a2:
                    'Zonnepanelen verlagen de EPC score van de woning. Een lagere EPC score betekent een hogere waarde van de woning. ',
                a2_tooltip: '',
                f2: 'Super, dat is juist.',
                f2_tooltip: '',
                a3: 'Zonnepanelen kunnen niet ingezet worden om water te verwarmen.',
                a3_tooltip: '',
                f3:
                    'Dat is niet juist. Er zijn 2 manieren om water met zonnepanelen te verwarmen: via een zonneboiler of door de waterpomp aan te sturen met zonne-energie. Het juiste antwoord is 2.',
                f3_tooltip: '',
            },
            q2: {
                q:
                    'Wat is de juiste volgorde van renovaties om de energie effici\u00ebntie van een woning te verbeteren?',
                q_tooltip: '',
                a1: 'Muren - dak - ramen - vloeren - verwarming',
                a1_tooltip: '',
                f1: 'Dat is niet juist. Het juiste antwoord is 2.',
                f1_tooltip: '',
                a2: 'Dak - muren - ramen - vloeren - verwarming',
                a2_tooltip: '',
                f2: 'Super, dat is juist.',
                f2_tooltip: '',
                a3: 'Dak - muren - vloeren - ramen - verwarming',
                a3_tooltip: '',
                f3: 'Dat is niet juist. Het juiste antwoord is 2.',
                f3_tooltip: '',
                a4: 'Muren - dak - ramen - verwarming - vloeren',
                a4_tooltip: '',
                f4: 'Dat is niet juist. Het juiste antwoord is 2.',
                f4_tooltip: '',
            },
            q3: {
                q:
                    'Wat kan je doen wanneer je meer zonne-energie cre\u00ebert dan verbruikt?',
                q_tooltip: '',
                a1: 'Niets. Die gaat verloren.',
                a1_tooltip: '',
                f1:
                    'Dat is niet juist. Er zijn interessante manieren om alles uit zonne-energie te halen. Het juiste antwoord is 3.',
                f1_tooltip: '',
                a2: 'Alle energie opslaan voor wanneer je het nodig hebt',
                a2_tooltip: '',
                f2:
                    'Dat is niet juist. Je kan een batterij opladen met zonne-energie, maar niet alle stroom kan voor onbepaalde tijd worden opgeslagen. Het juiste antwoord is 3.',
                f2_tooltip: '',
                a3:
                    'Verkoop de overbodige energie, zoals aan een energie-leverancier of retailer',
                a3_tooltip: '',
                f3: 'Super, dat is juist.',
                f3_tooltip: '',
            },
            q4: {
                q:
                    'Wat is geen goede manier om de gas rekening te verlagen zonder renovatiewerken?',
                q_tooltip: '',
                a1:
                    'Plaats aluminium folie tussen radiatoren en niet-ge\u00efsoleerde buitenmuren',
                a1_tooltip: '',
                f1:
                    'Dat is niet juist. Folie tussen radiatoren en niet-ge\u00efsoleerde buitenmuren vermindert het warmteverlies. Het is een goede manier om het gasverbruik te verminderen. Het juiste antwoord is 3.',
                f1_tooltip: '',
                a2:
                    'Isoleer leidingen die lopen tussen de verwarmingsketel naar de radiatoren in kelder of zolder',
                a2_tooltip: '',
                f2:
                    'Dat is niet correct. Leidingen isoleren die lopen in ruimtes die je niet verwarmt is een goede manier om minder gas te verbruiken omdat dit het warmteverlies vermindert. Het juiste antwoord is 3.',
                f2_tooltip: '',
                a3: 'Verwarm met een elektrische radiator',
                a3_tooltip: '',
                f3:
                    'Super, dat is juist. Een elektrische verwarming is een grote energie-verbruiker. Dit kost niet alleen veel geld, maar het heeft ook een negatieve impact op het milieu.',
                f3_tooltip: '',
            },
            q5: {
                q: 'Welke maatregel vermindert de CO2-uitstoot van een woning het meest?',
                q_tooltip: '',
                a1: 'Gebruik geen droogkast',
                a1_tooltip: '',
                f1: 'Dat is niet juist. Het juiste antwoord is 2.',
                f1_tooltip: '',
                a2: 'Installeer vloerverwarming',
                a2_tooltip: '',
                f2: 'Super, dat is juist.',
                f2_tooltip: '',
                a3: 'Douch om de twee dagen',
                a3_tooltip: '',
                f3: 'Dat is niet juist. Het juiste antwoord is 2.',
                f3_tooltip: '',
            },
            q6: {
                q: 'Welke stelling is niet juist?',
                q_tooltip: '',
                a1: 'Vloerisolatie spaart meer energie dan muurisolatie',
                a1_tooltip: '',
                f1: 'Super, dat is juist.',
                f1_tooltip: '',
                a2: 'Dakisolatie spaart meer CO2- uitstoot dan zonnepanelen',
                a2_tooltip: '',
                f2:
                    'Dat is niet juist. Dakisolatie is beter om CO2-uitstoot te verminderen dan zonnepanelen. Het juiste antwoord is 1.',
                f2_tooltip: '',
                a3:
                    'Ramen vervangen heeft een grotere impact dan een nieuw verwarmingssysteem installeren',
                a3_tooltip: '',
                f3:
                    'Dat is niet juist. De kwaliteit van de ramen verbeteren kan meer energie sparen dan een nieuw verwarmingssysteem installeren. Het juiste antwoord is 1.',
                f3_tooltip: '',
            },
            discovery_phase: 'Ontdekkingsfase',
            discovery_phase_tooltip: '',
            discovery_description:
                'Goed gedaan! Je bent op weg om alles over energie te ontdekken. Blijf nieuwsgierig en blijf leren hoe je energie kunt besparen. Begin met het bekijken van de juiste antwoorden hieronder.',
            discovery_description_tooltip: '',
            learning_phase: 'Leerfase',
            learning_phase_tooltip: '',
            learning_description:
                'Doe zo voort! Je hebt kennis over hoe je jouw woning energie effici\u00ebnt maakt. Blijf leren en nieuwe manieren vinden om uw huis energie-zuinig te maken.',
            learning_description_tooltip: '',
            expert_phase: 'Expert fase',
            expert_phase_tooltip: '',
            expert_description:
                'Proficiat! Je bent een expert wanneer het gaat om jouw huis energie-zuinig te maken. Tijd om dit een realiteit te maken!',
            expert_description_tooltip: '',
        },
        ers_report: {
            language: 'NL',
            language_tooltip: '',
            header_title: 'Bedankt voor uw interesse.',
            header_title_tooltip: '',
            header_subtitle:
                'Op basis van de gegeven informatie hebben wij de huidige EPC-score gesimuleerd. Hoe beter de invoergegevens, hoe nauwkeuriger de simulatie zal zijn. Dit vervangt geen offici\u00eble EPC-score.',
            header_subtitle_tooltip: '',
            header_subtitle_pdf:
                'Op basis van de gegeven informatie hebben wij de huidige EPC-score gesimuleerd. Hoe beter de invoergegevens, hoe nauwkeuriger de simulatie zal zijn. Dit vervangt geen offici\u00eble EPC-score.',
            header_subtitle_pdf_tooltip: '',
            epc_graph_title: 'Huidige EPC score*',
            epc_graph_title_tooltip: '',
            consumption_title: 'Verbruik',
            consumption_title_tooltip: '',
            gas_title: 'Gas',
            gas_title_tooltip: '',
            electric_title: 'Elektriciteit',
            electric_title_tooltip: '',
            low_title: 'Laag',
            low_title_tooltip: '',
            high_title: 'Hoog',
            high_title_tooltip: '',
            average_title: 'Gemiddeld',
            average_title_tooltip: '',
            you_title: 'Uw',
            you_title_tooltip: '',
            current_epc: 'Huidige EPC score',
            current_epc_tooltip: '',
            future_epc: 'Toekomstige EPC score',
            future_epc_tooltip: '',
            summary_title: 'Samenvatting',
            summary_title_tooltip: '',
            summary_note:
                'De prijzen en subsidies hieronder zijn indicatief. Prijzen zijn inclusief BTW en kunnen veranderen gebaseerd op de selectie van renovaties.**',
            summary_note_tooltip: '',
            price_title: 'Prijs incl. BTW',
            price_title_tooltip: '',
            subsidy_title: 'Subs.',
            subsidy_title_tooltip: '',
            after_subsidy_title: 'Na subs.',
            after_subsidy_title_tooltip: '',
            total_title: 'Totaal',
            total_title_tooltip: '',
            calculate_button_text: 'Bereken mijn ING lening',
            calculate_button_text_tooltip: '',
            download_report: 'Download mijn rapport',
            download_report_tooltip: '',
            renovation_title: 'Uw Renovatie Doelstelling',
            renovation_title_tooltip: '',
            renovation_subtitle:
                'Wat is uw renovatie doelstelling? Selecteer een renovatie pakket om te ontdekken welke renovaties de meest effici\u00ebnte zijn om dit doe te bereiken. Of, selecteer renovatie opties om te ontdekken met welke EPC score ze overeenkomen.',
            renovation_subtitle_tooltip: '',
            renovation_price: 'Prijs',
            renovation_price_tooltip: '',
            renovation_subsidy: 'Subsidie',
            renovation_subsidy_tooltip: '',
            renovation_yearly: 'Jaarlijkse besparing',
            renovation_yearly_tooltip: '',
            renovation_windowsDouble: 'Beglazing',
            renovation_windowsDouble_tooltip: '',
            renovation_wallExt: 'Buitenmuur',
            renovation_wallExt_tooltip: '',
            renovation_wallCav: 'Spouwmuur ',
            renovation_wallCav_tooltip: '',
            renovation_roofIn: 'Binnenkant dak',
            renovation_roofIn_tooltip: '',
            renovation_roofExt: 'Buitenkant dak',
            renovation_roofExt_tooltip: '',
            renovation_roofIn_attic_floor: 'Zoldervloer',
            renovation_roofIn_attic_floor_tooltip: '',
            renovation_condGas: 'Ketel',
            renovation_condGas_tooltip: '',
            renovation_heatPump: 'Warmtepomp',
            renovation_heatPump_tooltip: '',
            renovation_ventC: 'Ventilatie type C',
            renovation_ventC_tooltip: '',
            renovation_solar: 'Zonnepanelen',
            renovation_solar_tooltip: '',
            compliance_pack_title: 'Goed begin',
            compliance_pack_title_tooltip: '',
            compliance_pack_desc:
                'Ik wil voldoen aan de wettelijke voorwaarden omtrent EPC score.',
            compliance_pack_desc_tooltip: '',
            budget_pack_title: 'Groen budget',
            budget_pack_title_tooltip: '',
            budget_pack_desc:
                'Ik wil een budget van x euro optimaliseren aan energetische renovaties',
            budget_pack_desc_tooltip: '',
            lowest_pack_title: 'BENovatie',
            lowest_pack_title_tooltip: '',
            lowest_pack_desc:
                'Ik wil een volledig energetisch renovatietraject voor de woning.',
            lowest_pack_desc_tooltip: '',
            custom_pack_title: 'Gepersonaliseerd pakket',
            custom_pack_title_tooltip: '',
            custom_pack_desc:
                'Ik wil mijn eigen combinatie van renovatietechnieken samenstellen',
            custom_pack_desc_tooltip: '',
            add_remove_renovations: 'Renovaties toevoegen of verwijderen',
            add_remove_renovations_tooltip: '',
            submit_button_budget: 'Indienen',
            submit_button_budget_tooltip: '',
            already_compilance:
                'Super! Jouw woning voldoet reeds aan de wettelijke voorwaarden. Klik op de andere paketten om te ontdekken wat je nog meer kan doen.',
            already_compilance_tooltip: '',
            no_renovation_found:
                'Geen renovaties gevonden. Gelieve een groen budget in te geven.',
            no_renovation_found_tooltip: '',
            renovation_subtitle_compliance_pdf:
                'Proficiat met uw Goede Begin! Met een budget van {amount} euro kan de EPC score van de woning verbeteren van een EPC score {current_epc_score} naar een EPC score {future_epc_score}.\n\nHieronder vind u het meest energie-effici\u00ebnte pakket dat u zal helpen dit doel te behalen evenals uw jaarlijkse besparingen en een idee van mogelijk subsidies.\n\nVoor meer informatie over subsidies kan u de volgende websites bezoeken:\n- Voor Vlaanderen, ga naar https://www.mijnbenovatie.be/nl/wat-waarom/benovatiepremies-2022/\n- Voor Walloni\u00eb, ga naar https://energie.wallonie.be/fr/primes.html?IDC=7015\n- Voor Brussel, ga naar https://renolution.brussels/nl',
            renovation_subtitle_compliance_pdf_tooltip: '',
            renovation_subtitle_budget_pdf:
                'Proficiat met uw Groen Budget! Uw renovatiedoel is om een budget van {amount} euro te optimaliseren. Deze investering kan de EPC score van jouw woning verbeteren van een EPC score {current_epc_score} naar een EPC score {future_epc_score}.\n\nHieronder vindt u het meest energie-effici\u00ebnte pakket dat u zal helpen dit doel te behalen evenals uw jaarlijkse besparingen en een idee van mogelijk subsidies.\n\nVoor meer informatie over subsidies kan u de volgende websites bezoeken:\n- Voor Vlaanderen, ga naar https://www.mijnbenovatie.be/nl/wat-waarom/benovatiepremies-2022/\n- Voor Walloni\u00eb, ga naar https://energie.wallonie.be/fr/primes.html?IDC=7015\n- Voor Brussel, ga naar https://renolution.brussels/nl',
            renovation_subtitle_budget_pdf_tooltip: '',
            renovation_subtitle_custom_pdf:
                'Proficiat met uw gepersonaliseerd pack! Met een budget van {amount} euro kan de EPC score van jouw woning verbeteren van een EPC score {current_epc_score} naar een EPC score {future_epc_score}.\n\nHieronder vind u het meest energie-effici\u00ebnte pakket dat u zal helpen dit doel te behalen evenals uw jaarlijkse besparingen en een idee van mogelijk subsidies.\n\nVoor meer informatie over subsidies kan u de volgende websites bezoeken:\n- Voor Vlaanderen, ga naar https://www.mijnbenovatie.be/nl/wat-waarom/benovatiepremies-2022/\n- Voor Walloni\u00eb, ga naar https://energie.wallonie.be/fr/primes.html?IDC=7015\n- Voor Brussel, ga naar https://renolution.brussels/nl',
            renovation_subtitle_custom_pdf_tooltip: '',
            disclaimer_title: 'Disclaimer',
            disclaimer_title_tooltip: '',
            disclaimer_body:
                '*De schatting van de huidige EPC score is gebaseerd op de informatie gegeven in het vragenformulier, in combinatie met data over het gebouw en technische eisen zoals vastgelegd in de offici\u00eble EPC software. Hoe correcter de doorgegeven informatie, des te accurater de simulatie is. De simulatie vervangt geen officieel EPC certificaat. Raadpleeg steeds een professional voor gepersonaliseerd advies. \n**Prijzen zijn inclusief 6 % of 21 % BTW afhankelijk van de doorgegeven situatie. Enige bijkomende werken die vereist zijn voor de renovatie zijn niet inbegrepen in de prijs. Wij updaten prijzen en subsidies op een regelmatige basis. Afhankelijk van wanneer u de simulator gebruikt, kan de uitkomst daarom verschillen. Subsidies kunnen verschillen afhankelijk van uw persoonlijke situatie. Raadpleeg steeds een professional voor gepersonaliseerd advies. ',
            disclaimer_body_tooltip: '',
            disclaimer_body_pdf:
                '*De schatting van de huidige EPC score is gebaseerd op de informatie gegeven in het vragenformulier, in combinatie met data over het gebouw en technische eisen zoals vastgelegd in de offici\u00eble EPC software. Hoe correcter de doorgegeven informatie, des te accurater de simulatie is. De simulatie vervangt geen officieel EPC certificaat. Raadpleeg steeds een professional voor gepersonaliseerd advies. \n**Prijzen zijn inclusief 6 % of 21 % BTW afhankelijk van de doorgegeven situatie. Enige bijkomende werken die vereist zijn voor de renovatie zijn niet inbegrepen in de prijs. Wij updaten prijzen en subsidies op een regelmatige basis. Afhankelijk van wanneer u de simulator gebruikt, kan de uitkomst daarom verschillen. Subsidies kunnen verschillen afhankelijk van uw persoonlijke situatie. Raadpleeg steeds een professional voor gepersonaliseerd advies. ',
            disclaimer_body_pdf_tooltip: '',
        },
        processing_page: {
            title_started: 'Uw simulatie wordt verwerkt',
            title_started_tooltip: '',
            title_finished: 'Uw simulatie is klaar!',
            title_finished_tooltip: '',
            title_failed: 'Er ging iets mis met uw simulatie',
            title_failed_tooltip: '',
            subtitle:
                'Terwijl wij uw resultaten verwerken, kunt u uw energiekennis testen met onze onderstaande quiz:',
            subtitle_tooltip: '',
            ing_button: 'Ga terug naar ING ',
            ing_button_tooltip: '',
            check_results: 'Bekijk de resultaten',
            check_results_tooltip: '',
            results_ready: 'Uw resultaten zijn klaar!',
            results_ready_tooltip: '',
            something_went_wrong: 'Er ging iets mis met uw simulatie',
            something_went_wrong_tooltip: '',
        },
    },
    'en-BE': {
        unit: {
            m: 'm',
            m_tooltip: '',
            m2: 'm\u00b2',
            m2_tooltip: '',
            m3: 'm\u00b3',
            m3_tooltip: '',
            'kwh/m2year': 'kWh/m\u00b2yr',
            'kwh/m2year_tooltip': '',
            euro: '\u20ac',
            euro_tooltip: '',
            'euro/month': '\u20ac/month',
            'euro/month_tooltip': '',
            'euro/m2': '\u20ac/m\u00b2',
            'euro/m2_tooltip': '',
            'kgco2/m2year': 'kgCO\u2082/m\u00b2yr',
            'kgco2/m2year_tooltip': '',
            kWh: 'kWh',
            kWh_tooltip: '',
            l: 'l',
            l_tooltip: '',
            kg: 'kg',
            kg_tooltip: '',
            'kWh/yr': 'kWh/year',
            'kWh/yr_tooltip': '',
            'l/yr': 'l/year',
            'l/yr_tooltip': '',
            'm3/yr': 'm\u00b3/year',
            'm3/yr_tooltip': '',
            'kg/yr': 'kg/year',
            'kg/yr_tooltip': '',
        },
        address: {
            full_address: 'Address',
            full_address_tooltip:
                'Select the address of the property that is being financed. Please do not confuse this with the address of possible other properties that are being used as additional collateral.',
            streetname: 'Street',
            streetname_tooltip: '',
            streetnumber: 'Number',
            streetnumber_tooltip: '',
            boxnumber: 'Box number',
            boxnumber_tooltip: '',
            postalcode: 'Postal code',
            postalcode_tooltip: '',
            municipality: 'City',
            municipality_tooltip: '',
        },
        valuation: {
            date: 'Valuation date',
            date_tooltip: '',
            type: 'Valuation type',
            type_tooltip: '',
            valuer: 'Performed by',
            valuer_tooltip: '',
            monitor: 'Monitored by',
            monitor_tooltip: '',
            market_value: 'Market value',
            market_value_tooltip: '',
            market_value_post_renovation: 'Expected market value after renovation',
            market_value_post_renovation_tooltip: '',
            forced_sale_value: 'Forced sale value',
            forced_sale_value_tooltip: '',
            rental_value: 'Monthly rental value',
            rental_value_tooltip: '',
            reconstruction_value: 'Reconstruction value',
            reconstruction_value_tooltip: '',
            index_value: 'Index',
            index_value_tooltip: '',
            index_type: 'Index type',
            index_type_tooltip: '',
            explanation_1:
                'The valuation of this property relies in part on a data-driven statistical model. Its result has been reviewed and fine-tuned by a real estate expert. This is a two-step process:',
            explanation_1_tooltip: '',
            explanation_2: 'Step 1: Estimate by valuation model',
            explanation_2_tooltip: '',
            explanation_3:
                'An initial estimate of the property\u2019s Market value is calculated by the model based on all Customer input and some of the other input parameters (cf. page 2). This valuation represents the average market value for a property with this location and characteristics.(*)',
            explanation_3_tooltip: '',
            explanation_4: 'Step 2: Review and fine-tuning by real estate expert',
            explanation_4_tooltip: '',
            explanation_5:
                "The real estate expert supervises the model valuation in Step 1 and subsequently factors in the remaining input parameters. This results in the Market value of the property (red line in graph). Depending on the corrections applied by the real estate expert, the Market value can be lower or higher compared to the initial estimate of the valuation model.\n\nThe chart below illustrates how the Market value of this property compares to other properties in the neighbourhoud. It always contextualizes the expert's review.",
            explanation_5_tooltip: '',
            explanation_6:
                'The Market value in this report is based on an expert-reviewed desktop valuation and attempts to give the best possible view on the property\u2019s current value. However, it remains an estimation where the following elements need to be taken into account:\n- Remote valuation: the expert has evaluated the property remotely and did not actually visit the property.\n- Model accuracy: the valuation relies, at least partially, on a statistical model which has a certain margin of error.\n- Level of subjectivity: even the best expert valuation is somewhat biased by a level of subjectivity.',
            explanation_6_tooltip: '',
        },
        value: {
            avm: 'Automatic valuation',
            avm_tooltip: '',
            dvm: 'Remote valuation',
            dvm_tooltip: '',
            ovm: 'On-site valuation',
            ovm_tooltip: '',
            new_loan: 'New loan application',
            new_loan_tooltip: '',
            revaluation: 'Revaluation',
            revaluation_tooltip: '',
            classic_tooltip: '',
            renovation_light_tooltip: '',
            renovation_full_tooltip: '',
            house: 'House',
            house_tooltip: 'Building with one single living entity',
            apartment: 'Apartment',
            apartment_tooltip: 'A living entity part of a larger building',
            annex: 'Annex',
            annex_tooltip: '',
            new_construction: 'New construction',
            new_construction_tooltip: '',
            construction_plot: 'Construction plot',
            construction_plot_tooltip: '',
            attached: 'Attached',
            attached_tooltip: '',
            semi: 'Semi detached',
            semi_tooltip: '',
            detached: 'Detached',
            detached_tooltip: '',
            yes: 'Yes',
            yes_tooltip: '',
            no: 'No',
            no_tooltip: '',
            traditional: 'Traditional',
            traditional_tooltip: '',
            modern: 'Modern',
            modern_tooltip: '',
            contemporary: 'Contemporary',
            contemporary_tooltip: '',
            mediterranean: 'Mediterranean',
            mediterranean_tooltip: '',
            other: 'Other',
            other_tooltip: '',
            ground_floor: 'Ground floor',
            ground_floor_tooltip: '',
            1: '1',
            '1_tooltip': '',
            2: '2',
            '2_tooltip': '',
            3: '3',
            '3_tooltip': '',
            4: '4',
            '4_tooltip': '',
            '>4': '>4',
            '>4_tooltip': '',
            private: 'Private garden',
            private_tooltip: '',
            common: 'Common garden',
            common_tooltip: '',
            'private+common': 'Private and common garden',
            'private+common_tooltip': '',
            maintenance_required: 'Maintenance required',
            maintenance_required_tooltip: '',
            good: 'Good',
            good_tooltip: '',
            very_good: 'Very good',
            very_good_tooltip: '',
            cinder_blocks: 'Cinder blocks',
            cinder_blocks_tooltip: '',
            wood: 'Wood',
            wood_tooltip: '',
            plaster: 'Plaster',
            plaster_tooltip: '',
            natural_stone: 'Natural stone',
            natural_stone_tooltip: '',
            metal: 'Metal',
            metal_tooltip: '',
            concrete: 'Concrete',
            concrete_tooltip: '',
            brick: 'Brick',
            brick_tooltip: '',
            tiles: 'Tiles',
            tiles_tooltip: '',
            bitumen_roofing: 'Bitumen roofing',
            bitumen_roofing_tooltip: '',
            slate_shingles: 'Slate shingles',
            slate_shingles_tooltip: '',
            black_concrete_tiles: 'Black concrete tiles',
            black_concrete_tiles_tooltip: '',
            thatched: 'Thatched roof',
            thatched_tooltip: '',
            asbestos: 'Asbestos',
            asbestos_tooltip: '',
            green_roof: 'Green roof',
            green_roof_tooltip: '',
            home_liberal_profession_or_office: 'Home, liberal profession or office',
            home_liberal_profession_or_office_tooltip: '',
            home_other_professional_activity: 'Other professional activity',
            home_other_professional_activity_tooltip: '',
            home: 'Home',
            home_tooltip: '',
            no_economic_activity: 'No economic activity',
            no_economic_activity_tooltip: '',
            activity_unlikely: 'Activity unlikely',
            activity_unlikely_tooltip: '',
            activity_possible: 'Activity possible',
            activity_possible_tooltip: '',
            activity_likely: 'Activity likely',
            activity_likely_tooltip: '',
            activity_very_likely: 'Activity very likely',
            activity_very_likely_tooltip: '',
            n: 'N',
            n_tooltip: '',
            ne: 'NE',
            ne_tooltip: '',
            e: 'E',
            e_tooltip: '',
            se: 'SE',
            se_tooltip: '',
            s: 'S',
            s_tooltip: '',
            sw: 'SW',
            sw_tooltip: '',
            w: 'W',
            w_tooltip: '',
            nw: 'NW',
            nw_tooltip: '',
            limited: 'Limited',
            limited_tooltip: '',
            details_previous_page: '(details on previous page)',
            details_previous_page_tooltip: '',
            low: 'Low',
            low_tooltip: '',
            medium: 'Medium',
            medium_tooltip: '',
            high: 'High',
            high_tooltip: '',
            unknown: 'Unknown',
            unknown_tooltip: '',
            urban: 'Urban',
            urban_tooltip: '',
            rural: 'Rural',
            rural_tooltip: '',
            isolated: 'Isolated',
            isolated_tooltip: '',
            city: 'City',
            city_tooltip: '',
            suburbs: 'Suburbs',
            suburbs_tooltip: '',
            village: 'Village',
            village_tooltip: '',
            none: 'None',
            none_tooltip: '',
            classified_facade: 'Classified facade',
            classified_facade_tooltip: '',
            green_neighbourhood: 'Green neighbourhood',
            green_neighbourhood_tooltip: '',
            sea_view: 'Sea view',
            sea_view_tooltip: '',
            no_direct_traffic: 'No direct traffic',
            no_direct_traffic_tooltip: '',
            exceptional_view: 'Exceptional view',
            exceptional_view_tooltip: '',
            tram: 'Tram',
            tram_tooltip: '',
            train: 'Train',
            train_tooltip: '',
            metro: 'Metro',
            metro_tooltip: '',
            traffic: 'Traffic',
            traffic_tooltip: '',
            busy_area: 'Busy area',
            busy_area_tooltip: '',
            abex: 'ABEX',
            abex_tooltip: '',
            single: 'Single',
            single_tooltip: '',
            ordinary_double: 'Ordinary double',
            ordinary_double_tooltip: '',
            hr_double_after_2000: 'High performance double (after 2000)',
            hr_double_after_2000_tooltip: '',
            hr_double_before_2000: 'High performance double (before 2000)',
            hr_double_before_2000_tooltip: '',
            triple: 'Triple',
            triple_tooltip: '',
            gas: 'Gas',
            gas_tooltip: '',
            oil: 'Oil',
            oil_tooltip: '',
            pellets: 'Pellets',
            pellets_tooltip: '',
            coal: 'Coal',
            coal_tooltip: '',
            district_heating: 'District heating',
            district_heating_tooltip: '',
            'boiler:condensing': 'Condensing Boiler',
            'boiler:condensing_tooltip': '',
            heat_pump: 'Heat pump',
            heat_pump_tooltip: '',
            'boiler:standard': 'Standard Boiler',
            'boiler:standard_tooltip': '',
            chp: 'Combined heat and power',
            chp_tooltip: '',
            stove: 'Stove',
            stove_tooltip: '',
            electricity: 'Electricity',
            electricity_tooltip: '',
            electric_heater: 'Electric heater (resistance heating)',
            electric_heater_tooltip: '',
            no_ventilation: 'No ventilation',
            no_ventilation_tooltip: '',
            natural: 'Natural',
            natural_tooltip: '',
            mechanical: 'Mechanical',
            mechanical_tooltip: '',
            system_b: 'System B',
            system_b_tooltip: '',
            system_c: 'System C',
            system_c_tooltip: '',
            system_cplus: 'System C+',
            system_cplus_tooltip: '',
            system_d: 'System D',
            system_d_tooltip: '',
        },
        request: {
            references: 'References',
            references_tooltip: '',
            valuation_request_ref: 'Valuation reference',
            valuation_request_ref_tooltip: '',
            customer_ref: 'Internal reference',
            customer_ref_tooltip:
                'This allows to link the valuation request with the loan.',
            bank: 'Bank',
            bank_tooltip: '',
            customer: 'Customer',
            customer_tooltip: '',
            purpose: 'Valuation purpose',
            purpose_tooltip: '',
            package_tooltip: '',
        },
        footer: {
            text: 'For more information, check out {url.rockestate_main}',
            text_tooltip: '',
        },
        url: {
            rockestate_main: 'https://www.rock.estate',
            rockestate_main_tooltip: '',
            dashboard: '/valuation/default/dashboard',
            dashboard_tooltip: '',
            rockestate_info: 'https://www.rock.estate/',
            rockestate_info_tooltip: '',
            valuation_app: '/valuation/default/',
            valuation_app_tooltip: '',
        },
        export: {
            by: 'Exported by',
            by_tooltip: '',
            date: 'Date',
            date_tooltip: '',
            reference: 'Reference',
            reference_tooltip: '',
        },
        section: {
            reconstruction_value: 'Reconstruction',
            reconstruction_value_tooltip: '',
            address_location: 'Address and location',
            address_location_tooltip: '',
            secondary_buildings: 'Secondary buildings',
            secondary_buildings_tooltip: '',
            economic_activity: 'Economic activity',
            economic_activity_tooltip: '',
            roof: 'Roof',
            roof_tooltip: '',
            transaction: 'Transactions',
            transaction_tooltip: '',
            general: 'General',
            general_tooltip: '',
            report: 'Report',
            report_tooltip: '',
            'valuation-app': 'Application',
            'valuation-app_tooltip': '',
            dashboard: 'Dashboard',
            dashboard_tooltip: '',
            'faq-search-results': 'Search results',
            'faq-search-results_tooltip': '',
            warnings: 'Warnings',
            warnings_tooltip: '',
            renovation: 'Planned renovation',
            renovation_tooltip: '',
            general_info: 'General info',
            general_info_tooltip: '',
            parcels_and_buildings: 'Parcels and buildings',
            parcels_and_buildings_tooltip: '',
            c_parcels: 'Land',
            c_parcels_tooltip: '',
            c_building: 'Main building',
            c_building_tooltip: '',
            building: 'Building',
            building_tooltip: '',
            building_structure: 'Building structure',
            building_structure_tooltip: '',
            building_purpose_classification: 'Building purpose & classification',
            building_purpose_classification_tooltip: '',
            parcels: 'Parcel(s)',
            parcels_tooltip: '',
            surroundings: 'Surroundings',
            surroundings_tooltip: '',
            valuations: 'Valuations',
            valuations_tooltip: '',
            reference_properties_location: 'Reference properties: location',
            reference_properties_location_tooltip: '',
            reference_properties_characteristics: 'Reference properties: characteristics',
            reference_properties_characteristics_tooltip: '',
            flood: 'Flood zones',
            flood_tooltip: '',
            energy: 'Energy',
            energy_tooltip: '',
            flood_simple: 'Flood zones',
            flood_simple_tooltip: '',
            faq: 'Frequently asked questions',
            faq_tooltip: '',
            questions_feedback: 'Questions and feedback',
            questions_feedback_tooltip: '',
            contact_info: 'Contact information',
            contact_info_tooltip: '',
            disclaimer: 'Disclaimer',
            disclaimer_tooltip: '',
            heating: 'Heating',
            heating_tooltip: '',
            ventilation: 'Ventilation',
            ventilation_tooltip: '',
            renewables: 'Renewables',
            renewables_tooltip: '',
            additional_consumption: 'Additional consumption',
            additional_consumption_tooltip: '',
            consumers: 'Consumers',
            consumers_tooltip: '',
            house_info: 'House information',
            house_info_tooltip: '',
            consumption: 'Consumption',
            consumption_tooltip: '',
            property_type: 'Building type',
            property_type_tooltip: '',
            address: 'Address confirmation',
            address_tooltip: '',
            insulation: 'Insulation',
            insulation_tooltip: '',
            house_information: 'House information',
            house_information_tooltip: '',
        },
        front: {
            title: 'Property valuation report',
            title_tooltip: '',
            core_title: 'Property report',
            core_title_tooltip: '',
        },
        features: {
            renovation_cost: 'Renovation cost (excl VAT)',
            renovation_cost_tooltip: '',
            f_building_type: 'Building type',
            f_building_type_tooltip:
                'Is the Borrower financing a single-family home or an apartment?',
            f_number_of_facades: 'Number of facades',
            f_number_of_facades_tooltip: '',
            f_parcel_area: 'Total parcel area',
            f_parcel_area_tooltip: '',
            f_building_area: 'Building footprint area',
            f_building_area_tooltip: '',
            f_others: 'Other',
            f_others_tooltip: '',
            f_x_annexes: 'Secondary buildings',
            f_x_annexes_tooltip: '',
            f_annexes_area: 'Total area of secondary buildings',
            f_annexes_area_tooltip: '',
            f_area_largest_annex: 'Largest secondary building',
            f_area_largest_annex_tooltip: '',
            parcel_ids: 'Cadastral references',
            parcel_ids_tooltip: '',
            f_lng: 'Longitude',
            f_lng_tooltip: '',
            f_lat: 'Latitude',
            f_lat_tooltip: '',
            f_number_of_addresses: 'Number of addresses',
            f_number_of_addresses_tooltip: '',
            f_living_area: 'Living area',
            f_living_area_tooltip:
                'Total living area of the home. This info can usually be found in the sales ad.',
            f_expected_living_area: '(Expected) living area',
            f_expected_living_area_tooltip: 'Total expected living area of the home.',
            f_approx_living_area: 'Approximate living area',
            f_approx_living_area_tooltip: '',
            n_rooms: 'Number of rooms',
            n_rooms_tooltip: '',
            approx_n_rooms: 'Approximate number of rooms',
            approx_n_rooms_tooltip: '',
            transaction_value: 'Transaction value',
            transaction_value_tooltip: '',
            transaction_date: 'Transaction date',
            transaction_date_tooltip: '',
            approx_price_sqm: 'Approximate price',
            approx_price_sqm_tooltip: '',
            co2_sqm: 'Greenhouse gas emission',
            co2_sqm_tooltip: '',
            co2_label: 'Greenhouse gas emission',
            co2_label_tooltip: '',
            f_approx_epc: 'Approximate energy consumption',
            f_approx_epc_tooltip: '',
            f_construction_year: 'Construction year',
            f_construction_year_tooltip:
                'The official year of construction. This does not take into account any renovation works that may have followed at a later point in time. This info can usually be found in the sales ad.',
            f_expected_construction_year: '(Expected) construction year',
            f_expected_construction_year_tooltip:
                'The actual or expected year of construction.',
            f_epc: 'EPC',
            f_epc_tooltip:
                'The Energy Performance Coefficient (EPC) is the energy score of a home. This info can usually be found in the sales ad.',
            f_epc_label: 'EPC',
            f_epc_label_tooltip: '',
            f_epc_numeric: 'EPC',
            f_epc_numeric_tooltip: '',
            f_expected_epc: '(Expected) EPC',
            f_expected_epc_tooltip: 'The (expected) EPC value after construction',
            f_bedrooms: 'Number of bedrooms',
            f_bedrooms_tooltip:
                'Total number of rooms that could serve as bedroom. Kitchen, bathroom and living room should not be included.',
            f_floor: 'Floor',
            f_floor_tooltip:
                'The floor on which the apartment is located. If multiple, select the lowest.',
            f_bottom_floor: 'Bottom floor of the building',
            f_bottom_floor_tooltip:
                'The bottom floor of a building is either 0 or a negative number depending on the number of underground floors. \nAssign the value 0 if the building has no floor under the ground/earth level. \nAssign a negative value if the building has a basement/ underground garage/\u2026',
            f_top_floor: 'Top floor of the building',
            f_top_floor_tooltip:
                'The top floor of a building is the floor under the roof of the building. This does not include any unfinished attic.',
            f_floors: 'Floor(s) of the apartment',
            f_floors_tooltip:
                'Select the floor(s) the apartment is located on in the list generated.\nFor example: for a duplex located on floors 2 and 3, select both floors.',
            f_balcony_present: 'Terrace',
            f_balcony_present_tooltip:
                "If the apartment has at least one terrace with sufficient space to put a 4-person table you can answer 'Yes'.",
            f_garage_present: 'Garage',
            f_garage_present_tooltip:
                'The apartment can give access to one or multiple parking spaces. This includes both a simple parking spot as well as a garage box, inside or outside.',
            f_n_closed_garage: 'Number of closed garages',
            f_n_closed_garage_tooltip:
                'A closed garage is a box garage that can be indoor or outdoor. Enter the number of closed garages belonging to the assessed apartment.',
            f_n_parking_spot: 'Number of parking spots',
            f_n_parking_spot_tooltip:
                'A parking spot is an outdoor or indoor parking place that is delineated by surface markings and not walls (vs. closed garages).\nEnter the number of parking spots sold with the property assessed.',
            f_cellar_attic: 'Cellar and/or attic',
            f_cellar_attic_tooltip: '',
            f_garden_access: 'Garden',
            f_garden_access_tooltip:
                'The apartment can have access to a private or common garden.',
            f_garden_common: 'Access to a common garden',
            f_garden_common_tooltip:
                'A common garden is only accessible to any tenants of the building where the property is assessed. If there is a common garden, does the apartment assessed give access to it? If it does, select yes.',
            f_garden_private: 'Access to a private garden',
            f_garden_private_tooltip:
                'A private garden is restricted to the tenants of the assessed apartment and is adjacent to the apartment. The difference between a garden and a terrace is that a garden has grass.',
            f_volume: 'Main building volume',
            f_volume_tooltip: '',
            f_roof_area: 'Roof area',
            f_roof_area_tooltip: '',
            f_mean_tilt: 'Mean roof angle',
            f_mean_tilt_tooltip: '',
            f_percent_of_roof_flat: 'Flat roof proportion',
            f_percent_of_roof_flat_tooltip: '',
            f_min_height: 'Minimum roof height',
            f_min_height_tooltip: '',
            f_max_height: 'Maximum roof height',
            f_max_height_tooltip: '',
            f_n_parts: 'Parts of the main building',
            f_n_parts_tooltip: '',
            f_n_roof_panes: 'Roof panes',
            f_n_roof_panes_tooltip: '',
            f_x_matched_entities: 'Known entities at this address',
            f_x_matched_entities_tooltip: '',
            c_economic_activity_class: 'Economic activity class',
            c_economic_activity_class_tooltip: '',
            c_economic_activity_level: 'Economic activity level',
            c_economic_activity_level_tooltip: '',
            f_garden_area: 'Garden area',
            f_garden_area_tooltip: '',
            f_distance_to_street: 'Distance to middle street',
            f_distance_to_street_tooltip: '',
            f_dist_building_flood: 'Nearest flood zone',
            f_dist_building_flood_tooltip: '',
            f_dist_building_flood_insurance_exclusion:
                'Nearest flood zone\n(insurance exclusion)',
            f_dist_building_flood_insurance_exclusion_tooltip: '',
            f_flood_risk: 'Flood risk',
            f_flood_risk_tooltip: '',
            f_flood_risk_insurance_exclusion: 'Flood risk\n(insurance exclusion)',
            f_flood_risk_insurance_exclusion_tooltip: '',
            f_flood_risk_composite: 'Composite flood risk',
            f_flood_risk_composite_tooltip: '',
            level: 'Level of information',
            level_tooltip: '',
            f_ground_height_above_sea: 'Altitude (above sea level)',
            f_ground_height_above_sea_tooltip: '',
            property_type: 'Building type',
            property_type_tooltip: '',
            insulated_floors: 'Insulated floors',
            insulated_floors_tooltip: '',
            insulated_roof: 'Insulated roof',
            insulated_roof_tooltip: '',
            insulated_walls: 'Insulated walls',
            insulated_walls_tooltip: '',
            window_type: 'Windows insulation',
            window_type_tooltip: '',
            has_attic: 'Attic',
            has_attic_tooltip: '',
            floors_insulation_years: 'Floors insulation year',
            floors_insulation_years_tooltip: '',
            roof_insulation_year: 'Roof insulation year',
            roof_insulation_year_tooltip: '',
            walls_insulation_year: 'Walls insulation year',
            walls_insulation_year_tooltip: '',
            has_basement: 'Basement',
            has_basement_tooltip: '',
            is_attic_heated: 'Attic heated',
            is_attic_heated_tooltip: '',
            epc_score: 'EPC score',
            epc_score_tooltip: '',
            heating_fuel: 'Heating fuel',
            heating_fuel_tooltip: '',
            heating_type: 'Heating type',
            heating_type_tooltip: '',
            heating_installation_year: 'Heating installation year',
            heating_installation_year_tooltip: '',
            ventilation_type: 'Type of ventilation',
            ventilation_type_tooltip: '',
            solar_boiler: 'Solar boiler',
            solar_boiler_tooltip: '',
            solar_panels: 'Solar panels',
            solar_panels_tooltip: '',
            battery: 'Battery',
            battery_tooltip: '',
            air_conditioner: 'Air conditioner',
            air_conditioner_tooltip: '',
            spa_room: 'Spa room',
            spa_room_tooltip: '',
            swimming_pool: 'Swimming pool',
            swimming_pool_tooltip: '',
            electric_vehicle: 'Electric cars',
            electric_vehicle_tooltip: '',
            n_residents: 'Number of people living in the house',
            n_residents_tooltip: '',
            protected_consumer: 'Protected consumer?',
            protected_consumer_tooltip: '',
            yearly_electricity_consumption: 'Yearly electric consumption',
            yearly_electricity_consumption_tooltip: '',
            gas_consumption_approx: 'Yearly gas consumption',
            gas_consumption_approx_tooltip: '',
        },
        disclaimer: {
            avm_tooltip: '',
            dvm:
                'This valuation report is the result of a remote assessment of the property by a real estate valuer. The valuer did not visit the property but relied on various data sources and a statistical model to make an independent valuation of the property. For more information regarding this valuation approach, please refer to the final page of this report.',
            dvm_tooltip: '',
            ovm:
                'This valuation report is the result of an on-site assessment of the property by a real estate expert. The independent valuation has been based on the property situation observed by the expert, and is further supported by a statistical model. For more information regarding this valuation approach, please refer to the final page of this report.',
            ovm_tooltip: '',
            part_1:
                'This valuation has been completed for the purpose expressed herein, no responsibility is accepted in the event of this report being used for any other purpose or by any other party than to whom it is addressed. Should any other party wish to use or rely upon this report for any purpose they must first obtain our written consent.\n',
            part_1_tooltip: '',
            part_2:
                'The publication of this report, in whole or in part, is prohibited without the written approval of Rock.estate. The copyright of this report at all times remains the property of Rock.estate. This report may not be assigned to a third party without the express consent of the owner of the copyright.',
            part_2_tooltip: '',
            reconstruction_value_title: 'Reconstruction value computation',
            reconstruction_value_title_tooltip: '',
            reconstruction_value:
                'Reconstruction value is the total cost to reconstruct a building considering the current construction requirements. The calculation includes VAT and cost of an architect, is independent on the location, and does not include demolishment works nor secondary buildings. As a result, it is possible to have a reconstruction value \nhigher than the market value. The reconstruction value is based on an ABEX index of 847.',
            reconstruction_value_tooltip: '',
            part_3:
                'In making this valuation report we express no opinion as to, and accept no liability for, the ability and willingness of the proposed mortgagor to meet his/her/its commitments under the proposed loan.',
            part_3_tooltip: '',
        },
        info: {
            data_from_customer: 'data input by Customer',
            data_from_customer_tooltip: '',
        },
        dvm_features: {
            style_of_house: 'Building style',
            style_of_house_tooltip: '',
            exterior_state: 'Exterior state of the property',
            exterior_state_tooltip: '',
            facade_material: 'Facade material',
            facade_material_tooltip: '',
            roof_material: 'Roof material',
            roof_material_tooltip: '',
            n_roof_windows: 'Number of roof windows',
            n_roof_windows_tooltip: '',
            n_roof_dormers: 'Number of dormers',
            n_roof_dormers_tooltip: '',
            solar_panel_area: 'Solar panel area',
            solar_panel_area_tooltip: '',
            f_building_listed: 'Listed for historical purposes',
            f_building_listed_tooltip: '',
            garden_orientation: 'Orientation of garden',
            garden_orientation_tooltip: '',
            garden_arrangement: '(Potential of) garden arrangement',
            garden_arrangement_tooltip: '',
            f_swimming_pool: 'Exterior swimming pool',
            f_swimming_pool_tooltip: '',
            f_swimming_pool_area: 'Exterior swimming pool size',
            f_swimming_pool_area_tooltip: '',
            direct_traffic_level: 'Level of direct street traffic',
            direct_traffic_level_tooltip: '',
            indirect_traffic_level: 'Proximity of indirect street traffic',
            indirect_traffic_level_tooltip: '',
            surroundings: 'Description of the surroundings',
            surroundings_tooltip: '',
            other_positive: 'Other elements positively impacting the property valuation',
            other_positive_tooltip: '',
            other_negative: 'Other elements negatively impacting the property valuation',
            other_negative_tooltip: '',
            remarks: 'Remarks of the valuer',
            remarks_tooltip: '',
            internal_remarks: 'Internal remarks',
            internal_remarks_tooltip: '',
        },
        '': {
            'clarify here': 'Economic activity',
            'clarify here_tooltip': '',
        },
        fixed: {
            parcels_and_building_shapes: 'Parcel and building shapes',
            parcels_and_building_shapes_tooltip: '',
        },
        footnote: {
            no_renovation_included:
                'All values are computed for the current state of the property and do not take into account any planned renovations',
            no_renovation_included_tooltip: '',
            avm: 'Automatic valuation',
            avm_tooltip: '',
            q25: '25th percentile',
            q25_tooltip: '',
            q75: '75th percentile',
            q75_tooltip: '',
        },
        reference_properties_location: {
            explanation_1:
                'The valuation of this property relies on {num_references} reference properties which have been selected in the property\u2019s neighbourhood (within a distance of {reference_radius} km). Each reference property shares a number of similarities in terms of location, size and other characteristics with the property under valuation. The underlying statistical model of this valuation is based on a Geographically Weighted Regression (GWR) where reference properties in the immediate proximity of the valued property receive a larger weighting compared to reference properties which are located further away.',
            explanation_1_tooltip: '',
            explanation_2:
                'The map below gives on overview of the location of the reference properties and their respective distance versus the valued property. The color gradient on the map gives a good indication of the average market value of properties in the area.',
            explanation_2_tooltip: '',
        },
        reference_properties_characteristics: {
            explanation_1:
                'A property has various objective characteristics that contribute to its price, such as location, size and energy efficiency. The charts below compare some of these characteristics of this property with those of the reference properties.',
            explanation_1_tooltip: '',
            property_characteristics: 'Property characteristics',
            property_characteristics_tooltip: '',
            properties_in_the_area: 'Properties in the area',
            properties_in_the_area_tooltip: '',
        },
        flood: {
            explanation_1:
                'Overview of the official flood zones in the proximity of the property. Several categories of flood risk are calculated according to different flood risk scenarios, varying from Low to High.',
            explanation_1_tooltip: '',
        },
        faq: {
            q_incorrect_valuation:
                'The address of the valued property is incorrect, what should I do?',
            q_incorrect_valuation_tooltip: '',
            a_incorrect_valuation:
                'It seems that something must have gone wrong when selecting the address, the building or the parcels related to the property. You can review the address information via the following link: {url.dashboard}.',
            a_incorrect_valuation_tooltip: '',
            q_incorrect_characteristics:
                'The address of the valued property is correct, but some of the other characteristics in this valuation report are incorrect, what should I do?',
            q_incorrect_characteristics_tooltip: '',
            a_incorrect_characteristics:
                'If you believe that some information  in this report may substantially differ from the actual situation, which could lead to a material impact on the valuation of the property, follow this link {url.dashboard} to review and clarify the incorrect/missing information.',
            a_incorrect_characteristics_tooltip: '',
            q_transaction_vs_market_value:
                'Why is the transaction price I paid different from the market value determined in this report?',
            q_transaction_vs_market_value_tooltip: '',
            a_transaction_vs_market_value_dvm:
                'This valuation report is the result of the remote assessment of the property in question by a real estate expert, supported by a data-driven statistical model. The report should give an accurate indication of the expected value of the property, based on the information at hand. Since the valuer did not actually visit the property, it can happen that the impact of certain, specific, property characteristics has not been fully reflected in the estimated transaction price. Please have a look at our information page via the following link: {url.rockestate_info}.',
            a_transaction_vs_market_value_dvm_tooltip: '',
            a_transaction_vs_market_value_ovm:
                'This valuation report is the result of the assessment of a real estate expert, supported by a data-driven statistical model. The report should give an accurate indication of the expected value of the property, based on the information at hand. Since the valuer did actually visit the property, the impact of certain, specific, property characteristics should be fully reflected in the estimated transaction price. Please have a look at our information page via the following link: {url.rockestate_info}.',
            a_transaction_vs_market_value_ovm_tooltip: '',
            q_loan_accepted:
                'Now that I have received my valuation report, does that mean my loan application was accepted?',
            q_loan_accepted_tooltip: '',
            a_loan_accepted:
                'We have shared this valuation report with you and your bank. This valuation report will be used by the bank as one of the elements in its credit risk approval process. The final decision remains theirs. Rock.estate is not involved in any way in this decision.',
            a_loan_accepted_tooltip: '',
            'where-bryan_tooltip': '',
            'where-bryan_question': 'Where is Bryan?',
            'where-bryan_answer': 'At the beach.',
            'internet-down_tooltip': '',
            'internet-down_question': 'Can you help me please? My internet is down',
            'internet-down_answer': 'Have you tried\n\n- Turning it off\n- And on again?',
            'where-dog_tooltip': '',
            'where-dog_question': 'Have you seen my dog?',
            'where-dog_answer':
                '![Shiba](https://www.meme-arsenal.com/memes/3cd52c228bb8aff4714b1ea387263803.jpg)\n\nNow you have',
            'what-is-avm_tooltip': '',
            'what-is-avm_question': 'What is an automatic valuation? \n',
            'what-is-avm_answer':
                'A Statistical Valuation Method is any form of mathematical tool that can be used to estimate a property value through deterministic computations.\n\nRock.estate uses an implementation of a Comparables-Based Automated Valuation Model (CBAVM or simply AVM). Comparables-based AVMs employ sophisticated algorithms to select comparable assets and calculate value estimates for a specified property at a specified date using mathematical modelling techniques in an automated manner. \n\nAutomatic Valuations are by design automatic: they are performed remotely without any human intervention.\n',
            'what-is-dvm_tooltip': '',
            'what-is-dvm_question': 'What is a remote valuation? ',
            'what-is-dvm_answer':
                'A Desktop valuation is performed by a valuer, remotely, without visiting the interior of the property. The valuer will rely on various data sources, a statistical model and possible information provided by the Borrower.\n\n\nA successful desktop valuation combines the best of both worlds: a strong data-driven model, supervised by a valuer to correct and complete the model. This is a valid alternative for on-site valuations for the bulk of residential real estate.',
            'what-is-ovm_tooltip': '',
            'what-is-ovm_question': 'What is an on-site valuation? ',
            'what-is-ovm_answer':
                'An On-site valuation is the traditional valuation where a valuer visits the interior and exterior of a property to estimate a property value. This should offer the most complete view on the value of a property. It is also the most resource intensive valuation alternative.',
            'what-is-dashboard_tooltip': '',
            'what-is-dashboard_question': 'What is the Dashboard?',
            'what-is-dashboard_answer':
                'The Dashboard is the place where you can have access to all your requests and be informed of their current status; submitted, still in draft, valued by our valuation specialists or if a request is recommended to be valued on site.\n\nThis place allows you to:\n- have access to a request not submitted for valuation yet\n- see the information filled of a request already submitted\n- download the valuation report if the request has been reviewed by a valuation specialist\n- take ownership of a request not yet completed by another member of your team (if applicable) ',
            'what-is-draft_tooltip': '',
            'what-is-draft_question':
                'What happens when my request is under the status "Draft"?',
            'what-is-draft_answer':
                'There are two types of scenarios when a request is under the status \u2018draft\u2019. They both need actions from the applicant. \n1)The mortgage advisor is waiting on missing information from their client regarding the property\n2)The valuation specialist has found some incoherent information that needs a correction.',
            'market-price-incorrect_tooltip': '',
            'market-price-incorrect_question':
                'The market price in the report is incorrect',
            'market-price-incorrect_answer':
                'If the market value given by the valuation specialist does not match to the transaction price of the loan request, please make sure that the assessed property is the correct one and that the inputs the applicant filled when submitting their request are accurate. If the inputs are correct, reach out to your manager to know what are the next steps to take. If after a review you still think that the deviation is too large, please get in touch with us through our contact form to get some additional info.',
            'issue-parcels_tooltip': '',
            'issue-parcels_question':
                'The parcels shown in the report, do not match with the property',
            'issue-parcels_answer':
                'If the parcel(s) selected for the valuation is(are) not correct, this might come from a wrong selection during the data entry process. \nKindly follow the easy steps below to primarly verify the data filled when creating the request:\n- Log in to Rock.estate Valuation Application\n- Click on the button "Dashboard"\n- In the search toolbar, fill the valuation request with the wrong parcel area\n- If you see the request click on the button "see my request" on the right side\nYou should be able to review the input given\n\nIf the input is correct, please inform us via our contact form as it is an important information impacting the value of the property.',
            'old-picture-or-3d_tooltip': '',
            'old-picture-or-3d_question':
                'The property has significantly changed compared to the pictures or 3D model in the valuation report',
            'old-picture-or-3d_answer':
                'If you realise that the valuation was based on old pictures of the property that do not match reality anymore then please reach out via our contact form present at the bottom of the page. ',
            'report-language_tooltip': '',
            'report-language_question': 'How can I get the report in an other language?',
            'report-language_answer':
                "To get the report in another language: \n- Go on your Rock.estate Valuation Application\n- Go on your Dashboard\n- In the right corner, select the language you'd like to get for the report\n\nYou can now download the report in the right language!\n\nPlease, do note that the remark from our valuation specialist won't be translated as it is manual input filled by a human at the time of the valuation.",
            'wrong-pictures_tooltip': '',
            'wrong-pictures_question':
                'The photo on the valuation report does not correspond to the assessed building.',
            'wrong-pictures_answer':
                'The street view on the report is selected by Google and sometimes does not represent the assessed property. It does not mean that the valuer assessed the wrong property. If you have access to the web report, the street view image is actually interactive and you can move in the street.',
            sharing_tooltip: '',
            sharing_question: 'Can I share this valuation report with my client?',
            sharing_answer:
                'This is related to the internal rules applied by your company. \nWe do recommend to discuss with your direct manager in order to confirm if the valuation report can be shareable.',
            'remarks-language_tooltip': '',
            'remarks-language_question':
                'Why do the remarks in the valuation report not change accordingly to the language of the dashboard if the rest of the report does?',
            'remarks-language_answer':
                "When an Applicant request a valuation in a specific language (e.g: French), the remark will be written either in the selected language or English. If the applicant change the language after submitting its request, the language of the remarks won't changed accordingly as it is not automated. ",
            'appeal-missing-feature_tooltip': '',
            'appeal-missing-feature_question':
                'The valuation specialist did not take into account one element (solar panels, extra parcel, swimming pool,...)',
            'appeal-missing-feature_answer':
                'If the valuation specialist did not take into account an element that could potentially impact the value of the property, please reach out via our contact form to let us know.\nElement considered as potentially important are the following:\n- Address\n- Building area\n- Parcel area\n- EPC\n- Living area\n- Construction year\n- Number of bedrooms\n- Apartment floor(s)\n- Presence of a private or common garden\n- Terrace\n- External Swimming pool\n- Solar panel(s)\n- Historical classification of the property (fa\u00e7ade or full buiding)\n- Flood zones\n- Secondary buildings\n- Presence of parking or closed garage',
            'where-customer-ref_tooltip': '',
            'where-customer-ref_question':
                'Where can I find the internal reference of the loan?',
            'where-customer-ref_answer':
                'This reference is internal to the bank and we suggest you to copy/paste this reference from your loan origination/creation system.',
            'building-type-apartment-building_tooltip': '',
            'building-type-apartment-building_question':
                'How can I request a valuation for an apartment buidling?',
            'building-type-apartment-building_answer':
                'Rock.estate does not value Apartment buildings. A request of this kind will result in an on-site valuation and will not be valuated.',
            'address-not-found_tooltip': '',
            'address-not-found_question':
                "Rock.estate's valuation application does not recognize the address of the financed property.",
            'address-not-found_answer':
                'If the auto-complete does not work and the application cannot find the address, you can enter it manually by: \n- Return on your valuation request\n- In the Building & Parcel selection page click on "I cannot find the main building"\n- Follow the instructions',
            'building-not-found_tooltip': '',
            'building-not-found_question':
                'The building automatically selected is not the right building. What should I do?',
            'building-not-found_answer':
                'If you cannot find the building, you can follow the procedure:\n-Click on "This is not the right building" and the platform will allow you to select another one.\n-If you still cannot select/find it, click on "I cannot find the main building" and you will be redirected to a new page. On this page, you\'ll have to provide extra information such as the size of the plot, the size of the building and the number of touching sides. ',
            'find-epc_tooltip': '',
            'find-epc_question': 'Where can I find the EPC of a property?',
            'find-epc_answer':
                "The EPC is mandatory when selling a property. If the borrower cannot provide this information, try to find it on the sales ad. \nDon't hesitate to save your request before looking for the EPC. ",
            'epc-label-vs-number_tooltip': '',
            'epc-label-vs-number_question':
                'EPC: Is it better to give the label (letter from A to G) or to give the exact kWh/sqm-yr?',
            'epc-label-vs-number_answer':
                'The valuation depends heavily on the location, parcel, liveable space, construction year and the EPC score. Therefore we recommend to enter the request with the exact EPC score in kWh/sqm-yr. \nIf you only have the label letter from A to G, we will value the property taking the average score of each label, which will result in an approximative valuation of the market value of the property.',
            'find-living-area_tooltip': '',
            'find-living-area_question':
                'Where can I find the correct living area of a property?',
            'find-living-area_answer':
                'You can find the living area in several document linked to he property such as, for example, the sale agreement or the EPC certificate. Please note that any secondary building/basement/unused attic are not included as liveable space and therefore as living area. ',
            'find-box-number_tooltip': '',
            'find-box-number_question':
                'Where can I find the box number of an apartment?',
            'find-box-number_answer':
                "The box number of an apartment is part of the address that should be complete on the sale agreement or EPC certificate. If you're not sure of this information, please reach out to the borrower and complete the request once you have the information. ",
            'find-construction-year_tooltip': '',
            'find-construction-year_question': 'Where can I find the construction year?',
            'find-construction-year_answer':
                'The construction year can be found in the sale agreement or EPC certificate, for example. Please note that the construction year is the year of the actual construction of the building not the year of potential renovation. ',
            'construction-vs-renovation_tooltip': '',
            'construction-vs-renovation_question':
                'The property has been totally remodeled: what should be the construction year, the orginal one or the date of the makeover?',
            'construction-vs-renovation_answer':
                'The construction year is the actual year that the building was built, we do not take into account the year of any renovation. The sale agreement or the EPC certificate should reflect those renovations. ',
            'over-two-days_tooltip': '',
            'over-two-days_question':
                'It is more than 2 business days and my request is still not valued, what should I do?',
            'over-two-days_answer':
                'Rock.estate business working days are from Monday to Friday. \nYou might have counted Saturday or a public holiday in the 2 business working day you are referring too. \n\nIf not, reach out via our contact form with the reference number. ',
            'right-info-flagged-incorrect_tooltip': '',
            'right-info-flagged-incorrect_question':
                'What happens if the valuer flagged an information input in the request as "incorrect" but I know this is the right information?',
            'right-info-flagged-incorrect_answer':
                'If you have receive back a request under the status "Draft", it means that Rock.estate valuation specialist has flagged 1 or more information as "incorrect". If you know this/these information is indeed correct you can either decide to re-submit the request as it is or contact us via our contact form. ',
            'incoherent-submitted-twice_tooltip': '',
            'incoherent-submitted-twice_question':
                'What happens after a valuation is sent back twice with "incorrect information"?',
            'incoherent-submitted-twice_answer':
                'After 2 reviews by the Rock.estate valuation team, an on-site valuation will be recommended automatically. ',
            'processing-time_tooltip': '',
            'processing-time_question':
                'How long does it take for a request to be processed? ',
            'processing-time_answer':
                'It should not take more than 2 business working day for a request to be processed. \nRock.estate accounts business working days as follow:\n- From Monday to Friday (9am to 5pm)\n- Bank & Public Holidays are excluded\n\nIf a request is submitted on a Friday 7pm, for example, the first business working day will be the next Monday (except if it is a bank or public holiday)',
            'multiple-addresses_tooltip': '',
            'multiple-addresses_question':
                'Is it normal that there is more than one address for one building?',
            'multiple-addresses_answer':
                'Yes, sometimes a building will have more than one address. You can select the building with the address corresponding to your request. ',
            'what-is-bedroom_tooltip': '',
            'what-is-bedroom_question': 'What is a bedroom?',
            'what-is-bedroom_answer':
                'A bedroom is a room with a mininum of 8 sqm. and with a window. ',
            'what-is-garden_tooltip': '',
            'what-is-garden_question': 'What is a common garden?',
            'what-is-garden_answer':
                'A common garden is only accessible to the occupants of the building. It does not need to be closed but the assessed property needs to give the right to use this garden. ',
            'what-is-private-garden_tooltip': '',
            'what-is-private-garden_question': 'What is considered as a private garden?',
            'what-is-private-garden_answer':
                'A private garden is only accessible by the occupants of the assessed apartment and is adjacent to the apartment.',
            'what-is-closed-garage_tooltip': '',
            'what-is-closed-garage_question': 'What is a closed garage?',
            'what-is-closed-garage_answer':
                'A closed garage/ garage box can either be outside the building or inside an underground parking as long as there is a door that closes and that its access is only resticted to occupants of the assessed property. ',
            'what-is-parking-spot_tooltip': '',
            'what-is-parking-spot_question': 'What is a parking spot?',
            'what-is-parking-spot_answer':
                'A parking spot can either be indoor or outdoor and that is delineated by lines not walls (vs. closed garage)',
            'what-is-cellar-basement_tooltip': '',
            'what-is-cellar-basement_question': 'What is a cellar/basement?',
            'what-is-cellar-basement_answer':
                "Any room that is not counted in the liveable space that you can use as storage within the building. Examples: basement, maid's room, cellar, ...",
            carport_tooltip: '',
            carport_question: 'Does a carport refer to a parking spot or garage?',
            carport_answer: 'A carport refers to a parking spot not a closed garage. ',
            'personal-use_tooltip': '',
            'personal-use_question': 'Can I request a valuation of my own property?',
            'personal-use_answer':
                'Rock.estate\u2019s Valuation Application can be used for professional purpose only.\nIt is the responsibility of each authorized user to comply with this. Hence, we do not recommend you to request a valuation of your own property or from someone you know.',
            'is-request-submitted_tooltip': '',
            'is-request-submitted_question':
                'How do I see if I successfully submitted my request?',
            'is-request-submitted_answer':
                "Once you've succesfully submitted your request, the status of the request in your Dashboard should change to 'Submitted'. \nYou'll also receive an email informing you that the request has been submitted and that one of our valuation specialist will process it within two business working days.",
            review_tooltip: '',
            review_question:
                'The request I submited came back as a Draft in red, what am I supposed to do?',
            review_answer:
                'If you have received back a request under the status "Draft", it means that Rock.estate valuation specialist has flagged 1 or more information as "incorrect". If you know this/these information is indeed correct you can either decide to re-submit the request as it is or contact us via our contact form. ',
            'find-previous-request_tooltip': '',
            'find-previous-request_question': 'How can I find a previous request?',
            'find-previous-request_answer':
                'To find a previous request, go to your Dashboard and use the searching toolbar. You can enter a date, a reference, an internal reference, an address, ...',
            'download-report_tooltip': '',
            'download-report_question': 'How can I download the report?',
            'download-report_answer':
                "To download the report, you can click on the green box next to your request called 'PDF report'.  \nIt will only be available, after your request has been successfully valued by Rock.estate valuation team.",
            'language-impact_tooltip': '',
            'language-impact_question':
                'Does it change anything to the process if I use Rock.estate Valuation Application in a different language?',
            'language-impact_answer':
                'If you request a valuation in one language, you will receive a report of that property in the same language. You can change the language of the report but the remark will stay in the original language.\nThere will be no impact on the data or the market value of a property.',
            'unsubmission-window_tooltip': '',
            'unsubmission-window_question':
                'How can I modify a request after submission?',
            'unsubmission-window_answer':
                'You have 60 minutes to either modify or erase a request. To do so, go in your Dashboard and click on the yellow box \'Edit\'. After editing your request make sure to click on "Submit" to submit it again. ',
            'after-sixty-minutes_tooltip': '',
            'after-sixty-minutes_question':
                'After 60 minutes of submission, what can I do if I need to edit my request?',
            'after-sixty-minutes_answer':
                'If you want to edit or cancel a request after 60 minutes, let us know as soon as possible via our contact form. This request could be charged depending on the progress of the Valuation Specialist.',
            'review-billing_tooltip': '',
            'review-billing_question':
                'My request came back as a Draft, does that mean that the bank will have to pay twice for this request?',
            'review-billing_answer':
                'No, the bank will only be charged once after the request is successfully valued or recommended for an onsite valuation. \nIf after two reviews from Rock.estate valuation team it can not be successfully remotely valued, a recommendation for an on-site valuation will be performed. ',
            'on-site-next-steps_tooltip': '',
            'on-site-next-steps_question':
                "An on-site valuation is requested, what's the next step?",
            'on-site-next-steps_answer':
                'Rock.estate is currently not involved in the on-site valuation process. We recommend you to follow the Bank internal process for onsite valuation.',
            'access-requests_tooltip': '',
            'access-requests_question':
                'How can I have access to the requests of my colleagues?',
            'access-requests_answer':
                'You only have access to your own requests. A manager can have access to the requests of their employees. ',
            substitution_tooltip: '',
            substitution_question:
                'How can I take the ownership of a request that is not mine?',
        },
        questions_feedback: {
            explanation_1:
                'Do you have a specific question or comment regarding this report? Or are you just looking for some more information on how Rock.estate makes these valuation?',
            explanation_1_tooltip: '',
            explanation_2: 'Please visit our valuation portal: {url.valuation_app}.',
            explanation_2_tooltip: '',
        },
        email: {
            submission_confirmation_subject: 'Your valuation request has been submitted',
            submission_confirmation_subject_tooltip: '',
            submission_confirmation_1:
                'Dear Sir, Madam,\n\nYou have successfully submitted a new valuation request with the following references:',
            submission_confirmation_1_tooltip: '',
            submission_confirmation_2:
                "Our valuation specialist team will be treating your request shortly. In the meantime, you can follow the progress of your request in your dashboard.\n\nKind regards,\nRock.estate's valuation specialist team",
            submission_confirmation_2_tooltip: '',
            valuation_notification_subject: 'Your valuation request has been processed',
            valuation_notification_subject_tooltip: '',
            valuation_notification_1:
                'Dear Sir, Madam,\n\nThe following request has been successfully reviewed and valued by our experts:',
            valuation_notification_1_tooltip: '',
            valuation_notification_2:
                "Kindly login to your dashboard to download the report.\n\nKind regards,\nRock.estate's valuation specialist team",
            valuation_notification_2_tooltip: '',
            postpone_subject: 'Your valuation request has been postponed',
            postpone_subject_tooltip: '',
            postpone_1: 'Dear Sir, Madam,',
            postpone_1_tooltip: '',
            postpone_2: 'postponed',
            postpone_2_tooltip: '',
            postpone_3: 'thanks',
            postpone_3_tooltip: '',
            incoherent_features_subject: 'Your valuation request could not be processed',
            incoherent_features_subject_tooltip: '',
            incoherent_features_1:
                'Dear Sir, Madam,\n\nThe valuation request you submitted (see references below) can not be further processed due to incorrect information:',
            incoherent_features_1_tooltip: '',
            incoherent_features_2:
                'Our valuation specialist noticed for this request that the following characteristics might be incorrect: ',
            incoherent_features_2_tooltip: '',
            incoherent_features_3: 'Additional comment: ',
            incoherent_features_3_tooltip: '',
            incoherent_features_4:
                "Please correct this/these information from your Rock.estate Dashboard if needed. As soon as the necessary changes are made, please resubmit your request and our team of Valuation Specialists will proceed with the valuation. \n\nIf the information is indeed correct, you do not need to modify anything, just resubmit your valuation request as is. \n\nKind regards,\nRock.estate's Valuation Specialists team",
            incoherent_features_4_tooltip: '',
            owner_updated_subject: 'Transfer of ownership of your valuation request',
            owner_updated_subject_tooltip: '',
            owner_updated_1:
                'Dear Sir, Madam,\n\nThe valuation request referenced below is now owned by: ',
            owner_updated_1_tooltip: '',
            owner_updated_2:
                'If you wish to follow the progress of this request or take the ownership back, please go to the "All requests" tab on your Rock.estate dashboard and fill  the above references in the appropriate search boxes.\n\nKind regards,\n\nRock.estate\'s valuation specialist team',
            owner_updated_2_tooltip: '',
            on_site_requested_value:
                "Dear Sir, Madam, \n\nAfter review from our valuation specialist, the valuation request you submitted (see references below) can not be completed : \n- LOP ID: [LOP ID]\n- Valuation ID: [Valuation ID]\n- Address: [Address]\n\nDue to the uniqueness of this property and the contrast with other properties in the neighborhood, we suggest to send an on-site expert.\n\nKind regards,\n\nRock.estate's valuation specialist team",
            on_site_requested_value_tooltip: '',
            argenta: {
                on_site_requested_characteristics_1:
                    'Dear Sir, Madam, \n\nDue to the uniqueness and the contrast with other properties in the neighborhood a remote valuation can not be performed by our valuation specialist team.',
                on_site_requested_characteristics_1_tooltip: '',
                on_site_requested_characteristics_2:
                    "Rock.estate suggests that an on-site valuation is performed for this property.\n\nKind regards,\n\nRock.estate's valuation specialist team",
                on_site_requested_characteristics_2_tooltip: '',
                on_site_requested_characteristics_subject:
                    'A remote valuation for your request can not be performed',
                on_site_requested_characteristics_subject_tooltip: '',
            },
            onsite_request_subject: 'An on-site valuation is recommended',
            onsite_request_subject_tooltip: '',
            onsite_request_1:
                'Dear Sir, Madam,\n\nAfter review from our valuation specialist, the valuation request you submitted (see references below) can not be processed:',
            onsite_request_1_tooltip: '',
            onsite_request_2:
                "Therefore, we suggest you to follow the internal processes of your bank when a remote valuation can not be completed.\nIn case some of the information you have filled in is incorrect (e.g. wrong selection of a parcel, a building or certain characteristics of the property) we invite you to create a new request with the correct information and we will take care of it.\n\nKind regards, \nRock.estate's valuation specialist team",
            onsite_request_2_tooltip: '',
            address_not_found:
                "Dear Sir, Madam,\n\nYou requested a valuation through our tool, however, there seems to be a problem regarding the address of the property. \n\nCould you please review the request for the property with the following references: \n- LOP ID: [LOP ID]\n- Valuation ID: [Valuation ID]\n- Address: [Address]\n\nWe could not find this address in our internal tools neither on internet. \nIf the address is incorrect, kindly adjust it directly in your dashboard.\n\nWe need all the information to be filled and fully completed to give [BANK] a correct estimated value of this property. As soon as the address is confirmed, our valuation specialist will continue with the valuation. \n\nKind regards,\n\nRock.estate's valuation specialist team",
            address_not_found_tooltip: '',
            onsite_streetview:
                'We are not able to provide a valuation, as the property either can not be seen via our streetview tools or that the streetview images are outdated.',
            onsite_streetview_tooltip: '',
            onsite_incoherent_features:
                'The characteristics above seemed incorrect for this property, in addition, we sent the application back for review and afterwards we received this application back with still an incorrect result. ',
            onsite_incoherent_features_tooltip: '',
            onsite_wrong_building_selected:
                'The building or the parcel selected is incorrect for this property, \nFor this reason, we can not do a valuation remotely and recommend you to create a new request in order to select the correct building. \nWe will, then, review the property as soon as possible.',
            onsite_wrong_building_selected_tooltip: '',
            onsite_exceptional_property:
                'We are not able to provide a valuation as the property is considered as exceptional for the area. \nWe considered "exceptional" any property that own uncommon caracteristics for the neighborhood, these could be very large/small living area, presence of a tennis court or swimming pool where the rest of the area is devoid of them but also if the property is in a terrible shape in comparison to the rest of the street. ',
            onsite_exceptional_property_tooltip: '',
            onsite_commercial_building:
                'We are not able to provide a valuation as the property is a commercial building or has a commercial activity linked to it.',
            onsite_commercial_building_tooltip: '',
            onsite_volume_missing:
                "We are not able to provide it because our model couldn't retrieve some essential elements that could calculate automatically the volume of the building and/or the parcel. \nRock.estate's model gather data from multiple sources such as municipality or region, it could be possible that the data were not yet updated from one of these sources resulting in the incapacity for Rock.estate to keep an objective analysis.",
            onsite_volume_missing_tooltip: '',
            onsite_flood_zone:
                'For the property in question, we are not able to provide it because our model has detected that the property might be located in a flood area. This could potentially make it difficult to be insured.',
            onsite_flood_zone_tooltip: '',
            onsite_few_comparison:
                "We are not able to provide it because our model couldn't retrieve enough datapoints to compare with the property requested.\nOur valuation specialist has chosen to not value it remotely as he/she felt that the valuation could be incorrect.",
            onsite_few_comparison_tooltip: '',
            onsite_extensive_renovation:
                'We are not able to provide a valuation. Either because the value of the planned renovation is too high in relation to the current value of the property. Or because work above 50 000\u20ac is planned.',
            onsite_extensive_renovation_tooltip: '',
        },
        common: {
            cancel: 'Cancel',
            cancel_tooltip: '',
            save: 'Save',
            save_tooltip: '',
            edit: 'Edit',
            edit_tooltip: '',
            total: 'Total',
            total_tooltip: '',
            previous: 'Previous',
            previous_tooltip: '',
            next: 'Next',
            next_tooltip: '',
            uploading: 'Uploading',
            uploading_tooltip: '',
            thank_you_so_far: 'Thank you for providing the required info so far',
            thank_you_so_far_tooltip: '',
            yes: 'Yes',
            yes_tooltip: '',
            no: 'No',
            no_tooltip: '',
            number_input: 'Enter the energy score here',
            number_input_tooltip: '',
            multiple_choice: 'Select one of the options',
            multiple_choice_tooltip: '',
            we_are_sorry: 'We are sorry.',
            we_are_sorry_tooltip: '',
            sorry: 'Oops!',
            sorry_tooltip: '',
            enter_address: 'Enter address here',
            enter_address_tooltip: '',
            back_to_ing: 'Back to ING website',
            back_to_ing_tooltip: '',
            maintenance:
                'This website is currently in maintenance. We welcome you back soon!',
            maintenance_tooltip: '',
            send: 'Send',
            send_tooltip: '',
            start_now: 'Start now',
            start_now_tooltip: '',
            i_dont_know: "I don't know",
            i_dont_know_tooltip: '',
        },
        renovation_info: {
            title: 'Renovation Quotations',
            title_tooltip: '',
            add_document: 'Add document',
            add_document_tooltip: '',
            unlink_document: 'Unlink document',
            unlink_document_tooltip: '',
            link_document: 'Link document',
            link_document_tooltip: '',
            items: '{n} item|{n} items',
            items_tooltip: '',
            linked_items: '{n} linked item|{n} linked items',
            linked_items_tooltip: '',
        },
        renovation_item: {
            title: 'Title',
            title_tooltip: '',
            amount: 'Amount',
            amount_tooltip: '',
            category: 'Category',
            category_tooltip: '',
            subcategory: 'Subcategory',
            subcategory_tooltip: '',
            modal_title: 'Edit renovation item',
            modal_title_tooltip: '',
        },
        renovation_category: {
            energy: 'Energy',
            energy_tooltip: '',
            structural: 'Structural',
            structural_tooltip: '',
            aesthetic: 'Aesthetic',
            aesthetic_tooltip: '',
            other: 'Other',
            other_tooltip: '',
        },
        renovation_subcategory: {
            insulation: 'Insulation',
            insulation_tooltip: '',
            solar_panel: 'Solar panel',
            solar_panel_tooltip: '',
            heating: 'Heating',
            heating_tooltip: '',
            other: 'Other',
            other_tooltip: '',
            wall_extension: 'Wall extension',
            wall_extension_tooltip: '',
            demolition: 'Demolition',
            demolition_tooltip: '',
            kitchen: 'Kitchen',
            kitchen_tooltip: '',
            bathroom: 'Bathroom',
            bathroom_tooltip: '',
        },
        steps: {
            building_type: 'Building type',
            building_type_tooltip: 'Is it a single-family house or an apartment?',
            address: 'Address',
            address_tooltip: '',
            address_confirmation: 'Address confirmation',
            address_confirmation_tooltip:
                'Select the address of the property that you want to renovate.',
            building_selection: 'Building selection',
            building_selection_tooltip: '',
            house_info: 'House information',
            house_info_tooltip: '',
            epc: 'EPC',
            epc_tooltip:
                'The Energy Performance Coefficient (EPC) is the energy score of a home. This info can usually be found in the sales ad or on the EPC certificate. It is expressed in kWh/m2year.',
            f_construction_year: 'Construction year',
            f_construction_year_tooltip:
                'The official year of construction. This does not take into account any renovation works that may have followed at a later point in time. This info can usually be found in the sales ad, the reference year in the EPC certificate or the cadastral register.',
            living_area: 'Living area',
            living_area_tooltip:
                'Total living area of the home. This info can usually be found in the sales ad or the heated square footage in the EPC certificate. ',
            insulation: 'Insulation',
            insulation_tooltip: '',
            walls: 'Walls',
            walls_tooltip: '',
            roof: 'Roof',
            roof_tooltip: '',
            floor: 'Floor',
            floor_tooltip: '',
            windows: 'Windows',
            windows_tooltip: '',
            attic: 'Attic',
            attic_tooltip: '',
            basement: 'Basement',
            basement_tooltip: '',
            consumption: 'Consumption',
            consumption_tooltip: '',
            heating: 'Heating',
            heating_tooltip: '',
            electricity_consumption: 'Electric consumption',
            electricity_consumption_tooltip: '',
            gas_consumption: 'Gas consumption',
            gas_consumption_tooltip: '',
            oil_consumption: 'Oil consumption',
            oil_consumption_tooltip: '',
            coal_consumption: 'Coal consumption',
            coal_consumption_tooltip: '',
            wood_consumption: 'Wood consumption',
            wood_consumption_tooltip: '',
            pellets_consumption: 'Pellets consumption',
            pellets_consumption_tooltip: '',
            district_heating_consumption: 'District heating consumption',
            district_heating_consumption_tooltip: '',
            solar_panels: 'Solar panels',
            solar_panels_tooltip: '',
            ventilation: 'Ventilation',
            ventilation_tooltip: '',
            consumers: 'Consumers',
            consumers_tooltip: '',
            electric_vehicles: 'Electric cars',
            electric_vehicles_tooltip: '',
            processing: 'Processing',
            processing_tooltip: '',
        },
        property_type: {
            apartments: 'Apartments',
            apartments_tooltip: '',
            coming_soon: "The app does not work for apartments yet. It's coming soon! ",
            coming_soon_tooltip: '',
            select_type: 'Enter the address here',
            select_type_tooltip: '',
            title: 'What type of property is it?',
            title_tooltip: '',
        },
        out_of_bounds_page: {
            title: 'We did not find any data on the requested address',
            title_tooltip: '',
            content_tooltip: '',
            button: 'Try another address',
            button_tooltip: '',
            ing_button: 'Return to ING',
            ing_button_tooltip: '',
        },
        terms_page: {
            welcome: 'n/a',
            welcome_tooltip: '',
            epc: 'n/a',
            epc_tooltip: '',
            disclaimer: 'n/a',
            disclaimer_tooltip: '',
        },
        features_question: {
            insulated_floors: 'Are the floors insulated?',
            insulated_floors_tooltip: '',
            insulated_roof: 'Is the roof insulated?',
            insulated_roof_tooltip: '',
            insulated_walls: 'Are the walls insulated?',
            insulated_walls_tooltip: '',
            has_attic: 'Do you have an attic?',
            has_attic_tooltip: '',
            insulation_floors_when: 'When was the most recent insulation renovation?',
            insulation_floors_when_tooltip: '',
            insulation_roof_when: 'When was the most recent insulation renovation?',
            insulation_roof_when_tooltip: '',
            insulation_walls_when: 'When was the most recent insulation renovation?',
            insulation_walls_when_tooltip: '',
            has_basement: 'Is there a basement?',
            has_basement_tooltip: '',
            is_attic_heated: 'Is the attic space heated?',
            is_attic_heated_tooltip: '',
            epc_known: "Do you know the building's official EPC score?",
            epc_known_tooltip: '',
            select_heating_fuel: 'Select heating fuel first',
            select_heating_fuel_tooltip: '',
            ventilation_type: 'What type of ventilation do you have?',
            ventilation_type_tooltip: '',
            ventilation_subtype: 'What type of system do you have?',
            ventilation_subtype_tooltip: '',
            n_residents: 'How many people live in the house?',
            n_residents_tooltip: '',
            protected_consumer: 'Are you a protected consumer?',
            protected_consumer_tooltip: '',
            heating_consumption_approx:
                'Please provide us with an estimation of your heating consumption',
            heating_consumption_approx_tooltip: '',
            electric_consumption_approx:
                'Please provide us with an estimation of your electrical consumption',
            electric_consumption_approx_tooltip: '',
            installation_year: 'When was it installed?',
            installation_year_tooltip: '',
            heater: 'What kind of heater do you have?',
            heater_tooltip: '',
            consumption_known: 'Do you know your energy consumption?',
            consumption_known_tooltip:
                'You can find this information on your latest energy invoice or by comparing meter values. Please pay attention to enter yearly values only. It may be needed to recalculate the value on the invoice to a yearly value.',
            yearly_electricity_consumption: 'Do you know your electric consumption?',
            yearly_electricity_consumption_tooltip:
                'You can find this information on your latest electricity invoice or by comparing meter values. Please pay attention to enter yearly values only. It may be needed to recalculate the value on the invoice to a yearly value.',
            yearly_fuel_consumption: 'Do you know your fuel consumption?',
            yearly_fuel_consumption_tooltip:
                'You can find this information on your latest fuel invoice or by comparing meter values. Please pay attention to enter yearly values only. It may be needed to recalculate the value on the invoice to a yearly value.',
            yearly_heating_consumption: 'Do you know your yearly heating consumption?',
            yearly_heating_consumption_tooltip: '',
            f_construction_year: 'When was the house built?',
            f_construction_year_tooltip: '',
            f_living_area: "What's the house's living area ?",
            f_living_area_tooltip:
                'Total living area of the home. This info can usually be found in the sales ad or the heated square footage in the EPC certificate. ',
            solar_panels: 'Are there solar panels?',
            solar_panels_tooltip: '',
            insulated_windows: 'What type of window glazing has the home?',
            insulated_windows_tooltip: '',
            insulated_single_renovated:
                'Was improved insulation installed after construction?',
            insulated_single_renovated_tooltip: '',
            insulated_plural_renovated:
                'Was improved insulation installed after construction?',
            insulated_plural_renovated_tooltip: '',
            gas_consumption_known: 'Do you known your yearly gas consumption?',
            gas_consumption_known_tooltip: '',
            oil_consumption_known: 'Do you known your yearly oil consumption?',
            oil_consumption_known_tooltip: '',
            wood_consumption_known: 'Do you known your yearly wood consumption?',
            wood_consumption_known_tooltip: '',
            coal_consumption_known: 'Do you known your yearly coal consumption?',
            coal_consumption_known_tooltip: '',
            pellets_consumption_known: 'Do you known your yearly pellets consumption?',
            pellets_consumption_known_tooltip: '',
            district_heating_consumption_known:
                'Do you known your yearly consumption from district heating?',
            district_heating_consumption_known_tooltip: '',
            electricity_consumption_known:
                'Do you known your yearly electricity consumption?',
            electricity_consumption_known_tooltip: '',
            gas_consumption_approx: 'Houw would you estimate your gas consumption?',
            gas_consumption_approx_tooltip: '',
            oil_consumption_approx: 'How would you estimate your oil consumption?',
            oil_consumption_approx_tooltip: '',
            wood_consumption_approx: 'How would you estimate your wood consumption?',
            wood_consumption_approx_tooltip: '',
            coal_consumption_approx: 'How would you estimate your coal consumption?',
            coal_consumption_approx_tooltip: '',
            pellets_consumption_approx:
                'How would you estimate your pellets consumption?',
            pellets_consumption_approx_tooltip: '',
            district_heating_consumption_approx:
                'How would you estimate your consumption from district heating?',
            district_heating_consumption_approx_tooltip: '',
            electricity_consumption_approx:
                'How would you estimate your electricity consumption?',
            electricity_consumption_approx_tooltip: '',
        },
        map_page: {
            '3d_model':
                'Based on the information provided, we were able to generate a simplified 3D model of the requested property.',
            '3d_model_tooltip': '',
        },
        address_page: {
            title: 'What is the address of the property?',
            title_tooltip: '',
            control_address: 'Complete the address if needed',
            control_address_tooltip: '',
        },
        validation: {
            field_required: 'This field is required',
            field_required_tooltip: '',
            only_numbers: 'Please enter only numeric values',
            only_numbers_tooltip: '',
            min_4_characters: 'Input should contain min. 4 characters',
            min_4_characters_tooltip: '',
            wrong_epc: 'Please enter a correct EPC label',
            wrong_epc_tooltip: '',
            valid_years: 'Between {min} and {max}',
            valid_years_tooltip: '',
            value_too_large: 'The number is too large',
            value_too_large_tooltip: '',
        },
        f_epc: {
            label: 'EPC Label',
            label_tooltip: '',
        },
        features_page: {
            title: 'We need some information about the property',
            title_tooltip: '',
        },
        consumption_page: {
            title: 'Please provide us more information on your energy consumption.',
            title_tooltip: '',
            electric_vehicle: 'Do you have an electric vehicle?',
            electric_vehicle_tooltip: '',
        },
        login_page: {
            login: 'Login',
            login_tooltip: '',
        },
        welcome_page: {
            intro:
                'Let\u2019s find out what the most efficient investments are and a budget estimation.',
            intro_tooltip: '',
            corpus_tooltip: '',
            disclaimer_tooltip: '',
            by_clicking: 'By clicking "Start now" you agree with our ',
            by_clicking_tooltip: '',
            terms: 'terms and conditions',
            terms_tooltip: '',
            curious: 'Curious to know what the EPC of your (future) home is?',
            curious_tooltip: '',
        },
        error_page: {
            title: 'Something went wrong',
            title_tooltip: '',
            intro:
                'An error occured and the service is currently unavailable. Please try again later.',
            intro_tooltip: '',
            button: 'Return to ING',
            button_tooltip: '',
        },
        insulation: {
            title: 'How is the insulation of the property?',
            title_tooltip: '',
        },
        consumption: {
            gas_consumption_title: 'Gas consumption',
            gas_consumption_title_tooltip: '',
            oil_consumption_title: 'Oil consumption',
            oil_consumption_title_tooltip: '',
            wood_consumption_title: 'Wood consumption',
            wood_consumption_title_tooltip: '',
            coal_consumption_title: 'Coal consumption',
            coal_consumption_title_tooltip: '',
            pellets_consumption_title: 'Pellets consumption',
            pellets_consumption_title_tooltip: '',
            district_heating_consumption_title: 'Consumption from district heating',
            district_heating_consumption_title_tooltip: '',
            electricity_consumption_title: 'Electricity consumption',
            electricity_consumption_title_tooltip: '',
        },
        ventilation_page: {
            ventilation_title: 'Ventilation',
            ventilation_title_tooltip: '',
        },
        consumers_page: {
            title: 'Please provide us more information on your energy consumption.',
            title_tooltip: '',
        },
        energy_quizz: {
            solar: {
                qa: {
                    q:
                        'According to our data we think this is the best way to place solar panels on the roof. Would you agree?',
                    q_tooltip: '',
                    a1: 'Yes, I agree',
                    a1_tooltip: '',
                    a2: 'No, this is not the side that catches the most sun',
                    a2_tooltip: '',
                    a3: 'No, this is not the right surface',
                    a3_tooltip: '',
                },
                qb: {
                    q:
                        "You've indicated that there are solar panels. We estimate that {x} panels fit on your roof. Would you agree?",
                    q_tooltip: '',
                    a1: 'Yes, I agree',
                    a1_tooltip: '',
                    a2: 'No, that is not feasible',
                    a2_tooltip: '',
                    a3: 'Yes, but we decided to get a different amount of solar panels',
                    a3_tooltip: '',
                },
            },
            q1: {
                q: 'Which statement on solar panels is correct?',
                q_tooltip: '',
                a1:
                    'Solar panels have an average payback period of 15 years. The average product life is 25 years.',
                a1_tooltip: '',
                f1:
                    "That's not correct. The average payback period is around 6-8 years. The correct answer is 2.",
                f1_tooltip: '',
                a2:
                    'Solar panels lower the EPC value of a house. A lower EPC score increases the property value.',
                a2_tooltip: '',
                f2: "Awesome, that's correct. ",
                f2_tooltip: '',
                a3: 'Solar panels cannot be used to heat up water',
                a3_tooltip: '',
                f3:
                    "That's not correct. There are two ways of heating water with solar panels: via a solar boiler or by powering the water boiler. The correct answer is 2.",
                f3_tooltip: '',
            },
            q2: {
                q:
                    'What is the right order of renovations to improve the energy efficiency of a house?',
                q_tooltip: '',
                a1: 'Walls - roof - windows - floors - heating',
                a1_tooltip: '',
                f1: "That's not correct. The correct answer is 2.",
                f1_tooltip: '',
                a2: 'Roof - walls - windows - floors - heating',
                a2_tooltip: '',
                f2: "Awesome, that's correct. ",
                f2_tooltip: '',
                a3: 'Roof - walls - floors - windows - heating',
                a3_tooltip: '',
                f3: "That's not correct. The correct answer is 2.",
                f3_tooltip: '',
                a4: 'Walls - roof - windows - heating - floor',
                a4_tooltip: '',
                f4: "That's not correct. The correct answer is 2.",
                f4_tooltip: '',
            },
            q3: {
                q:
                    'What can you do when you create more energy with solar panels than you consume?',
                q_tooltip: '',
                a1: 'Nothing. It will be lost',
                a1_tooltip: '',
                f1:
                    "That's not correct. There are interesting ways to get the most out of the generated solar power. The correct answer is 3.",
                f1_tooltip: '',
                a2: 'Store all energy for when you need it',
                a2_tooltip: '',
                f2:
                    "That's not correct. While you can charge a battery with solar power, not all power can be stored for an unlimited amount of time. The correct answer is 3.",
                f2_tooltip: '',
                a3: 'Sell the excess energy, e.g. to an energy provider or a retailer',
                a3_tooltip: '',
                f3: "Awesome, that's correct. ",
                f3_tooltip: '',
            },
            q4: {
                q: 'What is not a good way to save on the gas bill without renovating?',
                q_tooltip: '',
                a1:
                    'Place aluminum foil between the heating radiator and non-isolated walls',
                a1_tooltip: '',
                f1:
                    "That's not correct. Aluminum foil between the heating radiator and non-insulated exterior walls diminishes the heat loss. It is a good way to decrease gas consumption. The correct answer is 3.",
                f1_tooltip: '',
                a2:
                    'Isolate heating pipes that run from the kettle to the heating radiators in the cellar or attic',
                a2_tooltip: '',
                f2:
                    "That's not correct. Isolating heating pipes in rooms that you do not heat is a good way to consume less gas because this decreases heat getting lost. The correct answer is 3.",
                f2_tooltip: '',
                a3: 'Heat with an electric heater',
                a3_tooltip: '',
                f3:
                    "Awesome, that's correct. An electric heater is a big electricity consumer. Not only does this cost a lot of money, but it also has a negative impact on the environment.",
                f3_tooltip: '',
            },
            q5: {
                q: "What measure decreases a house's CO2 emissions the most?",
                q_tooltip: '',
                a1: "Don't use a washing dryer",
                a1_tooltip: '',
                f1: "That's not correct. The correct answer is 2.",
                f1_tooltip: '',
                a2: 'Install underfloor heating',
                a2_tooltip: '',
                f2: "Awesome, that's correct. ",
                f2_tooltip: '',
                a3: 'Shower ever 2 days',
                a3_tooltip: '',
                f3: "That's not correct. The correct answer is 2.",
                f3_tooltip: '',
            },
            q6: {
                q: 'Which statement is not correct?',
                q_tooltip: '',
                a1: 'Floor insulation saves more energy than wall insulation',
                a1_tooltip: '',
                f1: "Awesome, that's correct. ",
                f1_tooltip: '',
                a2: 'Roof insulation saves more CO2 emissions than solar panels',
                a2_tooltip: '',
                f2:
                    "That's not correct. Roof insulation is better for reducing CO2 emissions than solar panels. The correct answer is 1.",
                f2_tooltip: '',
                a3:
                    'Replacing windows has a bigger impact than getting a new heating system',
                a3_tooltip: '',
                f3:
                    "That's not correct. Improving the quality of the windows can save more energy than getting a new heating system. The correct answer is 1.",
                f3_tooltip: '',
            },
            discovery_phase: 'Discovery phase',
            discovery_phase_tooltip: '',
            discovery_description:
                'Good job! You are on a path of discovering everything about energy. Stay curious and continue learning about how you can save energy. Start by having a look at the correct answers below.',
            discovery_description_tooltip: '',
            learning_phase: 'Learning phase',
            learning_phase_tooltip: '',
            learning_description:
                'Keep up the good work! You are knowledgeable on how to make your property energy efficient. Continue discovering more ways on how to save energy!',
            learning_description_tooltip: '',
            expert_phase: 'Expert phase',
            expert_phase_tooltip: '',
            expert_description:
                'Congrats! You are an expert when it comes to creating an energy efficient home. Time to put it into practice!',
            expert_description_tooltip: '',
        },
        ers_report: {
            language: 'EN',
            language_tooltip: '',
            header_title: 'Thank you for your interest.',
            header_title_tooltip: '',
            header_subtitle:
                'Based on the given information, we have simulated the current EPC score. The better the input data, the more accurate the simulation will be. This does not replace an official EPC score.',
            header_subtitle_tooltip: '',
            header_subtitle_pdf:
                'Based on the given information, we have simulated the current EPC score. The better the input data, the more accurate the simulation will be. This does not replace an official EPC score.',
            header_subtitle_pdf_tooltip: '',
            epc_graph_title: 'Your current EPC score*',
            epc_graph_title_tooltip: '',
            consumption_title: 'Your consumption',
            consumption_title_tooltip: '',
            gas_title: 'Gas',
            gas_title_tooltip: '',
            electric_title: 'Electric',
            electric_title_tooltip: '',
            low_title: 'Low',
            low_title_tooltip: '',
            high_title: 'High',
            high_title_tooltip: '',
            average_title: 'Average',
            average_title_tooltip: '',
            you_title: 'You',
            you_title_tooltip: '',
            current_epc: 'Current epc',
            current_epc_tooltip: '',
            future_epc: 'Future epc',
            future_epc_tooltip: '',
            summary_title: 'Summary',
            summary_title_tooltip: '',
            summary_note:
                'The prices and subsidies below are indicative. Prices include VAT and may change based on the selection of renovation measures.**',
            summary_note_tooltip: '',
            price_title: 'Price incl. VAT',
            price_title_tooltip: '',
            subsidy_title: 'Subs.',
            subsidy_title_tooltip: '',
            after_subsidy_title: 'After Subs',
            after_subsidy_title_tooltip: '',
            total_title: 'Total',
            total_title_tooltip: '',
            calculate_button_text: 'Calculate my ING loan',
            calculate_button_text_tooltip: '',
            download_report: 'Download my report',
            download_report_tooltip: '',
            renovation_title: 'Your Renovation Goal',
            renovation_title_tooltip: '',
            renovation_subtitle:
                'What is your renovation goal? Select a renovation package to see which renovations are the most efficient to reach that goal. Or, select renovations to see with which EPC score they align.',
            renovation_subtitle_tooltip: '',
            renovation_price: 'Price ',
            renovation_price_tooltip: '',
            renovation_subsidy: 'Subsidy',
            renovation_subsidy_tooltip: '',
            renovation_yearly: 'Yearly savings',
            renovation_yearly_tooltip: '',
            renovation_windowsDouble: 'Glazing',
            renovation_windowsDouble_tooltip: '',
            renovation_wallExt: 'Wall Exterior',
            renovation_wallExt_tooltip: '',
            renovation_wallCav: 'Wall Cavity',
            renovation_wallCav_tooltip: '',
            renovation_roofIn: 'Roof Interior',
            renovation_roofIn_tooltip: '',
            renovation_roofExt: 'Roof Exterior',
            renovation_roofExt_tooltip: '',
            renovation_roofIn_attic_floor: 'Roof Attic floor',
            renovation_roofIn_attic_floor_tooltip: '',
            renovation_condGas: 'Boiler',
            renovation_condGas_tooltip: '',
            renovation_heatPump: 'Heat pump',
            renovation_heatPump_tooltip: '',
            renovation_ventC: 'Ventilation type C',
            renovation_ventC_tooltip: '',
            renovation_solar: 'Solar Panels',
            renovation_solar_tooltip: '',
            compliance_pack_title: 'Good start',
            compliance_pack_title_tooltip: '',
            compliance_pack_desc:
                'I wish to be compliant with legal requirements on energy scores.',
            compliance_pack_desc_tooltip: '',
            budget_pack_title: 'Green budget',
            budget_pack_title_tooltip: '',
            budget_pack_desc:
                'I wish to optimize a budget of x euros on energetic renovations.',
            budget_pack_desc_tooltip: '',
            lowest_pack_title: 'BENovation',
            lowest_pack_title_tooltip: '',
            lowest_pack_desc:
                'I wish to see the full energy renovation trajectory for this property.',
            lowest_pack_desc_tooltip: '',
            custom_pack_title: 'Custom pack',
            custom_pack_title_tooltip: '',
            custom_pack_desc:
                'I wish to create my own combination of renovation measures. ',
            custom_pack_desc_tooltip: '',
            add_remove_renovations: 'Add or remove renovations',
            add_remove_renovations_tooltip: '',
            submit_button_budget: 'Submit',
            submit_button_budget_tooltip: '',
            already_compilance:
                'Congratulations! The property is compliant with legal requirements. Click on the other packages to find out what other investment you can make',
            already_compilance_tooltip: '',
            no_renovation_found: 'No renovations found. Submit your green budget',
            no_renovation_found_tooltip: '',
            renovation_subtitle_compliance_pdf:
                'Congratulations on your Good Start! With a budget of EUR {amount}, the EPC score of the property can go from {current_epc_score} to {future_epc_score}.\n\nBelow you find the most cost-efficient package that will help you realize this goal as well as your yearly savings and an indication on subsidies.\n\nFor more information on subsidies, please visit the following websites:\n- For Flanders, go to https://www.mijnbenovatie.be/nl/wat-waarom/benovatiepremies-2022/\n- For Wallonia, go to https://energie.wallonie.be/fr/primes.html?IDC=7015\n- For Brussels, go to https://renolution.brussels/nl',
            renovation_subtitle_compliance_pdf_tooltip: '',
            renovation_subtitle_budget_pdf:
                'Congratulations on your Green Budget! Your renovation goal is to optimize a budget of EUR {amount}. This investment can improve the EPC score of your property from an EPC score {current_epc_score} to an EPC score {future_epc_score}.\n\nBelow you find the most cost-efficient package that will help you realize this goal as well as your yearly savings and an indication on subsidies.\n\nFor more information on subsidies, please visit the following websites:\n- For Flanders, go to https://www.mijnbenovatie.be/nl/wat-waarom/benovatiepremies-2022/\n- For Wallonia, go to https://energie.wallonie.be/fr/primes.html?IDC=7015\n- For Brussels, go to https://renolution.brussels/nl',
            renovation_subtitle_budget_pdf_tooltip: '',
            renovation_subtitle_custom_pdf:
                'Congratulations on your custom pack! With a budget of EUR {amount}, the EPC score of the property can go from an EPC score {current_epc_score} to an EPC score {future_epc_score}.\n\nBelow you find the most cost-efficient package that will help you realize this goal as well as your yearly savings and an indication on subsidies.\n\nFor more information on subsidies, please visit the following websites:\n- For Flanders, go to https://www.mijnbenovatie.be/nl/wat-waarom/benovatiepremies-2022/\n- For Wallonia, go to https://energie.wallonie.be/fr/primes.html?IDC=7015\n- For Brussels, go to https://renolution.brussels/nl',
            renovation_subtitle_custom_pdf_tooltip: '',
            disclaimer_title: 'Disclaimer',
            disclaimer_title_tooltip: '',
            disclaimer_body:
                '*The estimation of the current EPC score is based on the information provided in the questionnaire combined with building data, and technical requirements as stated by the official EPC software. The more accurate this information is, the better we can approximate the actual EPC score. This does not replace an official EPC certificate. Please consult a professional for personalized advice. \n**Prices include 6% or 21% VAT depending on the provided information. They do not include additional works that may be a pre-requisite for the renovation. We update prices and subsidies regularly. Depending on the time of your visit the outcome may vary. Also, subsidies may be different depending on your situation. Always consult a professional to get personalized advice. ',
            disclaimer_body_tooltip: '',
            disclaimer_body_pdf:
                '*The estimation of the current EPC score is based on the information provided in the questionnaire combined with building data, and technical requirements as stated by the official EPC software. The more accurate this information is, the better we can approximate the actual EPC score. This does not replace an official EPC certificate. Please consult a professional for personalized advice. \n**Prices include 6% or 21% VAT depending on the provided information. They do not include additional works that may be a pre-requisite for the renovation. We update prices and subsidies regularly. Depending on the time of your visit the outcome may vary. Also, subsidies may be different depending on your situation. Always consult a professional to get personalized advice. ',
            disclaimer_body_pdf_tooltip: '',
        },
        processing_page: {
            title_started: 'Your simulation is being processed',
            title_started_tooltip: '',
            title_finished: 'Your simulation is ready!',
            title_finished_tooltip: '',
            title_failed: 'Something went wrong with your simulation',
            title_failed_tooltip: '',
            subtitle:
                'While we process your results, test your energy knowledge with our quizz below:',
            subtitle_tooltip: '',
            ing_button: 'Go back to ING',
            ing_button_tooltip: '',
            check_results: 'Check out the results',
            check_results_tooltip: '',
            results_ready: 'Your results are ready!',
            results_ready_tooltip: '',
            something_went_wrong: 'Something went wrong with your simulation',
            something_went_wrong_tooltip: '',
        },
    },
    technical: {
        unit: {
            m: 'unit.m',
            m_tooltip: '',
            m2: 'unit.m2',
            m2_tooltip: '',
            m3: 'unit.m3',
            m3_tooltip: '',
            'kwh/m2year': 'unit.kwh/m2year',
            'kwh/m2year_tooltip': '',
            euro: 'unit.euro',
            euro_tooltip: '',
            'euro/month': 'unit.euro/month',
            'euro/month_tooltip': '',
            'euro/m2': 'unit.euro/m2',
            'euro/m2_tooltip': '',
            'kgco2/m2year': 'unit.kgco2/m2year',
            'kgco2/m2year_tooltip': '',
            kWh: 'unit.kWh',
            kWh_tooltip: '',
            l: 'unit.l',
            l_tooltip: '',
            kg: 'unit.kg',
            kg_tooltip: '',
            'kWh/yr': 'unit.kWh/yr',
            'kWh/yr_tooltip': '',
            'l/yr': 'unit.l/yr',
            'l/yr_tooltip': '',
            'm3/yr': 'unit.m3/yr',
            'm3/yr_tooltip': '',
            'kg/yr': 'unit.kg/yr',
            'kg/yr_tooltip': '',
        },
        address: {
            full_address: 'address.full_address',
            full_address_tooltip: 'address.full_address_tooltip',
            streetname: 'address.streetname',
            streetname_tooltip: '',
            streetnumber: 'address.streetnumber',
            streetnumber_tooltip: '',
            boxnumber: 'address.boxnumber',
            boxnumber_tooltip: '',
            postalcode: 'address.postalcode',
            postalcode_tooltip: '',
            municipality: 'address.municipality',
            municipality_tooltip: '',
        },
        valuation: {
            date: 'valuation.date',
            date_tooltip: '',
            type: 'valuation.type',
            type_tooltip: '',
            valuer: 'valuation.valuer',
            valuer_tooltip: '',
            monitor: 'valuation.monitor',
            monitor_tooltip: '',
            market_value: 'valuation.market_value',
            market_value_tooltip: '',
            market_value_post_renovation: 'valuation.market_value_post_renovation',
            market_value_post_renovation_tooltip: '',
            forced_sale_value: 'valuation.forced_sale_value',
            forced_sale_value_tooltip: '',
            rental_value: 'valuation.rental_value',
            rental_value_tooltip: '',
            reconstruction_value: 'valuation.reconstruction_value',
            reconstruction_value_tooltip: '',
            index_value: 'valuation.index_value',
            index_value_tooltip: '',
            index_type: 'valuation.index_type',
            index_type_tooltip: '',
            explanation_1: 'valuation.explanation_1',
            explanation_1_tooltip: '',
            explanation_2: 'valuation.explanation_2',
            explanation_2_tooltip: '',
            explanation_3: 'valuation.explanation_3',
            explanation_3_tooltip: '',
            explanation_4: 'valuation.explanation_4',
            explanation_4_tooltip: '',
            explanation_5: 'valuation.explanation_5',
            explanation_5_tooltip: '',
            explanation_6: 'valuation.explanation_6',
            explanation_6_tooltip: '',
        },
        value: {
            avm: 'value.avm',
            avm_tooltip: '',
            dvm: 'value.dvm',
            dvm_tooltip: '',
            ovm: 'value.ovm',
            ovm_tooltip: '',
            new_loan: 'value.new_loan',
            new_loan_tooltip: '',
            revaluation: 'value.revaluation',
            revaluation_tooltip: '',
            classic_tooltip: '',
            renovation_light_tooltip: '',
            renovation_full_tooltip: '',
            house: 'value.house',
            house_tooltip: 'value.house_tooltip',
            apartment: 'value.apartment',
            apartment_tooltip: 'value.apartment_tooltip',
            annex: 'value.annex',
            annex_tooltip: '',
            new_construction: 'value.new_construction',
            new_construction_tooltip: '',
            construction_plot: 'value.construction_plot',
            construction_plot_tooltip: '',
            attached: 'value.attached',
            attached_tooltip: '',
            semi: 'value.semi',
            semi_tooltip: '',
            detached: 'value.detached',
            detached_tooltip: '',
            yes: 'value.yes',
            yes_tooltip: '',
            no: 'value.no',
            no_tooltip: '',
            traditional: 'value.traditional',
            traditional_tooltip: '',
            modern: 'value.modern',
            modern_tooltip: '',
            contemporary: 'value.contemporary',
            contemporary_tooltip: '',
            mediterranean: 'value.mediterranean',
            mediterranean_tooltip: '',
            other: 'value.other',
            other_tooltip: '',
            ground_floor: 'value.ground_floor',
            ground_floor_tooltip: '',
            1: 'value.1',
            '1_tooltip': '',
            2: 'value.2',
            '2_tooltip': '',
            3: 'value.3',
            '3_tooltip': '',
            4: 'value.4',
            '4_tooltip': '',
            '>4': 'value.>4',
            '>4_tooltip': '',
            private: 'value.private',
            private_tooltip: '',
            common: 'value.common',
            common_tooltip: '',
            'private+common': 'value.private+common',
            'private+common_tooltip': '',
            maintenance_required: 'value.maintenance_required',
            maintenance_required_tooltip: '',
            good: 'value.good',
            good_tooltip: '',
            very_good: 'value.very_good',
            very_good_tooltip: '',
            cinder_blocks: 'value.cinder_blocks',
            cinder_blocks_tooltip: '',
            wood: 'value.wood',
            wood_tooltip: '',
            plaster: 'value.plaster',
            plaster_tooltip: '',
            natural_stone: 'value.natural_stone',
            natural_stone_tooltip: '',
            metal: 'value.metal',
            metal_tooltip: '',
            concrete: 'value.concrete',
            concrete_tooltip: '',
            brick: 'value.brick',
            brick_tooltip: '',
            tiles: 'value.tiles',
            tiles_tooltip: '',
            bitumen_roofing: 'value.bitumen_roofing',
            bitumen_roofing_tooltip: '',
            slate_shingles: 'value.slate_shingles',
            slate_shingles_tooltip: '',
            black_concrete_tiles: 'value.black_concrete_tiles',
            black_concrete_tiles_tooltip: '',
            thatched: 'value.thatched',
            thatched_tooltip: '',
            asbestos: 'value.asbestos',
            asbestos_tooltip: '',
            green_roof: 'value.green_roof',
            green_roof_tooltip: '',
            home_liberal_profession_or_office: 'value.home_liberal_profession_or_office',
            home_liberal_profession_or_office_tooltip: '',
            home_other_professional_activity: 'value.home_other_professional_activity',
            home_other_professional_activity_tooltip: '',
            home: 'value.home',
            home_tooltip: '',
            no_economic_activity: 'value.no_economic_activity',
            no_economic_activity_tooltip: '',
            activity_unlikely: 'value.activity_unlikely',
            activity_unlikely_tooltip: '',
            activity_possible: 'value.activity_possible',
            activity_possible_tooltip: '',
            activity_likely: 'value.activity_likely',
            activity_likely_tooltip: '',
            activity_very_likely: 'value.activity_very_likely',
            activity_very_likely_tooltip: '',
            n: 'value.n',
            n_tooltip: '',
            ne: 'value.ne',
            ne_tooltip: '',
            e: 'value.e',
            e_tooltip: '',
            se: 'value.se',
            se_tooltip: '',
            s: 'value.s',
            s_tooltip: '',
            sw: 'value.sw',
            sw_tooltip: '',
            w: 'value.w',
            w_tooltip: '',
            nw: 'value.nw',
            nw_tooltip: '',
            limited: 'value.limited',
            limited_tooltip: '',
            details_previous_page: 'value.details_previous_page',
            details_previous_page_tooltip: '',
            low: 'value.low',
            low_tooltip: '',
            medium: 'value.medium',
            medium_tooltip: '',
            high: 'value.high',
            high_tooltip: '',
            unknown: 'value.unknown',
            unknown_tooltip: '',
            urban: 'value.urban',
            urban_tooltip: '',
            rural: 'value.rural',
            rural_tooltip: '',
            isolated: 'value.isolated',
            isolated_tooltip: '',
            city: 'value.city',
            city_tooltip: '',
            suburbs: 'value.suburbs',
            suburbs_tooltip: '',
            village: 'value.village',
            village_tooltip: '',
            none: 'value.none',
            none_tooltip: '',
            classified_facade: 'value.classified_facade',
            classified_facade_tooltip: '',
            green_neighbourhood: 'value.green_neighbourhood',
            green_neighbourhood_tooltip: '',
            sea_view: 'value.sea_view',
            sea_view_tooltip: '',
            no_direct_traffic: 'value.no_direct_traffic',
            no_direct_traffic_tooltip: '',
            exceptional_view: 'value.exceptional_view',
            exceptional_view_tooltip: '',
            tram: 'value.tram',
            tram_tooltip: '',
            train: 'value.train',
            train_tooltip: '',
            metro: 'value.metro',
            metro_tooltip: '',
            traffic: 'value.traffic',
            traffic_tooltip: '',
            busy_area: 'value.busy_area',
            busy_area_tooltip: '',
            abex: 'value.abex',
            abex_tooltip: '',
            single: 'value.single',
            single_tooltip: '',
            ordinary_double: 'value.ordinary_double',
            ordinary_double_tooltip: '',
            hr_double_after_2000: 'value.hr_double_after_2000',
            hr_double_after_2000_tooltip: '',
            hr_double_before_2000: 'value.hr_double_before_2000',
            hr_double_before_2000_tooltip: '',
            triple: 'value.triple',
            triple_tooltip: '',
            gas: 'value.gas',
            gas_tooltip: '',
            oil: 'value.oil',
            oil_tooltip: '',
            pellets: 'value.pellets',
            pellets_tooltip: '',
            coal: 'value.coal',
            coal_tooltip: '',
            district_heating: 'value.district_heating',
            district_heating_tooltip: '',
            'boiler:condensing': 'value.boiler:condensing',
            'boiler:condensing_tooltip': '',
            heat_pump: 'value.heat_pump',
            heat_pump_tooltip: '',
            'boiler:standard': 'value.boiler:standard',
            'boiler:standard_tooltip': '',
            chp: 'value.chp',
            chp_tooltip: '',
            stove: 'value.stove',
            stove_tooltip: '',
            electricity: 'value.electricity',
            electricity_tooltip: '',
            electric_heater: 'value.electric_heater',
            electric_heater_tooltip: '',
            no_ventilation: 'value.no_ventilation',
            no_ventilation_tooltip: '',
            natural: 'value.natural',
            natural_tooltip: 'value.natural_tooltip',
            mechanical: 'value.mechanical',
            mechanical_tooltip: '',
            system_b: 'value.system_b',
            system_b_tooltip: 'value.system_b_tooltip',
            system_c: 'value.system_c',
            system_c_tooltip: 'value.system_c_tooltip',
            system_cplus: 'value.system_cplus',
            system_cplus_tooltip: 'value.system_cplus_tooltip',
            system_d: 'value.system_d',
            system_d_tooltip: 'value.system_d_tooltip',
        },
        request: {
            references: 'request.references',
            references_tooltip: '',
            valuation_request_ref: 'request.valuation_request_ref',
            valuation_request_ref_tooltip: '',
            customer_ref: 'request.customer_ref',
            customer_ref_tooltip: 'request.customer_ref_tooltip',
            bank: 'request.bank',
            bank_tooltip: '',
            customer: 'request.customer',
            customer_tooltip: '',
            purpose: 'request.purpose',
            purpose_tooltip: '',
            package_tooltip: '',
        },
        footer: {
            text: 'footer.text',
            text_tooltip: '',
        },
        url: {
            rockestate_main: 'url.rockestate_main',
            rockestate_main_tooltip: '',
            dashboard: 'url.dashboard',
            dashboard_tooltip: '',
            rockestate_info: 'url.rockestate_info',
            rockestate_info_tooltip: '',
            valuation_app: 'url.valuation_app',
            valuation_app_tooltip: '',
        },
        export: {
            by: 'export.by',
            by_tooltip: '',
            date: 'export.date',
            date_tooltip: '',
            reference: 'export.reference',
            reference_tooltip: '',
        },
        section: {
            reconstruction_value: 'section.reconstruction_value',
            reconstruction_value_tooltip: '',
            address_location: 'section.address_location',
            address_location_tooltip: '',
            secondary_buildings: 'section.secondary_buildings',
            secondary_buildings_tooltip: '',
            economic_activity: 'section.economic_activity',
            economic_activity_tooltip: '',
            roof: 'section.roof',
            roof_tooltip: '',
            transaction: 'section.transaction',
            transaction_tooltip: '',
            general: 'section.general',
            general_tooltip: '',
            report: 'section.report',
            report_tooltip: '',
            'valuation-app': 'section.valuation-app',
            'valuation-app_tooltip': '',
            dashboard: 'section.dashboard',
            dashboard_tooltip: '',
            'faq-search-results': 'section.faq-search-results',
            'faq-search-results_tooltip': '',
            warnings: 'section.warnings',
            warnings_tooltip: '',
            renovation: 'section.renovation',
            renovation_tooltip: '',
            general_info: 'section.general_info',
            general_info_tooltip: '',
            parcels_and_buildings: 'section.parcels_and_buildings',
            parcels_and_buildings_tooltip: '',
            c_parcels: 'section.c_parcels',
            c_parcels_tooltip: '',
            c_building: 'section.c_building',
            c_building_tooltip: '',
            building: 'section.building',
            building_tooltip: '',
            building_structure: 'section.building_structure',
            building_structure_tooltip: '',
            building_purpose_classification: 'section.building_purpose_classification',
            building_purpose_classification_tooltip: '',
            parcels: 'section.parcels',
            parcels_tooltip: '',
            surroundings: 'section.surroundings',
            surroundings_tooltip: '',
            valuations: 'section.valuations',
            valuations_tooltip: '',
            reference_properties_location: 'section.reference_properties_location',
            reference_properties_location_tooltip: '',
            reference_properties_characteristics:
                'section.reference_properties_characteristics',
            reference_properties_characteristics_tooltip: '',
            flood: 'section.flood',
            flood_tooltip: '',
            energy: 'section.energy',
            energy_tooltip: '',
            flood_simple: 'section.flood_simple',
            flood_simple_tooltip: '',
            faq: 'section.faq',
            faq_tooltip: '',
            questions_feedback: 'section.questions_feedback',
            questions_feedback_tooltip: '',
            contact_info: 'section.contact_info',
            contact_info_tooltip: '',
            disclaimer: 'section.disclaimer',
            disclaimer_tooltip: '',
            heating: 'section.heating',
            heating_tooltip: '',
            ventilation: 'section.ventilation',
            ventilation_tooltip: '',
            renewables: 'section.renewables',
            renewables_tooltip: '',
            additional_consumption: 'section.additional_consumption',
            additional_consumption_tooltip: '',
            consumers: 'section.consumers',
            consumers_tooltip: '',
            house_info: 'section.house_info',
            house_info_tooltip: '',
            consumption: 'section.consumption',
            consumption_tooltip: '',
            property_type: 'section.property_type',
            property_type_tooltip: '',
            address: 'section.address',
            address_tooltip: '',
            insulation: 'section.insulation',
            insulation_tooltip: '',
            house_information: 'section.house_information',
            house_information_tooltip: '',
        },
        front: {
            title: 'front.title',
            title_tooltip: '',
            core_title: 'front.core_title',
            core_title_tooltip: '',
        },
        features: {
            renovation_cost: 'features.renovation_cost',
            renovation_cost_tooltip: '',
            f_building_type: 'features.f_building_type',
            f_building_type_tooltip: 'features.f_building_type_tooltip',
            f_number_of_facades: 'features.f_number_of_facades',
            f_number_of_facades_tooltip: '',
            f_parcel_area: 'features.f_parcel_area',
            f_parcel_area_tooltip: '',
            f_building_area: 'features.f_building_area',
            f_building_area_tooltip: '',
            f_others: 'features.f_others',
            f_others_tooltip: '',
            f_x_annexes: 'features.f_x_annexes',
            f_x_annexes_tooltip: '',
            f_annexes_area: 'features.f_annexes_area',
            f_annexes_area_tooltip: '',
            f_area_largest_annex: 'features.f_area_largest_annex',
            f_area_largest_annex_tooltip: '',
            parcel_ids: 'features.parcel_ids',
            parcel_ids_tooltip: '',
            f_lng: 'features.f_lng',
            f_lng_tooltip: '',
            f_lat: 'features.f_lat',
            f_lat_tooltip: '',
            f_number_of_addresses: 'features.f_number_of_addresses',
            f_number_of_addresses_tooltip: '',
            f_living_area: 'features.f_living_area',
            f_living_area_tooltip: 'features.f_living_area_tooltip',
            f_expected_living_area: 'features.f_expected_living_area',
            f_expected_living_area_tooltip: 'features.f_expected_living_area_tooltip',
            f_approx_living_area: 'features.f_approx_living_area',
            f_approx_living_area_tooltip: '',
            n_rooms: 'features.n_rooms',
            n_rooms_tooltip: '',
            approx_n_rooms: 'features.approx_n_rooms',
            approx_n_rooms_tooltip: '',
            transaction_value: 'features.transaction_value',
            transaction_value_tooltip: '',
            transaction_date: 'features.transaction_date',
            transaction_date_tooltip: '',
            approx_price_sqm: 'features.approx_price_sqm',
            approx_price_sqm_tooltip: '',
            co2_sqm: 'features.co2_sqm',
            co2_sqm_tooltip: '',
            co2_label: 'features.co2_label',
            co2_label_tooltip: '',
            f_approx_epc: 'features.f_approx_epc',
            f_approx_epc_tooltip: '',
            f_construction_year: 'features.f_construction_year',
            f_construction_year_tooltip: 'features.f_construction_year_tooltip',
            f_expected_construction_year: 'features.f_expected_construction_year',
            f_expected_construction_year_tooltip:
                'features.f_expected_construction_year_tooltip',
            f_epc: 'features.f_epc',
            f_epc_tooltip: 'features.f_epc_tooltip',
            f_epc_label: 'features.f_epc_label',
            f_epc_label_tooltip: '',
            f_epc_numeric: 'features.f_epc_numeric',
            f_epc_numeric_tooltip: '',
            f_expected_epc: 'features.f_expected_epc',
            f_expected_epc_tooltip: 'features.f_expected_epc_tooltip',
            f_bedrooms: 'features.f_bedrooms',
            f_bedrooms_tooltip: 'features.f_bedrooms_tooltip',
            f_floor: 'features.f_floor',
            f_floor_tooltip: 'features.f_floor_tooltip',
            f_bottom_floor: 'features.f_bottom_floor',
            f_bottom_floor_tooltip: 'features.f_bottom_floor_tooltip',
            f_top_floor: 'features.f_top_floor',
            f_top_floor_tooltip: 'features.f_top_floor_tooltip',
            f_floors: 'features.f_floors',
            f_floors_tooltip: 'features.f_floors_tooltip',
            f_balcony_present: 'features.f_balcony_present',
            f_balcony_present_tooltip: 'features.f_balcony_present_tooltip',
            f_garage_present: 'features.f_garage_present',
            f_garage_present_tooltip: 'features.f_garage_present_tooltip',
            f_n_closed_garage: 'features.f_n_closed_garage',
            f_n_closed_garage_tooltip: 'features.f_n_closed_garage_tooltip',
            f_n_parking_spot: 'features.f_n_parking_spot',
            f_n_parking_spot_tooltip: 'features.f_n_parking_spot_tooltip',
            f_cellar_attic: 'features.f_cellar_attic',
            f_cellar_attic_tooltip: '',
            f_garden_access: 'features.f_garden_access',
            f_garden_access_tooltip: 'features.f_garden_access_tooltip',
            f_garden_common: 'features.f_garden_common',
            f_garden_common_tooltip: 'features.f_garden_common_tooltip',
            f_garden_private: 'features.f_garden_private',
            f_garden_private_tooltip: 'features.f_garden_private_tooltip',
            f_volume: 'features.f_volume',
            f_volume_tooltip: '',
            f_roof_area: 'features.f_roof_area',
            f_roof_area_tooltip: '',
            f_mean_tilt: 'features.f_mean_tilt',
            f_mean_tilt_tooltip: '',
            f_percent_of_roof_flat: 'features.f_percent_of_roof_flat',
            f_percent_of_roof_flat_tooltip: '',
            f_min_height: 'features.f_min_height',
            f_min_height_tooltip: '',
            f_max_height: 'features.f_max_height',
            f_max_height_tooltip: '',
            f_n_parts: 'features.f_n_parts',
            f_n_parts_tooltip: '',
            f_n_roof_panes: 'features.f_n_roof_panes',
            f_n_roof_panes_tooltip: '',
            f_x_matched_entities: 'features.f_x_matched_entities',
            f_x_matched_entities_tooltip: '',
            c_economic_activity_class: 'features.c_economic_activity_class',
            c_economic_activity_class_tooltip: '',
            c_economic_activity_level: 'features.c_economic_activity_level',
            c_economic_activity_level_tooltip: '',
            f_garden_area: 'features.f_garden_area',
            f_garden_area_tooltip: '',
            f_distance_to_street: 'features.f_distance_to_street',
            f_distance_to_street_tooltip: '',
            f_dist_building_flood: 'features.f_dist_building_flood',
            f_dist_building_flood_tooltip: '',
            f_dist_building_flood_insurance_exclusion:
                'features.f_dist_building_flood_insurance_exclusion',
            f_dist_building_flood_insurance_exclusion_tooltip: '',
            f_flood_risk: 'features.f_flood_risk',
            f_flood_risk_tooltip: '',
            f_flood_risk_insurance_exclusion: 'features.f_flood_risk_insurance_exclusion',
            f_flood_risk_insurance_exclusion_tooltip: '',
            f_flood_risk_composite: 'features.f_flood_risk_composite',
            f_flood_risk_composite_tooltip: '',
            level: 'features.level',
            level_tooltip: '',
            f_ground_height_above_sea: 'features.f_ground_height_above_sea',
            f_ground_height_above_sea_tooltip: '',
            property_type: 'features.property_type',
            property_type_tooltip: '',
            insulated_floors: 'features.insulated_floors',
            insulated_floors_tooltip: '',
            insulated_roof: 'features.insulated_roof',
            insulated_roof_tooltip: '',
            insulated_walls: 'features.insulated_walls',
            insulated_walls_tooltip: '',
            window_type: 'features.window_type',
            window_type_tooltip: '',
            has_attic: 'features.has_attic',
            has_attic_tooltip: '',
            floors_insulation_years: 'features.floors_insulation_years',
            floors_insulation_years_tooltip: '',
            roof_insulation_year: 'features.roof_insulation_year',
            roof_insulation_year_tooltip: '',
            walls_insulation_year: 'features.walls_insulation_year',
            walls_insulation_year_tooltip: '',
            has_basement: 'features.has_basement',
            has_basement_tooltip: '',
            is_attic_heated: 'features.is_attic_heated',
            is_attic_heated_tooltip: '',
            epc_score: 'features.epc_score',
            epc_score_tooltip: '',
            heating_fuel: 'features.heating_fuel',
            heating_fuel_tooltip: '',
            heating_type: 'features.heating_type',
            heating_type_tooltip: '',
            heating_installation_year: 'features.heating_installation_year',
            heating_installation_year_tooltip: '',
            ventilation_type: 'features.ventilation_type',
            ventilation_type_tooltip: '',
            solar_boiler: 'features.solar_boiler',
            solar_boiler_tooltip: '',
            solar_panels: 'features.solar_panels',
            solar_panels_tooltip: '',
            battery: 'features.battery',
            battery_tooltip: '',
            air_conditioner: 'features.air_conditioner',
            air_conditioner_tooltip: '',
            spa_room: 'features.spa_room',
            spa_room_tooltip: '',
            swimming_pool: 'features.swimming_pool',
            swimming_pool_tooltip: '',
            electric_vehicle: 'features.electric_vehicle',
            electric_vehicle_tooltip: '',
            n_residents: 'features.n_residents',
            n_residents_tooltip: '',
            protected_consumer: 'features.protected_consumer',
            protected_consumer_tooltip: '',
            yearly_electricity_consumption: 'features.yearly_electricity_consumption',
            yearly_electricity_consumption_tooltip: '',
            gas_consumption_approx: 'features.gas_consumption_approx',
            gas_consumption_approx_tooltip: '',
        },
        disclaimer: {
            avm_tooltip: '',
            dvm: 'disclaimer.dvm',
            dvm_tooltip: '',
            ovm: 'disclaimer.ovm',
            ovm_tooltip: '',
            part_1: 'disclaimer.part_1',
            part_1_tooltip: '',
            part_2: 'disclaimer.part_2',
            part_2_tooltip: '',
            reconstruction_value_title: 'disclaimer.reconstruction_value_title',
            reconstruction_value_title_tooltip: '',
            reconstruction_value: 'disclaimer.reconstruction_value',
            reconstruction_value_tooltip: '',
            part_3: 'disclaimer.part_3',
            part_3_tooltip: '',
        },
        info: {
            data_from_customer: 'info.data_from_customer',
            data_from_customer_tooltip: '',
        },
        dvm_features: {
            style_of_house: 'dvm_features.style_of_house',
            style_of_house_tooltip: '',
            exterior_state: 'dvm_features.exterior_state',
            exterior_state_tooltip: '',
            facade_material: 'dvm_features.facade_material',
            facade_material_tooltip: '',
            roof_material: 'dvm_features.roof_material',
            roof_material_tooltip: '',
            n_roof_windows: 'dvm_features.n_roof_windows',
            n_roof_windows_tooltip: '',
            n_roof_dormers: 'dvm_features.n_roof_dormers',
            n_roof_dormers_tooltip: '',
            solar_panel_area: 'dvm_features.solar_panel_area',
            solar_panel_area_tooltip: '',
            f_building_listed: 'dvm_features.f_building_listed',
            f_building_listed_tooltip: '',
            garden_orientation: 'dvm_features.garden_orientation',
            garden_orientation_tooltip: '',
            garden_arrangement: 'dvm_features.garden_arrangement',
            garden_arrangement_tooltip: '',
            f_swimming_pool: 'dvm_features.f_swimming_pool',
            f_swimming_pool_tooltip: '',
            f_swimming_pool_area: 'dvm_features.f_swimming_pool_area',
            f_swimming_pool_area_tooltip: '',
            direct_traffic_level: 'dvm_features.direct_traffic_level',
            direct_traffic_level_tooltip: '',
            indirect_traffic_level: 'dvm_features.indirect_traffic_level',
            indirect_traffic_level_tooltip: '',
            surroundings: 'dvm_features.surroundings',
            surroundings_tooltip: '',
            other_positive: 'dvm_features.other_positive',
            other_positive_tooltip: '',
            other_negative: 'dvm_features.other_negative',
            other_negative_tooltip: '',
            remarks: 'dvm_features.remarks',
            remarks_tooltip: '',
            internal_remarks: 'dvm_features.internal_remarks',
            internal_remarks_tooltip: '',
        },
        '': {
            'clarify here': '.clarify here',
            'clarify here_tooltip': '',
        },
        fixed: {
            parcels_and_building_shapes: 'fixed.parcels_and_building_shapes',
            parcels_and_building_shapes_tooltip: '',
        },
        footnote: {
            no_renovation_included: 'footnote.no_renovation_included',
            no_renovation_included_tooltip: '',
            avm: 'footnote.avm',
            avm_tooltip: '',
            q25: 'footnote.q25',
            q25_tooltip: '',
            q75: 'footnote.q75',
            q75_tooltip: '',
        },
        reference_properties_location: {
            explanation_1: 'reference_properties_location.explanation_1',
            explanation_1_tooltip: '',
            explanation_2: 'reference_properties_location.explanation_2',
            explanation_2_tooltip: '',
        },
        reference_properties_characteristics: {
            explanation_1: 'reference_properties_characteristics.explanation_1',
            explanation_1_tooltip: '',
            property_characteristics:
                'reference_properties_characteristics.property_characteristics',
            property_characteristics_tooltip: '',
            properties_in_the_area:
                'reference_properties_characteristics.properties_in_the_area',
            properties_in_the_area_tooltip: '',
        },
        flood: {
            explanation_1: 'flood.explanation_1',
            explanation_1_tooltip: '',
        },
        faq: {
            q_incorrect_valuation: 'faq.q_incorrect_valuation',
            q_incorrect_valuation_tooltip: '',
            a_incorrect_valuation: 'faq.a_incorrect_valuation',
            a_incorrect_valuation_tooltip: '',
            q_incorrect_characteristics: 'faq.q_incorrect_characteristics',
            q_incorrect_characteristics_tooltip: '',
            a_incorrect_characteristics: 'faq.a_incorrect_characteristics',
            a_incorrect_characteristics_tooltip: '',
            q_transaction_vs_market_value: 'faq.q_transaction_vs_market_value',
            q_transaction_vs_market_value_tooltip: '',
            a_transaction_vs_market_value_dvm: 'faq.a_transaction_vs_market_value_dvm',
            a_transaction_vs_market_value_dvm_tooltip: '',
            a_transaction_vs_market_value_ovm: 'faq.a_transaction_vs_market_value_ovm',
            a_transaction_vs_market_value_ovm_tooltip: '',
            q_loan_accepted: 'faq.q_loan_accepted',
            q_loan_accepted_tooltip: '',
            a_loan_accepted: 'faq.a_loan_accepted',
            a_loan_accepted_tooltip: '',
            'where-bryan_tooltip': '',
            'where-bryan_question': 'faq.where-bryan_question',
            'where-bryan_answer': 'faq.where-bryan_answer',
            'internet-down_tooltip': '',
            'internet-down_question': 'faq.internet-down_question',
            'internet-down_answer': 'faq.internet-down_answer',
            'where-dog_tooltip': '',
            'where-dog_question': 'faq.where-dog_question',
            'where-dog_answer': 'faq.where-dog_answer',
            'what-is-avm_tooltip': '',
            'what-is-avm_question': 'faq.what-is-avm_question',
            'what-is-avm_answer': 'faq.what-is-avm_answer',
            'what-is-dvm_tooltip': '',
            'what-is-dvm_question': 'faq.what-is-dvm_question',
            'what-is-dvm_answer': 'faq.what-is-dvm_answer',
            'what-is-ovm_tooltip': '',
            'what-is-ovm_question': 'faq.what-is-ovm_question',
            'what-is-ovm_answer': 'faq.what-is-ovm_answer',
            'what-is-dashboard_tooltip': '',
            'what-is-dashboard_question': 'faq.what-is-dashboard_question',
            'what-is-dashboard_answer': 'faq.what-is-dashboard_answer',
            'what-is-draft_tooltip': '',
            'what-is-draft_question': 'faq.what-is-draft_question',
            'what-is-draft_answer': 'faq.what-is-draft_answer',
            'market-price-incorrect_tooltip': '',
            'market-price-incorrect_question': 'faq.market-price-incorrect_question',
            'market-price-incorrect_answer': 'faq.market-price-incorrect_answer',
            'issue-parcels_tooltip': '',
            'issue-parcels_question': 'faq.issue-parcels_question',
            'issue-parcels_answer': 'faq.issue-parcels_answer',
            'old-picture-or-3d_tooltip': '',
            'old-picture-or-3d_question': 'faq.old-picture-or-3d_question',
            'old-picture-or-3d_answer': 'faq.old-picture-or-3d_answer',
            'report-language_tooltip': '',
            'report-language_question': 'faq.report-language_question',
            'report-language_answer': 'faq.report-language_answer',
            'wrong-pictures_tooltip': '',
            'wrong-pictures_question': 'faq.wrong-pictures_question',
            'wrong-pictures_answer': 'faq.wrong-pictures_answer',
            sharing_tooltip: '',
            sharing_question: 'faq.sharing_question',
            sharing_answer: 'faq.sharing_answer',
            'remarks-language_tooltip': '',
            'remarks-language_question': 'faq.remarks-language_question',
            'remarks-language_answer': 'faq.remarks-language_answer',
            'appeal-missing-feature_tooltip': '',
            'appeal-missing-feature_question': 'faq.appeal-missing-feature_question',
            'appeal-missing-feature_answer': 'faq.appeal-missing-feature_answer',
            'where-customer-ref_tooltip': '',
            'where-customer-ref_question': 'faq.where-customer-ref_question',
            'where-customer-ref_answer': 'faq.where-customer-ref_answer',
            'building-type-apartment-building_tooltip': '',
            'building-type-apartment-building_question':
                'faq.building-type-apartment-building_question',
            'building-type-apartment-building_answer':
                'faq.building-type-apartment-building_answer',
            'address-not-found_tooltip': '',
            'address-not-found_question': 'faq.address-not-found_question',
            'address-not-found_answer': 'faq.address-not-found_answer',
            'building-not-found_tooltip': '',
            'building-not-found_question': 'faq.building-not-found_question',
            'building-not-found_answer': 'faq.building-not-found_answer',
            'find-epc_tooltip': '',
            'find-epc_question': 'faq.find-epc_question',
            'find-epc_answer': 'faq.find-epc_answer',
            'epc-label-vs-number_tooltip': '',
            'epc-label-vs-number_question': 'faq.epc-label-vs-number_question',
            'epc-label-vs-number_answer': 'faq.epc-label-vs-number_answer',
            'find-living-area_tooltip': '',
            'find-living-area_question': 'faq.find-living-area_question',
            'find-living-area_answer': 'faq.find-living-area_answer',
            'find-box-number_tooltip': '',
            'find-box-number_question': 'faq.find-box-number_question',
            'find-box-number_answer': 'faq.find-box-number_answer',
            'find-construction-year_tooltip': '',
            'find-construction-year_question': 'faq.find-construction-year_question',
            'find-construction-year_answer': 'faq.find-construction-year_answer',
            'construction-vs-renovation_tooltip': '',
            'construction-vs-renovation_question':
                'faq.construction-vs-renovation_question',
            'construction-vs-renovation_answer': 'faq.construction-vs-renovation_answer',
            'over-two-days_tooltip': '',
            'over-two-days_question': 'faq.over-two-days_question',
            'over-two-days_answer': 'faq.over-two-days_answer',
            'right-info-flagged-incorrect_tooltip': '',
            'right-info-flagged-incorrect_question':
                'faq.right-info-flagged-incorrect_question',
            'right-info-flagged-incorrect_answer':
                'faq.right-info-flagged-incorrect_answer',
            'incoherent-submitted-twice_tooltip': '',
            'incoherent-submitted-twice_question':
                'faq.incoherent-submitted-twice_question',
            'incoherent-submitted-twice_answer': 'faq.incoherent-submitted-twice_answer',
            'processing-time_tooltip': '',
            'processing-time_question': 'faq.processing-time_question',
            'processing-time_answer': 'faq.processing-time_answer',
            'multiple-addresses_tooltip': '',
            'multiple-addresses_question': 'faq.multiple-addresses_question',
            'multiple-addresses_answer': 'faq.multiple-addresses_answer',
            'what-is-bedroom_tooltip': '',
            'what-is-bedroom_question': 'faq.what-is-bedroom_question',
            'what-is-bedroom_answer': 'faq.what-is-bedroom_answer',
            'what-is-garden_tooltip': '',
            'what-is-garden_question': 'faq.what-is-garden_question',
            'what-is-garden_answer': 'faq.what-is-garden_answer',
            'what-is-private-garden_tooltip': '',
            'what-is-private-garden_question': 'faq.what-is-private-garden_question',
            'what-is-private-garden_answer': 'faq.what-is-private-garden_answer',
            'what-is-closed-garage_tooltip': '',
            'what-is-closed-garage_question': 'faq.what-is-closed-garage_question',
            'what-is-closed-garage_answer': 'faq.what-is-closed-garage_answer',
            'what-is-parking-spot_tooltip': '',
            'what-is-parking-spot_question': 'faq.what-is-parking-spot_question',
            'what-is-parking-spot_answer': 'faq.what-is-parking-spot_answer',
            'what-is-cellar-basement_tooltip': '',
            'what-is-cellar-basement_question': 'faq.what-is-cellar-basement_question',
            'what-is-cellar-basement_answer': 'faq.what-is-cellar-basement_answer',
            carport_tooltip: '',
            carport_question: 'faq.carport_question',
            carport_answer: 'faq.carport_answer',
            'personal-use_tooltip': '',
            'personal-use_question': 'faq.personal-use_question',
            'personal-use_answer': 'faq.personal-use_answer',
            'is-request-submitted_tooltip': '',
            'is-request-submitted_question': 'faq.is-request-submitted_question',
            'is-request-submitted_answer': 'faq.is-request-submitted_answer',
            review_tooltip: '',
            review_question: 'faq.review_question',
            review_answer: 'faq.review_answer',
            'find-previous-request_tooltip': '',
            'find-previous-request_question': 'faq.find-previous-request_question',
            'find-previous-request_answer': 'faq.find-previous-request_answer',
            'download-report_tooltip': '',
            'download-report_question': 'faq.download-report_question',
            'download-report_answer': 'faq.download-report_answer',
            'language-impact_tooltip': '',
            'language-impact_question': 'faq.language-impact_question',
            'language-impact_answer': 'faq.language-impact_answer',
            'unsubmission-window_tooltip': '',
            'unsubmission-window_question': 'faq.unsubmission-window_question',
            'unsubmission-window_answer': 'faq.unsubmission-window_answer',
            'after-sixty-minutes_tooltip': '',
            'after-sixty-minutes_question': 'faq.after-sixty-minutes_question',
            'after-sixty-minutes_answer': 'faq.after-sixty-minutes_answer',
            'review-billing_tooltip': '',
            'review-billing_question': 'faq.review-billing_question',
            'review-billing_answer': 'faq.review-billing_answer',
            'on-site-next-steps_tooltip': '',
            'on-site-next-steps_question': 'faq.on-site-next-steps_question',
            'on-site-next-steps_answer': 'faq.on-site-next-steps_answer',
            'access-requests_tooltip': '',
            'access-requests_question': 'faq.access-requests_question',
            'access-requests_answer': 'faq.access-requests_answer',
            substitution_tooltip: '',
            substitution_question: 'faq.substitution_question',
        },
        questions_feedback: {
            explanation_1: 'questions_feedback.explanation_1',
            explanation_1_tooltip: '',
            explanation_2: 'questions_feedback.explanation_2',
            explanation_2_tooltip: '',
        },
        email: {
            submission_confirmation_subject: 'email.submission_confirmation_subject',
            submission_confirmation_subject_tooltip: '',
            submission_confirmation_1: 'email.submission_confirmation_1',
            submission_confirmation_1_tooltip: '',
            submission_confirmation_2: 'email.submission_confirmation_2',
            submission_confirmation_2_tooltip: '',
            valuation_notification_subject: 'email.valuation_notification_subject',
            valuation_notification_subject_tooltip: '',
            valuation_notification_1: 'email.valuation_notification_1',
            valuation_notification_1_tooltip: '',
            valuation_notification_2: 'email.valuation_notification_2',
            valuation_notification_2_tooltip: '',
            postpone_subject: 'email.postpone_subject',
            postpone_subject_tooltip: '',
            postpone_1: 'email.postpone_1',
            postpone_1_tooltip: '',
            postpone_2: 'email.postpone_2',
            postpone_2_tooltip: '',
            postpone_3: 'email.postpone_3',
            postpone_3_tooltip: '',
            incoherent_features_subject: 'email.incoherent_features_subject',
            incoherent_features_subject_tooltip: '',
            incoherent_features_1: 'email.incoherent_features_1',
            incoherent_features_1_tooltip: '',
            incoherent_features_2: 'email.incoherent_features_2',
            incoherent_features_2_tooltip: '',
            incoherent_features_3: 'email.incoherent_features_3',
            incoherent_features_3_tooltip: '',
            incoherent_features_4: 'email.incoherent_features_4',
            incoherent_features_4_tooltip: '',
            owner_updated_subject: 'email.owner_updated_subject',
            owner_updated_subject_tooltip: '',
            owner_updated_1: 'email.owner_updated_1',
            owner_updated_1_tooltip: '',
            owner_updated_2: 'email.owner_updated_2',
            owner_updated_2_tooltip: '',
            on_site_requested_value: 'email.on_site_requested_value',
            on_site_requested_value_tooltip: '',
            argenta: {
                on_site_requested_characteristics_1:
                    'email.argenta.on_site_requested_characteristics_1',
                on_site_requested_characteristics_1_tooltip: '',
                on_site_requested_characteristics_2:
                    'email.argenta.on_site_requested_characteristics_2',
                on_site_requested_characteristics_2_tooltip: '',
                on_site_requested_characteristics_subject:
                    'email.argenta.on_site_requested_characteristics_subject',
                on_site_requested_characteristics_subject_tooltip: '',
            },
            onsite_request_subject: 'email.onsite_request_subject',
            onsite_request_subject_tooltip: '',
            onsite_request_1: 'email.onsite_request_1',
            onsite_request_1_tooltip: '',
            onsite_request_2: 'email.onsite_request_2',
            onsite_request_2_tooltip: '',
            address_not_found: 'email.address_not_found',
            address_not_found_tooltip: '',
            onsite_streetview: 'email.onsite_streetview',
            onsite_streetview_tooltip: '',
            onsite_incoherent_features: 'email.onsite_incoherent_features',
            onsite_incoherent_features_tooltip: '',
            onsite_wrong_building_selected: 'email.onsite_wrong_building_selected',
            onsite_wrong_building_selected_tooltip: '',
            onsite_exceptional_property: 'email.onsite_exceptional_property',
            onsite_exceptional_property_tooltip: '',
            onsite_commercial_building: 'email.onsite_commercial_building',
            onsite_commercial_building_tooltip: '',
            onsite_volume_missing: 'email.onsite_volume_missing',
            onsite_volume_missing_tooltip: '',
            onsite_flood_zone: 'email.onsite_flood_zone',
            onsite_flood_zone_tooltip: '',
            onsite_few_comparison: 'email.onsite_few_comparison',
            onsite_few_comparison_tooltip: '',
            onsite_extensive_renovation: 'email.onsite_extensive_renovation',
            onsite_extensive_renovation_tooltip: '',
        },
        common: {
            cancel: 'common.cancel',
            cancel_tooltip: '',
            save: 'common.save',
            save_tooltip: '',
            edit: 'common.edit',
            edit_tooltip: '',
            total: 'common.total',
            total_tooltip: '',
            previous: 'common.previous',
            previous_tooltip: '',
            next: 'common.next',
            next_tooltip: '',
            uploading: 'common.uploading',
            uploading_tooltip: '',
            thank_you_so_far: 'common.thank_you_so_far',
            thank_you_so_far_tooltip: '',
            yes: 'common.yes',
            yes_tooltip: '',
            no: 'common.no',
            no_tooltip: '',
            number_input: 'common.number_input',
            number_input_tooltip: '',
            multiple_choice: 'common.multiple_choice',
            multiple_choice_tooltip: '',
            we_are_sorry: 'common.we_are_sorry',
            we_are_sorry_tooltip: '',
            sorry: 'common.sorry',
            sorry_tooltip: '',
            enter_address: 'common.enter_address',
            enter_address_tooltip: '',
            back_to_ing: 'common.back_to_ing',
            back_to_ing_tooltip: '',
            maintenance: 'common.maintenance',
            maintenance_tooltip: '',
            send: 'common.send',
            send_tooltip: '',
            start_now: 'common.start_now',
            start_now_tooltip: '',
            i_dont_know: 'common.i_dont_know',
            i_dont_know_tooltip: '',
        },
        renovation_info: {
            title: 'renovation_info.title',
            title_tooltip: '',
            add_document: 'renovation_info.add_document',
            add_document_tooltip: '',
            unlink_document: 'renovation_info.unlink_document',
            unlink_document_tooltip: '',
            link_document: 'renovation_info.link_document',
            link_document_tooltip: '',
            items: 'renovation_info.items',
            items_tooltip: '',
            linked_items: 'renovation_info.linked_items',
            linked_items_tooltip: '',
        },
        renovation_item: {
            title: 'renovation_item.title',
            title_tooltip: '',
            amount: 'renovation_item.amount',
            amount_tooltip: '',
            category: 'renovation_item.category',
            category_tooltip: '',
            subcategory: 'renovation_item.subcategory',
            subcategory_tooltip: '',
            modal_title: 'renovation_item.modal_title',
            modal_title_tooltip: '',
        },
        renovation_category: {
            energy: 'renovation_category.energy',
            energy_tooltip: '',
            structural: 'renovation_category.structural',
            structural_tooltip: '',
            aesthetic: 'renovation_category.aesthetic',
            aesthetic_tooltip: '',
            other: 'renovation_category.other',
            other_tooltip: '',
        },
        renovation_subcategory: {
            insulation: 'renovation_subcategory.insulation',
            insulation_tooltip: '',
            solar_panel: 'renovation_subcategory.solar_panel',
            solar_panel_tooltip: '',
            heating: 'renovation_subcategory.heating',
            heating_tooltip: '',
            other: 'renovation_subcategory.other',
            other_tooltip: '',
            wall_extension: 'renovation_subcategory.wall_extension',
            wall_extension_tooltip: '',
            demolition: 'renovation_subcategory.demolition',
            demolition_tooltip: '',
            kitchen: 'renovation_subcategory.kitchen',
            kitchen_tooltip: '',
            bathroom: 'renovation_subcategory.bathroom',
            bathroom_tooltip: '',
        },
        steps: {
            building_type: 'steps.building_type',
            building_type_tooltip: 'steps.building_type_tooltip',
            address: 'steps.address',
            address_tooltip: '',
            address_confirmation: 'steps.address_confirmation',
            address_confirmation_tooltip: 'steps.address_confirmation_tooltip',
            building_selection: 'steps.building_selection',
            building_selection_tooltip: '',
            house_info: 'steps.house_info',
            house_info_tooltip: '',
            epc: 'steps.epc',
            epc_tooltip: 'steps.epc_tooltip',
            f_construction_year: 'steps.f_construction_year',
            f_construction_year_tooltip: 'steps.f_construction_year_tooltip',
            living_area: 'steps.living_area',
            living_area_tooltip: 'steps.living_area_tooltip',
            insulation: 'steps.insulation',
            insulation_tooltip: '',
            walls: 'steps.walls',
            walls_tooltip: '',
            roof: 'steps.roof',
            roof_tooltip: '',
            floor: 'steps.floor',
            floor_tooltip: '',
            windows: 'steps.windows',
            windows_tooltip: '',
            attic: 'steps.attic',
            attic_tooltip: '',
            basement: 'steps.basement',
            basement_tooltip: '',
            consumption: 'steps.consumption',
            consumption_tooltip: '',
            heating: 'steps.heating',
            heating_tooltip: '',
            electricity_consumption: 'steps.electricity_consumption',
            electricity_consumption_tooltip: '',
            gas_consumption: 'steps.gas_consumption',
            gas_consumption_tooltip: '',
            oil_consumption: 'steps.oil_consumption',
            oil_consumption_tooltip: '',
            coal_consumption: 'steps.coal_consumption',
            coal_consumption_tooltip: '',
            wood_consumption: 'steps.wood_consumption',
            wood_consumption_tooltip: '',
            pellets_consumption: 'steps.pellets_consumption',
            pellets_consumption_tooltip: '',
            district_heating_consumption: 'steps.district_heating_consumption',
            district_heating_consumption_tooltip: '',
            solar_panels: 'steps.solar_panels',
            solar_panels_tooltip: '',
            ventilation: 'steps.ventilation',
            ventilation_tooltip: '',
            consumers: 'steps.consumers',
            consumers_tooltip: '',
            electric_vehicles: 'steps.electric_vehicles',
            electric_vehicles_tooltip: '',
            processing: 'steps.processing',
            processing_tooltip: '',
        },
        property_type: {
            apartments: 'property_type.apartments',
            apartments_tooltip: '',
            coming_soon: 'property_type.coming_soon',
            coming_soon_tooltip: '',
            select_type: 'property_type.select_type',
            select_type_tooltip: '',
            title: 'property_type.title',
            title_tooltip: '',
        },
        out_of_bounds_page: {
            title: 'out_of_bounds_page.title',
            title_tooltip: '',
            content_tooltip: '',
            button: 'out_of_bounds_page.button',
            button_tooltip: '',
            ing_button: 'out_of_bounds_page.ing_button',
            ing_button_tooltip: '',
        },
        terms_page: {
            welcome: 'terms_page.welcome',
            welcome_tooltip: '',
            epc: 'terms_page.epc',
            epc_tooltip: '',
            disclaimer: 'terms_page.disclaimer',
            disclaimer_tooltip: '',
        },
        features_question: {
            insulated_floors: 'features_question.insulated_floors',
            insulated_floors_tooltip: '',
            insulated_roof: 'features_question.insulated_roof',
            insulated_roof_tooltip: '',
            insulated_walls: 'features_question.insulated_walls',
            insulated_walls_tooltip: '',
            has_attic: 'features_question.has_attic',
            has_attic_tooltip: '',
            insulation_floors_when: 'features_question.insulation_floors_when',
            insulation_floors_when_tooltip: '',
            insulation_roof_when: 'features_question.insulation_roof_when',
            insulation_roof_when_tooltip: '',
            insulation_walls_when: 'features_question.insulation_walls_when',
            insulation_walls_when_tooltip: '',
            has_basement: 'features_question.has_basement',
            has_basement_tooltip: '',
            is_attic_heated: 'features_question.is_attic_heated',
            is_attic_heated_tooltip: '',
            epc_known: 'features_question.epc_known',
            epc_known_tooltip: '',
            select_heating_fuel: 'features_question.select_heating_fuel',
            select_heating_fuel_tooltip: '',
            ventilation_type: 'features_question.ventilation_type',
            ventilation_type_tooltip: '',
            ventilation_subtype: 'features_question.ventilation_subtype',
            ventilation_subtype_tooltip: '',
            n_residents: 'features_question.n_residents',
            n_residents_tooltip: '',
            protected_consumer: 'features_question.protected_consumer',
            protected_consumer_tooltip: '',
            heating_consumption_approx: 'features_question.heating_consumption_approx',
            heating_consumption_approx_tooltip: '',
            electric_consumption_approx: 'features_question.electric_consumption_approx',
            electric_consumption_approx_tooltip: '',
            installation_year: 'features_question.installation_year',
            installation_year_tooltip: '',
            heater: 'features_question.heater',
            heater_tooltip: '',
            consumption_known: 'features_question.consumption_known',
            consumption_known_tooltip: 'features_question.consumption_known_tooltip',
            yearly_electricity_consumption:
                'features_question.yearly_electricity_consumption',
            yearly_electricity_consumption_tooltip:
                'features_question.yearly_electricity_consumption_tooltip',
            yearly_fuel_consumption: 'features_question.yearly_fuel_consumption',
            yearly_fuel_consumption_tooltip:
                'features_question.yearly_fuel_consumption_tooltip',
            yearly_heating_consumption: 'features_question.yearly_heating_consumption',
            yearly_heating_consumption_tooltip: '',
            f_construction_year: 'features_question.f_construction_year',
            f_construction_year_tooltip: '',
            f_living_area: 'features_question.f_living_area',
            f_living_area_tooltip: 'features_question.f_living_area_tooltip',
            solar_panels: 'features_question.solar_panels',
            solar_panels_tooltip: '',
            insulated_windows: 'features_question.insulated_windows',
            insulated_windows_tooltip: '',
            insulated_single_renovated: 'features_question.insulated_single_renovated',
            insulated_single_renovated_tooltip: '',
            insulated_plural_renovated: 'features_question.insulated_plural_renovated',
            insulated_plural_renovated_tooltip: '',
            gas_consumption_known: 'features_question.gas_consumption_known',
            gas_consumption_known_tooltip: '',
            oil_consumption_known: 'features_question.oil_consumption_known',
            oil_consumption_known_tooltip: '',
            wood_consumption_known: 'features_question.wood_consumption_known',
            wood_consumption_known_tooltip: '',
            coal_consumption_known: 'features_question.coal_consumption_known',
            coal_consumption_known_tooltip: '',
            pellets_consumption_known: 'features_question.pellets_consumption_known',
            pellets_consumption_known_tooltip: '',
            district_heating_consumption_known:
                'features_question.district_heating_consumption_known',
            district_heating_consumption_known_tooltip: '',
            electricity_consumption_known:
                'features_question.electricity_consumption_known',
            electricity_consumption_known_tooltip: '',
            gas_consumption_approx: 'features_question.gas_consumption_approx',
            gas_consumption_approx_tooltip: '',
            oil_consumption_approx: 'features_question.oil_consumption_approx',
            oil_consumption_approx_tooltip: '',
            wood_consumption_approx: 'features_question.wood_consumption_approx',
            wood_consumption_approx_tooltip: '',
            coal_consumption_approx: 'features_question.coal_consumption_approx',
            coal_consumption_approx_tooltip: '',
            pellets_consumption_approx: 'features_question.pellets_consumption_approx',
            pellets_consumption_approx_tooltip: '',
            district_heating_consumption_approx:
                'features_question.district_heating_consumption_approx',
            district_heating_consumption_approx_tooltip: '',
            electricity_consumption_approx:
                'features_question.electricity_consumption_approx',
            electricity_consumption_approx_tooltip: '',
        },
        map_page: {
            '3d_model': 'map_page.3d_model',
            '3d_model_tooltip': '',
        },
        address_page: {
            title: 'address_page.title',
            title_tooltip: '',
            control_address: 'address_page.control_address',
            control_address_tooltip: '',
        },
        validation: {
            field_required: 'validation.field_required',
            field_required_tooltip: '',
            only_numbers: 'validation.only_numbers',
            only_numbers_tooltip: '',
            min_4_characters: 'validation.min_4_characters',
            min_4_characters_tooltip: '',
            wrong_epc: 'validation.wrong_epc',
            wrong_epc_tooltip: '',
            valid_years: 'validation.valid_years',
            valid_years_tooltip: '',
            value_too_large: 'validation.value_too_large',
            value_too_large_tooltip: '',
        },
        f_epc: {
            label: 'f_epc.label',
            label_tooltip: '',
        },
        features_page: {
            title: 'features_page.title',
            title_tooltip: '',
        },
        consumption_page: {
            title: 'consumption_page.title',
            title_tooltip: '',
            electric_vehicle: 'consumption_page.electric_vehicle',
            electric_vehicle_tooltip: '',
        },
        login_page: {
            login: 'login_page.login',
            login_tooltip: '',
        },
        welcome_page: {
            intro: 'welcome_page.intro',
            intro_tooltip: '',
            corpus_tooltip: '',
            disclaimer_tooltip: '',
            by_clicking: 'welcome_page.by_clicking',
            by_clicking_tooltip: '',
            terms: 'welcome_page.terms',
            terms_tooltip: '',
            curious: 'welcome_page.curious',
            curious_tooltip: '',
        },
        error_page: {
            title: 'error_page.title',
            title_tooltip: '',
            intro: 'error_page.intro',
            intro_tooltip: '',
            button: 'error_page.button',
            button_tooltip: '',
        },
        insulation: {
            title: 'insulation.title',
            title_tooltip: '',
        },
        consumption: {
            gas_consumption_title: 'consumption.gas_consumption_title',
            gas_consumption_title_tooltip: '',
            oil_consumption_title: 'consumption.oil_consumption_title',
            oil_consumption_title_tooltip: '',
            wood_consumption_title: 'consumption.wood_consumption_title',
            wood_consumption_title_tooltip: '',
            coal_consumption_title: 'consumption.coal_consumption_title',
            coal_consumption_title_tooltip: '',
            pellets_consumption_title: 'consumption.pellets_consumption_title',
            pellets_consumption_title_tooltip: '',
            district_heating_consumption_title:
                'consumption.district_heating_consumption_title',
            district_heating_consumption_title_tooltip: '',
            electricity_consumption_title: 'consumption.electricity_consumption_title',
            electricity_consumption_title_tooltip: '',
        },
        ventilation_page: {
            ventilation_title: 'ventilation_page.ventilation_title',
            ventilation_title_tooltip: '',
        },
        consumers_page: {
            title: 'consumers_page.title',
            title_tooltip: '',
        },
        energy_quizz: {
            solar: {
                qa: {
                    q: 'energy_quizz.solar.qa.q',
                    q_tooltip: '',
                    a1: 'energy_quizz.solar.qa.a1',
                    a1_tooltip: '',
                    a2: 'energy_quizz.solar.qa.a2',
                    a2_tooltip: '',
                    a3: 'energy_quizz.solar.qa.a3',
                    a3_tooltip: '',
                },
                qb: {
                    q: 'energy_quizz.solar.qb.q',
                    q_tooltip: '',
                    a1: 'energy_quizz.solar.qb.a1',
                    a1_tooltip: '',
                    a2: 'energy_quizz.solar.qb.a2',
                    a2_tooltip: '',
                    a3: 'energy_quizz.solar.qb.a3',
                    a3_tooltip: '',
                },
            },
            q1: {
                q: 'energy_quizz.q1.q',
                q_tooltip: '',
                a1: 'energy_quizz.q1.a1',
                a1_tooltip: '',
                f1: 'energy_quizz.q1.f1',
                f1_tooltip: '',
                a2: 'energy_quizz.q1.a2',
                a2_tooltip: '',
                f2: 'energy_quizz.q1.f2',
                f2_tooltip: '',
                a3: 'energy_quizz.q1.a3',
                a3_tooltip: '',
                f3: 'energy_quizz.q1.f3',
                f3_tooltip: '',
            },
            q2: {
                q: 'energy_quizz.q2.q',
                q_tooltip: '',
                a1: 'energy_quizz.q2.a1',
                a1_tooltip: '',
                f1: 'energy_quizz.q2.f1',
                f1_tooltip: '',
                a2: 'energy_quizz.q2.a2',
                a2_tooltip: '',
                f2: 'energy_quizz.q2.f2',
                f2_tooltip: '',
                a3: 'energy_quizz.q2.a3',
                a3_tooltip: '',
                f3: 'energy_quizz.q2.f3',
                f3_tooltip: '',
                a4: 'energy_quizz.q2.a4',
                a4_tooltip: '',
                f4: 'energy_quizz.q2.f4',
                f4_tooltip: '',
            },
            q3: {
                q: 'energy_quizz.q3.q',
                q_tooltip: '',
                a1: 'energy_quizz.q3.a1',
                a1_tooltip: '',
                f1: 'energy_quizz.q3.f1',
                f1_tooltip: '',
                a2: 'energy_quizz.q3.a2',
                a2_tooltip: '',
                f2: 'energy_quizz.q3.f2',
                f2_tooltip: '',
                a3: 'energy_quizz.q3.a3',
                a3_tooltip: '',
                f3: 'energy_quizz.q3.f3',
                f3_tooltip: '',
            },
            q4: {
                q: 'energy_quizz.q4.q',
                q_tooltip: '',
                a1: 'energy_quizz.q4.a1',
                a1_tooltip: '',
                f1: 'energy_quizz.q4.f1',
                f1_tooltip: '',
                a2: 'energy_quizz.q4.a2',
                a2_tooltip: '',
                f2: 'energy_quizz.q4.f2',
                f2_tooltip: '',
                a3: 'energy_quizz.q4.a3',
                a3_tooltip: '',
                f3: 'energy_quizz.q4.f3',
                f3_tooltip: '',
            },
            q5: {
                q: 'energy_quizz.q5.q',
                q_tooltip: '',
                a1: 'energy_quizz.q5.a1',
                a1_tooltip: '',
                f1: 'energy_quizz.q5.f1',
                f1_tooltip: '',
                a2: 'energy_quizz.q5.a2',
                a2_tooltip: '',
                f2: 'energy_quizz.q5.f2',
                f2_tooltip: '',
                a3: 'energy_quizz.q5.a3',
                a3_tooltip: '',
                f3: 'energy_quizz.q5.f3',
                f3_tooltip: '',
            },
            q6: {
                q: 'energy_quizz.q6.q',
                q_tooltip: '',
                a1: 'energy_quizz.q6.a1',
                a1_tooltip: '',
                f1: 'energy_quizz.q6.f1',
                f1_tooltip: '',
                a2: 'energy_quizz.q6.a2',
                a2_tooltip: '',
                f2: 'energy_quizz.q6.f2',
                f2_tooltip: '',
                a3: 'energy_quizz.q6.a3',
                a3_tooltip: '',
                f3: 'energy_quizz.q6.f3',
                f3_tooltip: '',
            },
            discovery_phase: 'energy_quizz.discovery_phase',
            discovery_phase_tooltip: '',
            discovery_description: 'energy_quizz.discovery_description',
            discovery_description_tooltip: '',
            learning_phase: 'energy_quizz.learning_phase',
            learning_phase_tooltip: '',
            learning_description: 'energy_quizz.learning_description',
            learning_description_tooltip: '',
            expert_phase: 'energy_quizz.expert_phase',
            expert_phase_tooltip: '',
            expert_description: 'energy_quizz.expert_description',
            expert_description_tooltip: '',
        },
        ers_report: {
            language: 'ers_report.language',
            language_tooltip: '',
            header_title: 'ers_report.header_title',
            header_title_tooltip: '',
            header_subtitle: 'ers_report.header_subtitle',
            header_subtitle_tooltip: '',
            header_subtitle_pdf: 'ers_report.header_subtitle_pdf',
            header_subtitle_pdf_tooltip: '',
            epc_graph_title: 'ers_report.epc_graph_title',
            epc_graph_title_tooltip: '',
            consumption_title: 'ers_report.consumption_title',
            consumption_title_tooltip: '',
            gas_title: 'ers_report.gas_title',
            gas_title_tooltip: '',
            electric_title: 'ers_report.electric_title',
            electric_title_tooltip: '',
            low_title: 'ers_report.low_title',
            low_title_tooltip: '',
            high_title: 'ers_report.high_title',
            high_title_tooltip: '',
            average_title: 'ers_report.average_title',
            average_title_tooltip: '',
            you_title: 'ers_report.you_title',
            you_title_tooltip: '',
            current_epc: 'ers_report.current_epc',
            current_epc_tooltip: '',
            future_epc: 'ers_report.future_epc',
            future_epc_tooltip: '',
            summary_title: 'ers_report.summary_title',
            summary_title_tooltip: '',
            summary_note: 'ers_report.summary_note',
            summary_note_tooltip: '',
            price_title: 'ers_report.price_title',
            price_title_tooltip: '',
            subsidy_title: 'ers_report.subsidy_title',
            subsidy_title_tooltip: '',
            after_subsidy_title: 'ers_report.after_subsidy_title',
            after_subsidy_title_tooltip: '',
            total_title: 'ers_report.total_title',
            total_title_tooltip: '',
            calculate_button_text: 'ers_report.calculate_button_text',
            calculate_button_text_tooltip: '',
            download_report: 'ers_report.download_report',
            download_report_tooltip: '',
            renovation_title: 'ers_report.renovation_title',
            renovation_title_tooltip: '',
            renovation_subtitle: 'ers_report.renovation_subtitle',
            renovation_subtitle_tooltip: '',
            renovation_price: 'ers_report.renovation_price',
            renovation_price_tooltip: '',
            renovation_subsidy: 'ers_report.renovation_subsidy',
            renovation_subsidy_tooltip: '',
            renovation_yearly: 'ers_report.renovation_yearly',
            renovation_yearly_tooltip: '',
            renovation_windowsDouble: 'ers_report.renovation_windowsDouble',
            renovation_windowsDouble_tooltip: '',
            renovation_wallExt: 'ers_report.renovation_wallExt',
            renovation_wallExt_tooltip: '',
            renovation_wallCav: 'ers_report.renovation_wallCav',
            renovation_wallCav_tooltip: '',
            renovation_roofIn: 'ers_report.renovation_roofIn',
            renovation_roofIn_tooltip: '',
            renovation_roofExt: 'ers_report.renovation_roofExt',
            renovation_roofExt_tooltip: '',
            renovation_roofIn_attic_floor: 'ers_report.renovation_roofIn_attic_floor',
            renovation_roofIn_attic_floor_tooltip: '',
            renovation_condGas: 'ers_report.renovation_condGas',
            renovation_condGas_tooltip: '',
            renovation_heatPump: 'ers_report.renovation_heatPump',
            renovation_heatPump_tooltip: '',
            renovation_ventC: 'ers_report.renovation_ventC',
            renovation_ventC_tooltip: '',
            renovation_solar: 'ers_report.renovation_solar',
            renovation_solar_tooltip: '',
            compliance_pack_title: 'ers_report.compliance_pack_title',
            compliance_pack_title_tooltip: '',
            compliance_pack_desc: 'ers_report.compliance_pack_desc',
            compliance_pack_desc_tooltip: '',
            budget_pack_title: 'ers_report.budget_pack_title',
            budget_pack_title_tooltip: '',
            budget_pack_desc: 'ers_report.budget_pack_desc',
            budget_pack_desc_tooltip: '',
            lowest_pack_title: 'ers_report.lowest_pack_title',
            lowest_pack_title_tooltip: '',
            lowest_pack_desc: 'ers_report.lowest_pack_desc',
            lowest_pack_desc_tooltip: '',
            custom_pack_title: 'ers_report.custom_pack_title',
            custom_pack_title_tooltip: '',
            custom_pack_desc: 'ers_report.custom_pack_desc',
            custom_pack_desc_tooltip: '',
            add_remove_renovations: 'ers_report.add_remove_renovations',
            add_remove_renovations_tooltip: '',
            submit_button_budget: 'ers_report.submit_button_budget',
            submit_button_budget_tooltip: '',
            already_compilance: 'ers_report.already_compilance',
            already_compilance_tooltip: '',
            no_renovation_found: 'ers_report.no_renovation_found',
            no_renovation_found_tooltip: '',
            renovation_subtitle_compliance_pdf:
                'ers_report.renovation_subtitle_compliance_pdf',
            renovation_subtitle_compliance_pdf_tooltip: '',
            renovation_subtitle_budget_pdf: 'ers_report.renovation_subtitle_budget_pdf',
            renovation_subtitle_budget_pdf_tooltip: '',
            renovation_subtitle_custom_pdf: 'ers_report.renovation_subtitle_custom_pdf',
            renovation_subtitle_custom_pdf_tooltip: '',
            disclaimer_title: 'ers_report.disclaimer_title',
            disclaimer_title_tooltip: '',
            disclaimer_body: 'ers_report.disclaimer_body',
            disclaimer_body_tooltip: '',
            disclaimer_body_pdf: 'ers_report.disclaimer_body_pdf',
            disclaimer_body_pdf_tooltip: '',
        },
        processing_page: {
            title_started: 'processing_page.title_started',
            title_started_tooltip: '',
            title_finished: 'processing_page.title_finished',
            title_finished_tooltip: '',
            title_failed: 'processing_page.title_failed',
            title_failed_tooltip: '',
            subtitle: 'processing_page.subtitle',
            subtitle_tooltip: '',
            ing_button: 'processing_page.ing_button',
            ing_button_tooltip: '',
            check_results: 'processing_page.check_results',
            check_results_tooltip: '',
            results_ready: 'processing_page.results_ready',
            results_ready_tooltip: '',
            something_went_wrong: 'processing_page.something_went_wrong',
            something_went_wrong_tooltip: '',
        },
    },
}
